import React from "react";
import { View } from "react-native";
import Background from "../../signin/background";
import Logo from "../../signin/logo";
import { SignupProvider } from "../SignupProvider";
import useStyles from "./styles.css";
import ResendEmail from "./resend-email";
import { tabletSize } from "@src/utils/screensize-helper";
import LogoTop from "../../signin/logo-top";

const Signup = () => {
  const styles = useStyles();
  const isTablet = tabletSize();

  return (
    <SignupProvider>
      <View style={styles.container}>
        <Background />
        <View style={styles.v_body}>
          {isTablet ? <LogoTop /> : <Logo />}
          <ResendEmail />
        </View>
      </View>
    </SignupProvider>
  );
};

export default Signup;
