import React, { useState, useCallback, createContext } from "react";

interface SignupContextValue {
  screen: string;
  updateScreen: (value: string) => void;
}

const SignupContext = createContext<SignupContextValue>({
  screen: "",
  updateScreen: () => {},
});

interface SignupProviderProps {
  children: React.ReactElement;
}

const SignupProvider = ({ children }: SignupProviderProps) => {
  const [screen, setScreen] = useState("");

  const updateScreen = useCallback(
    (value: string) => {
      setScreen(value);
    },
    [screen]
  );

  const value = {
    screen,
    updateScreen,
  };

  return (
    <SignupContext.Provider value={value}>{children}</SignupContext.Provider>
  );
};

export { SignupProvider, SignupContext };
