import colors from "@assets/colors";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.blurBG,
    },
    v_modal: {
      zIndex: 2,
      width: 500,
      height: 700,
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    t_title: {
      fontFamily: "DMSans-Bold", fontSize: 20,
      lineHeight: 26, color: colors.colorPrimary, textAlign: "center"
    },
    v_face_scanning: { marginTop: 103, marginBottom: 84},
    btn_cancel: { width: "58%", height: 47, marginTop: 16, paddingHorizontal: 0,
      backgroundColor: colors.transparent, borderWidth: 1, borderColor: colors.colorPrimary },
    label_cancel: { fontFamily: "DMSans-Bold", color: colors.colorPrimary },
    btn_proceed: { 
      width: "58%", height: 47,
      paddingHorizontal: 0, backgroundColor: colors.colorPrimary 
    },
    label_proceed: { fontFamily: "DMSans-Bold" },

    // SCREEN 1
    v_screen_1: {
      width: "100%",
      height: "100%",  alignItems: "center",
      justifyContent: "center"
    },
    btn_close: {width: "100%",
      height: "100%", position: "absolute"},

    // SCREEN 2
    v_screen_2: {
      width: 500,
      height: 700,
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.black,
    },
    t_note2: {
      zIndex: 2,
      position: "absolute",
      fontFamily: "DMSans-Bold", fontSize: 20, top: 50,
      lineHeight: 26, color: colors.white, textAlign: "center"
    },

    // SCREEN 3
    v_screen_3: {flex: 1, alignItems: "center", justifyContent: "center"},
    t_note3: {
      fontFamily: "DMSans-Bold", fontSize: 20, marginTop: 39,
      lineHeight: 26, color: colors.colorPrimary, textAlign: "center"
    },
    v_buttons_3: {
      width: "100%", 
      marginBottom: 45, 
      alignItems: "center", 
      justifyContent: "center"
    },

    // CAMERA
    v_camera_view: { 
      width: "100%", height: "100%", 
      justifyContent: "center", alignItems:"center",
      backgroundColor: "black", borderRadius: 8
    },
   
  },
  {
    [DEVICE_SIZES.LG]: {
      v_modal: { width: 400, height: 570 },
      t_title: { fontSize: 16, lineHeight: 17 },
      v_face_scanning: { marginTop: 80, marginBottom: 50},
      btn_proceed: { height: 35, marginTop: 40, borderRadius: 6 },
      btn_cancel: { height: 35, marginTop: 14, borderRadius: 6 },
      label_proceed: { fontSize: 10.5, lineHeight: 10.5 },
      label_cancel: { fontSize: 10.5, lineHeight: 10.5 },

      
      t_note3: { fontSize: 16, lineHeight: 17, marginTop: 30 },

      v_screen_2: { width: 400, height: 570 },
      t_note2: { fontSize: 16, lineHeight: 17, top: 40 },
    },
    [DEVICE_SIZES.MD]: {},
    [maxSize(DEVICE_SIZES.SM)]: {
      v_modal: { width: "95%" },
      btn_proceed: { height: 45, borderRadius: 6 },
      btn_cancel: { height: 45, marginTop: 15, borderRadius: 6 },
      v_screen_2: { width: "95%" },
    },
  }
);

export default useStyles;
