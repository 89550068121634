import type { RenderThumbnailItemProps } from "@react-pdf-viewer/thumbnail";
import React from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import { PluginContext } from "../provider/plugin";
import useStyles from "./styles.css";

function Thumbnails({ height }: any) {
  const styles = useStyles();
  const { thumbnailPluginInstance } = React.useContext(PluginContext);
  const { Thumbnails } = thumbnailPluginInstance;

  const renderThumbnailItem = React.useCallback((props: RenderThumbnailItemProps) => {
    return (
      <View key={props.key}>
        <Pressable onPress={props.onJumpToPage} 
          style={[styles.item_thumbnails, props.pageIndex === props.currentPage && styles.item_active]}>
          {props.renderPageThumbnail}
        </Pressable>
        <Text style={styles.text_page}>Page {props.renderPageLabel}</Text>
        <View style={styles.v_height} />
      </View>
    );
  },[]);

  return (
    <View style={[styles.container, { height }]} >
      <ScrollView  style={{paddingHorizontal: 20,}}>
        <Thumbnails renderThumbnailItem={renderThumbnailItem} />
      </ScrollView>
    </View>
  );
}

export default React.memo(Thumbnails);