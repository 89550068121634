import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    textBoxModalBlurBG: {
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.blurBG,
    },
    textBoxContainer: {
      padding: 40,
      width: "46%",
      borderRadius: 20,
      backgroundColor: colors.white,
    },
    textBoxHeader: {
      width: "100%",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 31,
    },
    i_close: { height: 24, width: 24 },
    textBoxTextHeader: {
      color: colors.black2,
      fontFamily: "Montserrat",
      fontWeight: "700",
      fontSize: 24,
      lineHeight: 29,
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [maxSize(DEVICE_SIZES.SM)]: {
      textBoxModalBlurBG: {
        backgroundColor: colors.blurBG,
      },
      textBoxContainer: {
        flex: 1,
        width: "90%",
        maxHeight: "75%",
        marginTop: 0,
        borderRadius: 0,
        paddingHorizontal: 20,
        height: "100%"
      },
    },
  }
);

export default useStyles;
