import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    v_stage_container: {
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      // backgroundColor: 'red',
      // ...StyleSheet.absoluteFillObject,
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
