import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    v_body: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    v_signup: {
      width: "50%",
      height: "100%",
      alignSelf: "center",
      justifyContent: "center",
      paddingHorizontal: "8%",
      backgroundColor: colors.white,
    },
    v_signup_landscape: {
      width: "50%",
      justifyContent: "center",
      paddingHorizontal: "9%",
      backgroundColor: colors.white,
    },
    signInCenter: { marginTop: 30 },
    btn_signup: { marginTop: 30, backgroundColor: colors.blue },
    lbl_signup: { fontFamily: "Montserrat-Bold" },

    // Email Sent
    t_email_sent: TypographyStyle.heading7Bold,
    t_email_sent_notes: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { marginTop: 45, color: "#4B4B4B" },
    ]),
    t_email_sent_notes2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      { marginTop: 34, textAlign: "center", color: "#4B4B4B" },
    ]),
    btn_backtologin: {
      height: 60,
      marginTop: 45,
      marginHorizontal: 30,
      backgroundColor: colors.blue,
    },
  },
  {
    [DEVICE_SIZES.XL]: {
      v_signup: { paddingHorizontal: "12%" },
      v_signup_landscape: { paddingHorizontal: "12%" },
    },
    [DEVICE_SIZES.LG]: {
      v_signup: { paddingHorizontal: "8%" },
      v_signup_landscape: { paddingHorizontal: "8%" },
      t_email_sent: { fontSize: 30, lineHeight: 39 },
      t_email_sent_notes: { fontSize: 18, lineHeight: 21, marginTop: 38 },
      t_email_sent_notes2: { fontSize: 10.5, lineHeight: 10.5, marginTop: 24 },
      btn_backtologin: { height: 45, borderRadius: 6 },
      lbl_signup: { fontSize: 10.5, lineHeight: 12 },
      btn_signup: { marginTop: 20 },
    },
    [DEVICE_SIZES.MD]: {
      v_body: {
        flexDirection: "column",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
      },
      v_signup: {
        minWidth: "100%",
        maxWidth: "100%",
        paddingTop: 100,
        alignSelf: "center",
        paddingVertical: 0,
        paddingHorizontal: "20%",
        justifyContent: "flex-start",
      },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_body: {
        flexDirection: "column",
        width: "100%",
        height: "100%",
      },
      v_signup: {
        minWidth: "100%",
        maxWidth: "100%",
        paddingTop: 90,
        paddingVertical: 0,
        paddingHorizontal: 20,
        justifyContent: "flex-start",
      },
      t_email_sent: {
        fontSize: 22,
        lineHeight: 25,
      },
      t_email_sent_notes: {
        fontSize: 16,
        lineHeight: 20,
      },
      t_email_sent_notes2: {
        fontSize: 12,
        lineHeight: 20,
      },
      btn_signup: { marginTop: 32 },
    },
  }
);

export default useStyles;
