import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    header_container: {
      flexDirection: "row",
    },
    btn_style: {
      height: 48,
      width: 190,
      maxWidth: 190,
      borderRadius: 8,
      alignSelf: "center",
      backgroundColor: colors.blue,
    },
    btn_label: {
      fontSize: 14,
      lineHeight: 16,
      fontWeight: "700",
      color: colors.white,
      fontFamily: "Montserrat",
    },
    divider: {width: 40},
    btn_other: {
      height: 48,
      width: 168,
      flexDirection: "row", 
      alignItems: "center",
      justifyContent: "center",
    },
    btn_label_other: {
      fontSize: 14,
      lineHeight: 16,
      marginRight: 6,
      color: colors.black,
      fontFamily: "DMSans-Bold",
    },
    header_title: { ...TypographyStyle.heading1Bold, color: colors.black3 },
    v_invite_link_wrapper: {
      flex: 1,
      flexDirection: "row", 
      justifyContent: "flex-start", 
      alignItems: "center", 
      marginTop: 0
    },

    // MENU COMPONENTS
    modal: { flex: 1 },
    dropdown: {
      height: 241,
      borderWidth: 0.5,
      position: "absolute",
      backgroundColor: "white",
      justifyContent: "center",
      borderColor: colors.border2,
    },
    flatlist: { marginTop: 18},
    item_separator: { height: 8 },
    v_item: {
      height: 33,
      paddingLeft: 26,
      flexDirection: "row",
      alignItems: "center",
    },
    v_item_active: { backgroundColor: colors.gray4 },
    t_item: {
      flex: 1,
      fontSize: 14,
      lineHeight: 16,
      fontFamily: "DMSans",
      color: colors.black3,
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      btn_style: { width: 142.5, height: 36, borderRadius: 6 },
      btn_label: { fontSize: 12, lineHeight: 10 },
      divider: {width: 25},
      btn_other: { width: 126, height: 36 },
      btn_label_other: { fontSize: 10.5, lineHeight: 12 },
      header_title: { fontSize: 12, lineHeight: 14 },

      //ACTIONS MENU
      dropdown: { height: 181 },
      flatlist: { marginTop: 15},
      item_separator: { height: 5.75 },
      v_item: { height: 25, paddingLeft: 20 },
      t_item: { fontSize: 10.5, lineHeight: 12}
    },
    [DEVICE_SIZES.MD]: {
      header_container: { flexDirection: "row-reverse"},
      divider: {width: 0},
      btn_style: {
        paddingVertical: 10,
        paddingHorizontal: 18,
        borderRadius: 8,
      },
      btn_label: {
        color: colors.white,
        fontSize: 12,
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: 16,
      },
      btn_label_other: { fontSize: 12, lineHeight: 14 },
      header_title: { fontSize: 12, lineHeight: 14 },
      v_invite_link_wrapper: { marginLeft: 15 }
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      header_container: {
        flexDirection: "row-reverse", 
        alignItems: "flex-end",
      },
      v_invite_link_wrapper: { marginLeft: 15 },
      divider: {width: 0},
      btn_style: {
        height: 40,
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: 8,
        width:"40%"
      },
      btn_label: {
        color: colors.white,
        fontSize: 10,
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: 16,
      },
      btn_label_other: { fontSize: 12, lineHeight: 14 },
      header_title: { fontSize: 12, lineHeight: 14 },
    },
  }
);

export default useStyles;
