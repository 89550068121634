import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1 },
    scrollview: { flex: 1, width: "100%" },
    v_body: { flex: 1, flexDirection: "row" },
    v_login: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "10%",
      backgroundColor: colors.white,
    },
    containerPortrait: { alignItems: "center", justifyContent: "center" },
    v_login_landscape: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "10%",
      backgroundColor: colors.white,
    },
    v_body_portrait: {
      width: "100%",
      alignItems: "flex-start",
      marginHorizontal: 20,
    },
    linearStyle_portrait: { display: "none" },
    labelStyle: {
      fontFamily: "DMSans-Medium"
    },
    v_height: { height: 50 },
    v_height_top: { height: "10%" },
    v_height_top_landscape: {height: "10%"},
    v_logo_top: { alignItems: "center", height: 300, justifyContent: "center", display: "none" },
    v_logo_top_2: { alignItems: "center", justifyContent: "center", display: "none" },

    v_marginTop20: { marginTop: 24 },
    signInCenter: { alignItems: "center" },
    txtNote1: 
    StyleSheet.flatten([TypographyStyle.heading2Bold, { color: colors.black2, letterSpacing:1.5,}]),
    txtNote1_landscape: StyleSheet.flatten([TypographyStyle.heading2Bold, { color: colors.blue, letterSpacing:1.5}]),
    v_field: { marginTop: 20 },
    txtNote2: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { marginTop: 24, color: "#4B4B4B" },
    ]),
    t_error: StyleSheet.flatten([
      TypographyStyle.ButtonLRegular,
      { paddingHorizontal: 20, marginTop: 13, color: colors.red, fontSize: 15, textAlign: "center" },
    ]),
    v_remember_forgot: {
      marginTop: 34,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    v_wrap_remember: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    v_remember_box: {
      width: 18,
      height: 18,
      borderWidth: 1,
      borderRadius: 4,
      alignItems: "center",
      justifyContent: "center",
      borderColor: colors.border2,
    },
    v_remember_box_active: {
      borderColor: colors.black1,
      backgroundColor: colors.black1,
    },
    t_remember_me: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginLeft: 5,
      },
    ]),
    t_forgot_password: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        fontWeight: "700",
        color: colors.blue,
      },
    ]),
    t_signup: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginTop: 20,
        textAlign: "center",
        alignSelf: "center",
        color: colors.black3,
      },
    ]),
    t_signup_2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.blue,
        fontWeight: "700",
        marginLeft: 20
      },
    ]),
    btn_signup: { marginTop: 30, backgroundColor: colors.black1 },
    btn_face_id: { marginTop: 15, backgroundColor: colors.blue },
    btn_google: {
      marginTop: 15,
      backgroundColor: colors.white,
      borderWidth: 1,
      width: 50,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      shadowColor: "gray",
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.6,
      shadowRadius: 10,
      borderColor: colors.border2,
    },
    btn_signin: { marginTop: 30, backgroundColor: colors.black },
    lbl_signup: { fontFamily: "Montserrat-Bold", textAlign: "center"},
    lbl_google: { fontFamily: "Montserrat-Bold", color: colors.gray1 },
    lbl_signin: { fontFamily: "Montserrat-Bold" },
    btn_signin_google: {
      marginTop: 15,
      borderWidth: 1,
      borderColor: colors.border2,
      backgroundColor: colors.white,
      shadowColor: colors.black1,
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.08,
      shadowRadius: 9,
    },
    lbl_signin_google: StyleSheet.flatten([
      TypographyStyle.googleText,
      {
        color: colors.gray1,
      },
    ]),

    v_privacy: { display: "none", marginTop: 52 },

    // LOGO COMPONENT
    v_logo_container: {
      flex: 1,
      width: "50%",
      alignItems: "flex-start",
      justifyContent:"flex-start",
      paddingLeft: 125,
      paddingTop: 257
    },
    txtNote3: {
      fontFamily: "DMSans",
      fontWeight: "700",
      fontSize: 33,
      color: colors.black2,
      paddingTop: "15%"
    },
    txtNote4: {
      fontFamily: "DMSans",
      fontWeight: "400",
      fontSize: 18,
      color: colors.black2,
      marginTop: 30
    },
    itemSeparator: {
      height: 1,
      marginHorizontal: 10,
      backgroundColor: colors.gray4,
      marginTop: 10,
      width: 190
    },
    txtNote5: {
      fontFamily: "DMSans",
      fontWeight: "400",
      fontSize: 16,
      color: colors.black2,
    },
    i_logo: { width: 620, height: 60 },
    t_logo: {
      fontFamily: "DMSans",
      fontWeight: "500",
      paddingTop: 0,
      fontSize: 50,
      lineHeight: 55,
      marginTop: 27,
      color: colors.white, 
    },

    //IMAGE BG
    i_bg: {
      ...StyleSheet.absoluteFillObject,
      width: "100%",
      height: "100%",
    },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { position: "absolute", bottom: 100,
        alignSelf: "center",textAlign: "center", 
        color: colors.white },
    ]),
    unawaProductStyle : StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { position: "absolute", bottom: "15%",
        alignSelf: "flex-start",textAlign: "flex-start", 
        color: colors.white },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([TypographyStyle.copyRightButton]),

    // BACKGROUND COMPONENT
    linearStyle: {
      position: "absolute",
      zIndex: -1,
      width: "100%",
      height: "100%",
    },
  },
  {
    [DEVICE_SIZES.XL]: {
      txtNote1: { fontSize: 50 },
      v_height_top: {height: 90},
      v_login: { paddingHorizontal: "9%" }
    },
    [DEVICE_SIZES.LG]: {
      v_height_top: {height: 145},
      txtNote1: { fontSize: 37.5, lineHeight: 39 },
      txtNote2: { fontSize: 18, lineHeight: 24 },
      v_remember_forgot: {
        marginTop: 28,
      },
      i_logo: { width: 373, height: 120.86 },
      t_logo: { fontSize: 40, lineHeight: 45, marginTop: 21 },
      copyRightStyle: { fontSize: 10.5, lineHeight: 15.75 },
      copyRightBtnStyle: { fontSize: 10.5, lineHeight: 15.75 },
      t_remember_me: { fontSize: 10.5, lineHeight: 10.5 },
      lbl_signup: { fontSize: 10.5, lineHeight: 12 },
      lbl_google: { fontSize: 10.5, lineHeight: 12 },
      lbl_signin: { fontSize: 10.5, lineHeight: 12 },
      t_forgot_password: { fontSize: 10.5, lineHeight: 10.5 },
      t_signup: { fontSize: 10.5, lineHeight: 10.75 },
      t_signup_2: { fontSize: 10.5, lineHeight: 10.75 },
      v_login_landscape: {
        paddingHorizontal: "7%",
      },
      itemSeparator : {
        width: "30%"
      }
    },
    [DEVICE_SIZES.MD]: {
      v_height_top_landscape: {height: 145},
      txtNote1_landscape: { fontSize: 37.5, lineHeight: 39 },
      txtNote2: { fontSize: 18, lineHeight: 24 },
      v_logo_container: {
        width: "20%"
      },
      unawaProductStyle : StyleSheet.flatten([
        TypographyStyle.copyRightText,
        { position: "relative", bottom: 0, top: 50,
          alignSelf: "center",textAlign: "center", 
          color: colors.black },
      ]),
      txtNote3: {
        fontFamily: "DMSans",
        fontWeight: "700",
        fontSize: 33,
        color: colors.black2,
        paddingTop: "0%"
      },
      v_login_landscape: {
        width: "50%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: "10%",
        backgroundColor: colors.white,
      },
      t_logo: { 
        fontSize: 32, 
        lineHeight: 35, 
        marginTop: 27, 
        fontWeight: "500",
        textAlign: "center"
      },
      copyRightBtnStyle: { fontSize: 10.5, lineHeight: 15.75 },
      t_remember_me: { fontSize: 10.5, lineHeight: 10.5 },
      lbl_google: { fontSize: 10.5, lineHeight: 12 },
      lbl_signin: { fontSize: 10.5, lineHeight: 12 },
      t_forgot_password: { fontSize: 10.5, lineHeight: 10.5 },
      t_signup_2: { fontSize: 10.5, lineHeight: 10.75 },

      //portrait
      v_login: {
        minWidth: "100%",
        maxWidth: "100%",
        paddingHorizontal: 20,
      },
      v_height_top: { height: 0 },
      signInCenter: { marginTop: 51 },
      linearStyle_portrait: { display: "none" },
      v_logo_top: { display: "flex", height: 300 },
      v_body_portrait: {
        width: "100%",
        alignItems: "center",
        marginHorizontal: 20,
        height: "80%"
      },
      scrollview: {
        width: "50%",
        height: "100%"
      },
      itemSeparator: {
        width: "30%"
      },
    }, 
    [DEVICE_SIZES.SM]: {
      v_height_top_landscape: {height: 145},
      txtNote1_landscape: { fontSize: 37.5, lineHeight: 39 },
      txtNote2: { fontSize: 18, lineHeight: 24 },
      v_logo_container: {
        width: "20%"
      },
      v_login_landscape: {
        width: "50%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: "10%",
        backgroundColor: colors.white,
      },
      itemSeparator: {
        width: "30%"
      },
      t_logo: { 
        fontSize: 16, 
        lineHeight: 25, 
        marginTop: 40, 
        fontWeight: "500",
        textAlign: "center"
      },
      txtNote4: {
        fontFamily: "DMSans",
        fontWeight: "400",
        fontSize: 16,
        color: colors.black2,
        marginTop: 30
      },
      copyRightBtnStyle: { fontSize: 10.5, lineHeight: 15.75 },
      t_remember_me: { fontSize: 10.5, lineHeight: 10.5 },
      lbl_google: { fontSize: 10.5, lineHeight: 12 },
      lbl_signin: { fontSize: 10.5, lineHeight: 12 },
      t_forgot_password: { fontSize: 10.5, lineHeight: 10.5 },
      t_signup_2: { fontSize: 10.5, lineHeight: 10.75 },

      //portrait
      v_login: {
        minWidth: "100%",
        maxWidth: "100%",
        paddingHorizontal: 20,
      },
      txtNote3: {
        fontFamily: "DMSans",
        fontWeight: "700",
        fontSize: 33,
        color: colors.black2,
        paddingTop: "0%"
      },
      scrollview: {
        width: "60%"
      },
      unawaProductStyle : StyleSheet.flatten([
        TypographyStyle.copyRightText,
        { position: "relative", bottom: 0, top: 50,
          alignSelf: "center",textAlign: "center", 
          color: colors.black },
      ]),
      v_height_top: { height: 51 },
      signInCenter: { marginTop: 0, alignItems: "center" },
      linearStyle_portrait: { display: "none" },
      v_logo_top: { display: "flex" },
      v_body_portrait: {
        width: "100%",
        alignItems: "center",
        marginHorizontal: 20,
        height: "80%"
      },
      v_height: { height: 0 },
      
    },
    [DEVICE_SIZES.XS]: {
      v_height_top_landscape: {height: 145},
      txtNote1_landscape: { fontSize: 37.5, lineHeight: 39 },
      txtNote2: { fontSize: 18, lineHeight: 24 },
      v_logo_container: {
        width: "20%"
      },
      v_height: {height: 0},
      v_login_landscape: {
        width: "50%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: "10%",
        backgroundColor: colors.white,
      },
      t_logo: { 
        fontSize: 20, 
        lineHeight: 25, 
        marginTop: 40, 
        fontWeight: "500",
        textAlign: "center"
      },
      txtNote4: {
        fontFamily: "DMSans",
        fontWeight: "400",
        fontSize: 14,
        color: colors.black2,
        marginTop: 30
      },
      copyRightBtnStyle: { fontSize: 10.5, lineHeight: 15.75 },
      t_remember_me: { fontSize: 10.5, lineHeight: 10.5 },
      lbl_google: { fontSize: 10.5, lineHeight: 12 },
      lbl_signin: { fontSize: 10.5, lineHeight: 12 },
      t_forgot_password: { fontSize: 10.5, lineHeight: 10.5 },
      t_signup_2: { fontSize: 10.5, lineHeight: 10.75 },

      //portrait
      v_login: {
        minWidth: "100%",
        maxWidth: "100%",
        paddingHorizontal: 20,
        height: "80%"
      },
      txtNote3: {
        fontFamily: "DMSans",
        fontWeight: "700",
        fontSize: 33,
        color: colors.black2,
        paddingTop: "0%"
      },
      scrollview: {
        width: "100%"
      },
      unawaProductStyle : StyleSheet.flatten([
        TypographyStyle.copyRightText,
        { position: "relative", bottom: 0, top: 50,
          alignSelf: "center",textAlign: "center", 
          color: colors.black },
      ]),
      v_height_top: { height: 51 },
      signInCenter: { marginTop: 0, alignItems: "center" },
      linearStyle_portrait: { display: "none" },
      v_logo_top: { display: "flex" },
      v_body_portrait: {
        width: "100%",
        alignItems: "center",
        marginHorizontal: 20,
      },
      itemSeparator: {
        width: "30%"
      },
      txtNote5: {
        fontFamily: "DMSans",
        fontWeight: "400",
        fontSize: 14,
        color: colors.black2,
      },
    },
  }
);

export default useStyles;