import React from "react";
import { MsalProvider, useMsal } from "@azure/msal-react";
import Button01 from "@src/components/button/Button01";
import colors from "@assets/colors";
import images from "@assets/images";
import useStyles from "./styles.css";
/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package 
 */
export const  MicrosoftSignInButton = () => {
  const styles = useStyles();
const {instance} = useMsal();
const loginRequest = {
  scopes: []
}
console.log("MSAL Instance,",instance);
const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance.loginPopup({
        ...loginRequest,
        prompt:'create',
      }).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect({
        ...loginRequest,
        prompt:'create',
      }).catch((e) => {
        console.log(e);
      });
    }
  };


  return (
  <Button01
      onPress={() => handleLogin("popup")}
      source={images.microsoft}
      color={colors.gray1}
      label="Sign up with Mircosoft"
      labelStyle={{color: colors.black9, fontSize: 12}}
      style={styles.btn_google}
    />
);
};