import React, { useState } from "react";
import { TextInput02 } from "@src/components/textinput";
import { Button01 } from "@src/components/button";
import { View, Text } from "react-native";
import useStyles from "./styles.css";
import colors from "@assets/colors";
import validator from "validator";
import Counter from "./counter";

const ConfirmFooter = (props: any) => {
  const styles = useStyles();
  const [code, setCode] = useState("");
  const [timerEnable, setTimerEnable] = useState(false);
  const enableValidation = timerEnable && code.length < 4;

  const onPress = () => {
    if (timerEnable) {
      props.onValidate(code);
    } else {
      props.onResend();
    }
  };

  const onChangetText = (value: string) => {
    if(!validator.isNumeric(value)){
      value = value.substring(0, value.length - 1);
    }
    setCode(value);
  };

  React.useEffect(() => {
    if (props.sessionId) {
      setTimerEnable(true);
    }
  }, [props.sessionId]);

  return (
    <View style={styles.v_footer}>
      <Text style={[styles.t_okay, { fontSize: 12 }]}>
        <Counter sessionId={props.sessionId} endTimer={() => setTimerEnable(false)}  />
      </Text>
      <View
        style={[
          styles.v_actions,
          !timerEnable && { flexDirection: "column" },
        ]}
      >
        {timerEnable && (
          <TextInput02
            value={code}
            maxLength={4}
            onChangeText={onChangetText}
            placeholder="Enter OTP"
            keyboardType="numeric"
            style={[{ width: "44%", marginTop: 0 }]}
            borderStyle={{ marginTop: 0 }}
          />
        )}
        <View style={{width: 30}} />
        <Button01
          onPress={onPress}
          style={styles.btn_yes}
          disabled={enableValidation}
          isLoading={props.isLoading}
          label={timerEnable ? "Validate OTP" : "Resend OTP"}
          labelStyle={{ color: enableValidation ? colors.black4 : colors.white }}
        />
      </View>
    </View>
  );
};

export default ConfirmFooter;
