import AffixIcon from "@assets/svg/affix-signature";
import CopyRightContent from "@src/screens/create-document/document-sent/copy-right";
import MobileHeader from "@src/screens/drawer-screens/drawer-header/mobile";
import WebHeader from "@src/screens/drawer-screens/drawer-header/web-view";
import { mobileSize } from "@src/utils/screensize-helper";
import React from "react";
import { Text, View } from "react-native";
import useStyles from "./styles.css";

const DocumentDenied = () => {
  const styles = useStyles();
  const isMobileSize = mobileSize();

  return (
    <>
      <View style={styles.drawer_item_header}>
        {isMobileSize ? <MobileHeader /> : <WebHeader/>}
      </View>
      <View style={styles.container}>
        <AffixIcon name="docu-denied" />
        <Text style={[styles.t_title]}>Access Denied</Text>
        <Text style={[styles.t_notes, styles.t_valid]}>You can only view this document with the email address
          {"\n"}you were invited with for signing.</Text>
        <Text style={[styles.t_notes, styles.t_notes_bold, styles.t_valid]}>Please log in or sign in using that email to access the document.</Text>
      </View>
      <View >
        <CopyRightContent />
      </View>
    </>
  );
};

export default DocumentDenied;
