import colors from "@assets/colors";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.blurBG3,
    },
    v_modal: {
      width: "80%",
      height: 340,
      borderRadius: 10,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
      padding: 35
    },
    t_title: {fontFamily: "DMSans-Bold", fontSize: 20, marginTop: 35,
      lineHeight: 28, color: colors.colorPrimary, textAlign: "center" },
    t_message: {fontFamily: "DMSans", fontSize: 24,
      lineHeight: 28, color: colors.colorPrimary, textAlign: "center" },
    btn_close: {zIndex: 1, position: "absolute", right: -22, top: -22},
    back_btn: {width: "100%", marginTop: 45}
  },
  {
    [DEVICE_SIZES.XL]: {
      v_modal: { width: "20%" }
    },
    [DEVICE_SIZES.LG]: {
      v_modal: { width: 452, height: 330, paddingHorizontal: 40 },
      t_title: { fontSize: 18, lineHeight: 22.5, marginTop: 25, },
      t_message: { fontSize: 18, lineHeight: 22.5, },
    },
    [DEVICE_SIZES.MD]: {
      v_modal: { width: "50%" }
    },
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
