import colors from "@assets/colors";
import { useTextWidth } from "@tag0/use-text-width";
import React from "react";
import { Group, Rect } from "react-konva";
import { Html } from "react-konva-utils";
import { TextInput, View } from "react-native";
import { CanvasContext } from "../../provider/canvas";
import { mobileSize, tabletSize } from "@src/utils/screensize-helper";

type QRCodeSignProps = {
  field: any;
};

const TextInputView = (props: QRCodeSignProps) => {
  const { field } = props;
  const inputRef = React.useRef<any>();
  const { canvas } = React.useContext(CanvasContext);
  const [input, setInput] = React.useState<string>(field.inputConfig.text ?? "");
  const textWidth = useTextWidth({ text: input || " ", font: "14.69px Times" });
  const contentWidth = textWidth > field.config.width ? textWidth : field.config.width;
  const isMobile = mobileSize();
  const isTablet = tabletSize();

  React.useEffect(() => {
    const maxWidth = (canvas.width * canvas.scale) - field.config.x;

    if(textWidth > maxWidth && input){
      setInput(input.slice(0, -1));
    }
  }, [input, textWidth, canvas]);

  React.useEffect(() => {
    if (field.inputConfig.text) {
      setInput(field.inputConfig.text)
    }
  }, [field.inputConfig.text])

  return (
    <React.Fragment>
      <Group
        x={field.config.x}
        y={field.config.y}
        width={contentWidth}
        height={field.config.height}
        rotation={field.config.rotation}
      >
        <Rect width={contentWidth} height={field.config.height} />
        <Html
          divProps={{
            style: {
              zIndex: -1,
              width: contentWidth,
              height: field.config.height,
              pointerEvents: "none",
              backgroundColor: colors.transparent
            },
          }}
        >
          <View style={{
            minWidth: contentWidth,
            height: field.inputConfig.height,
            alignItems: "center",
            justifyContent: "center",
          }}>
            <TextInput
              editable={false}
              ref={inputRef}
              numberOfLines={1}
              style={{
                paddingLeft: 2,
                fontFamily: "DMSans",
                minWidth: contentWidth,
                height: field.inputConfig.height,
                fontSize: field.inputConfig.fontSize
              }} 
              value={input}
              // placeholder={focus ? "" : "Type here..."} 
              />
          </View>
        </Html>
      </Group>
    </React.Fragment>
  );
};

export default React.memo(TextInputView);
