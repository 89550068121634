import React from "react";
import { Text,View, Pressable } from "react-native";
import useStyles from "./styles.css";
import Icon from "@expo/vector-icons/MaterialIcons";
import colors from "@assets/colors";
import { TextInput02 } from "@src/components/textinput";
import { DocumentContext } from "../provider/document";

const GeneratePassword = (props: any) => {
  const styles = useStyles();
  const { enableGeneratePassword, setGeneratePasswordTick } = React.useContext(DocumentContext);
  const [password, setPassword] = React.useState<string>("");

  const onSetGeneratedPassword = () => {
    generateRandomPassword();
    setGeneratePasswordTick(!enableGeneratePassword);
    props.isGeneratePassword(!enableGeneratePassword);
    if(enableGeneratePassword){
      return;
    }
  }

  const generateRandomPassword = () => {
    let charset = "";
    let newPassword = "";
    const passwordLength = 12;


    charset += "abcdefghijklmnopqrstuvwxyz1234567890!@#$%^&*()~-_";
    charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()~-_";
 

    for (let i = 0; i < passwordLength; i++) {
        newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    setPassword(newPassword);
    props.generatePassword(newPassword);
    
};

  return (
    <>
        <View style={styles.share_docs_wrapper}>
          <Pressable onPress={onSetGeneratedPassword}
            style={[styles.share_docs_box, enableGeneratePassword && {backgroundColor: colors.black3}]} >
            <Icon name="check" size={15} color="white" />
          </Pressable>
          <Text style={styles.t_share_document}>Generate Password</Text>
        </View>
        { 
          enableGeneratePassword &&  
            <TextInput02
            label=""
            hasReset
            style={styles.email}
            value={password}
            // error={errorInput.position}
            placeholder=""
            labelStyle={styles.fontWeight}
            inputStyle={styles.inputStyle}
            onChangeText={props.generatePassword}
            onPress={generateRandomPassword} 
        />
        }

        
    </>

  );
};

export default GeneratePassword;
