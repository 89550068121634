import React, { useCallback, useContext, useEffect, useState } from "react";
import ErrorMessage from "./components/modals/error-sideway";
import SuccessTop from "./components/modals/success-top";
import SessionExpired from "./components/session-expired";
import { useAppSelector } from "./ducks/ducksHook";
import { useAuthService, useUserService } from "./ducks/hooks";
import { selectUserFailed } from "./ducks/slices/user.slice";
import { appConfig } from "@src/utils/api";
import Splash from "@src/components/loading";
import { AuthenticationResult, EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./utils/azure-ad-config";
import { MsalProvider, useMsal } from "@azure/msal-react";

interface AuthProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  maintenance: boolean;
  error: string | null;
  onErrorMessage: (value: string) => void;
  onSuccessMessage: (value: string) => void;
}

export const AuthContext = React.createContext<ContextValue>({} as ContextValue);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const msalInstance = new PublicClientApplication(msalConfig);

  const [error, setError] = useState<string | null>(null);
  const { isLoggedIn, onRefreshToken, setLoginInput,onMicrosoftLogin } = useAuthService();
  const isSessionExpired = useAppSelector(selectUserFailed);
  const [success, setSuccess] = useState<string | null>(null);
  const { resetError, fetchUserDetails, fetchUserSubscription } = useUserService();

  const onErrorMessage = useCallback(setError, [error]);
  const onSuccessMessage = useCallback(setSuccess, [success]);

  if(!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0){
    console.log("msalInstance callback getActiveAccount",msalInstance)
    //Account selection logic is app dependent.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.addEventCallback((event) => {
    console.log("msalInstance callback")
    const authResult = event.payload as AuthenticationResult;
    const account = authResult?.account

    console.log("msalInstance callback account",account)
    console.log("event.eventType",event.eventType)
    console.log("EventType.LOGIN_SUCCESS",EventType.LOGIN_SUCCESS)
    if((event.eventType === EventType.LOGIN_SUCCESS ||
       event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && account){
      msalInstance.setActiveAccount(account);
      //setLoginInput({"email", account?.username });
      console.log("msalInstance callback account",account);
      onMicrosoftLogin(account);
      
    }

  })

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserDetails();
      fetchUserSubscription();
      onRefreshToken();
    }
  }, [isLoggedIn]);


  const [loaded, setLoaded] = React.useState(false);
  const [maintenance, setMaintenance] = React.useState(false);

  const fetchMaintenance = async (completion: (isMaintenance: boolean) => void) => {
    let maintenance = false
    try {
      const response = await appConfig();
      maintenance = response?.data?.isMaintenance ?? false
    } catch (error) {
      maintenance = false
    } finally {
      completion(maintenance)
    }
  };

  React.useEffect(() => {
    fetchMaintenance((isMaintenance: boolean) => {
      setMaintenance(isMaintenance)
      setLoaded(true);
    })
  }, [setMaintenance]);

  if (!loaded) {
    return <Splash />;
  }

  return (
    <MsalProvider instance={msalInstance}>
    <AuthContext.Provider value={{ maintenance, error, onErrorMessage, onSuccessMessage }}>
      {children}
      <SessionExpired visible={isSessionExpired} onPress={resetError} />
      <ErrorMessage
        message={error}
        visible={error !== null}
        onClose={() => setError(null)}
      />
      <SuccessTop
        message={success}
        visible={success !== null}
        onClose={() => setSuccess(null)}
      />
    </AuthContext.Provider>
    </MsalProvider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
