import { useRoute } from "@react-navigation/native";
import Loading from "@src/components/loading";
import { useAuthService } from "@src/ducks/hooks";
import { useFetchPost } from "@src/utils/api/hooks";
import _ from "lodash";
import React from "react";
import Failed from "./failed";
import Success from "./success";

const VerificationCheck = () => {
  const route = useRoute<any>();
  const { onLogin } = useAuthService();
  const token = route?.params?.token ?? "";
  const { data, error, runRequest } = useFetchPost();

  React.useEffect(() => {
    const routes = "/auth/confirm-registration";
    const params = {
      confirmToken: token,
    };
    runRequest(routes, params);
  }, [token]);

  const handleSuccess = () => {
    onLogin({...data, otpverified: true, loginType: "Login via Email"});
  };

  if (!_.isEmpty(error)) {
    return <Failed />;
  }

  if (!_.isEmpty(data)) {
    return <Success onPress={handleSuccess} />;
  }

  return <Loading />;
};

export default VerificationCheck;
