import React from "react";
import { PluginProvider } from "../provider/plugin";
import { CanvasProvider } from "../provider/canvas";
import ViewerScreen from "./viewer";
import PDFPages from "../pages";

function Viewer(props: any) {    
  return (
    <PluginProvider>
      <CanvasProvider>
        <>
          <ViewerScreen {...props} />
          <PDFPages document={props.item} />
        </>
      </CanvasProvider>
    </PluginProvider>
  );
}

export default React.memo(Viewer);
