// DUCKS pattern
import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
import { CreateDocumentRequestValue, ErrorValue, GeneratedOTP, LoginInput, SessionValue, TypeValue, ValidatedOTP, loginInputValue } from "../types";

export interface AuthState {
  loading: boolean;
  isLoggedIn: boolean;
  session: SessionValue;
  error: ErrorValue;
  loginInput: loginInputValue;
  documentOnFloat: CreateDocumentRequestValue;
  generatedOTP: GeneratedOTP;
  validatedOTP: ValidatedOTP;
}

export const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  session: {} as SessionValue,
  error: {} as ErrorValue,
  documentOnFloat: {} as CreateDocumentRequestValue,
  loginInput: {
    email: "",
    password: "",
    rememberme: false,
  } as loginInputValue,
  generatedOTP: {} as GeneratedOTP,
  validatedOTP: {
    sessions: [],
    id: "",
    valid: false
  } as ValidatedOTP,
};

// Slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginInput: (state, action) => {
      if(action.payload.type === "email"){
        state.loginInput.email = action.payload.value;
      }else if(action.payload.type === "password"){
        state.loginInput.password = action.payload.value;
      }else if(action.payload.type === "rememberme"){
        state.loginInput.rememberme = action.payload.value;
      }
    },
    faceIDloginRequest: (state) => {
      state.isLoggedIn = false;
      state.session = {} as SessionValue;
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    loginRequest: (state) => {
      state.isLoggedIn = false;
      state.session = {} as SessionValue;
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    generateOTP: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    generateOTPSuccess: (state, action: any) => {
      state.loading = false;
      state.generatedOTP = {
        updatedAt: action.payload.updatedAt,
        createdAt: action.payload.createdAt,
        sessionId: action.payload.otpExpiration,
      };
    },
    validateOTP: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    validateOTPSuccess: (state, action: any) => {
      state.validatedOTP = {
        sessions: state?.validatedOTP?.sessions?.find(session => session.email === action.payload.email) ?
          state?.validatedOTP?.sessions?.map(session => {
            if (session.email === action.payload.email) {
              return ({ ...session, 
                createdAt: action.payload.createdAt, 
                sessionId: action.payload.sessionId 
              });
            }
            return session;
          }) : [...state?.validatedOTP?.sessions ?? [], { 
            createdAt: action.payload.createdAt,
            sessionId: action.payload.sessionId, 
            email: action.payload.email 
          }],
        id: action.payload.id,
        valid: action.payload.valid,
      };
    },
    validateOTPFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    loginSuccess: (state, action) => {
      console.log("action---",action);
      state.session = action.payload;
      state.error = {} as ErrorValue;
      state.isLoggedIn = true;
      state.loading = false;
      if(action.payload?.otpverified){
        state.validatedOTP = {
          valid: true
        };
      }
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetLoading: (state) => {
      state.loading = false;
    },

    logout: (state) => {
      if(state.loginInput?.rememberme){
        state.isLoggedIn = false;
        state.loginInput.password = "";
        state.session = {} as SessionValue;
      }else{
        return { ...initialState, 
          generatedOTP: state?.generatedOTP,
          validatedOTP: ({ ...state.validatedOTP, valid: false }) 
        };
      }
    },
    refreshTokenUpdate: (state, action) => {
      state.session.accessToken = action.payload;
    },
    documentOnFloat: (state, action) => {
      state.documentOnFloat = action.payload;
    },
  },
});

// Actions
export const authActions = {
  generateOTP: createAction(`${authSlice.name}/generateOTP`),
  validateOTP: createAction(
    `${authSlice.name}/validateOTP`,
    (params: GeneratedOTP) => ({
      payload: params,
    })
  ),
  setLoginInput: createAction(
    `${authSlice.name}/setLoginInput`,
    (params: TypeValue) => ({
      payload: params,
    })
  ),
  loginRequest: createAction(
    `${authSlice.name}/loginRequest`,
    (params: LoginInput) => ({
      payload: params,
    })
  ),
  faceIDloginRequest: createAction(`${authSlice.name}/faceIDloginRequest`),
  loginSuccess: authSlice.actions.loginSuccess,
  loginFailure: authSlice.actions.loginFailure,
  resetLoading: authSlice.actions.resetLoading,
  generateOTPSuccess: authSlice.actions.generateOTPSuccess,
  validateOTPSuccess: authSlice.actions.validateOTPSuccess,
  validateOTPFailure: authSlice.actions.validateOTPFailure,
  logout: authSlice.actions.logout,

  refreshTokenRequest: createAction(
    `${authSlice.name}/refreshTokenRequest`,
    (params: SessionValue) => ({
      payload: { ...params, userId: params?.id },
    })
  ),
  refreshTokenUpdate: authSlice.actions.refreshTokenUpdate,
  documentOnFloat: authSlice.actions.documentOnFloat,
  microsoftLoginRequest: createAction(`${authSlice.name}/microsoftLoginRequest`,
    (params: any) => ({
      payload: params,
    })),
};

// Selectors
export const selectAuthValidatedOTP = (state: RootState) => state.auth.validatedOTP;
export const selectAuthGeneratedOTP = (state: RootState) => state.auth.generatedOTP;
export const selectAuthLoggingIn = (state: RootState) => state.auth.loading;
export const selectAuthLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectAuthLogInFailed = (state: RootState) => state.auth.error;
export const selectAuthSession = (state: RootState) => state.auth.session;
export const selectAuthLoginInput = (state: RootState) => state.auth.loginInput;
export const selectDocumentOnFloat = (state: RootState) => state.auth.documentOnFloat;
export const selectedAuthLoginType = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.loginType ?? ""
);
export const selectedAuthGuestRole = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.role === "guest"
);
export const selectedAuthAccessToken = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.accessToken ?? ""
);
export const selectedAuthEmail = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.email ?? ""
);
export const selectedAuthUserID = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.id ?? ""
);
export const selectedAuthRefreshToken = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.refreshToken
);
export const selectedAuthRole = createSelector(
  (state: RootState) => state.auth.session,
  (session) => session?.role ?? "principal"
);
export const selectedErrorMessage = createSelector(
  (state: RootState) => state.signsecure.error,
  (error) => error.message
);

// Reducer
export default authSlice.reducer;
