import React, { useContext } from "react";
import { SignupContext } from "./SignupProvider";
import SignEmailSent from "./signup-emailsent";
import SignUpFields from "./signup-fields";

const Screens = () => {
  const { screen } = useContext(SignupContext);

  switch (screen) {
  case "emailsent":
    return <SignEmailSent />;
  default:
    return <SignUpFields />;
  }
};

export default Screens;
