import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

export const HEADER_HEIGHT = 60;

const useStyles = CreateResponsiveStyle(
  {
    main: {
      zIndex: 2,
      top: 250,
      right: 0,
      position: "absolute",
    },
    actionContainer: {
      height: 65,
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },

    // ZOOM
    zoom_container: {
      right: 0,
      zIndex: 4,
      alignItems: "center",
      position: "absolute",
      justifyContent: "center",
    },
    pdf_btn_zoom: {
      width: 45,
      height: 45,
      borderRadius: 45 / 2,
      paddingVertical: 5,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.black3,
    },
    divider: { height: 20, width: 14 },
  },
  {
    [DEVICE_SIZES.LG]: {
      main: { right: 50 }, 
      pdf_btn_zoom: {
        width: 34,
        height: 34,
        borderRadius: 34 / 2,
      },
      divider: { height: 18 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      pdf_btn_zoom: {
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
        backgroundColor: colors.white,
        shadowColor: colors.black1,
        shadowOffset: { width: 1, height: 8 },
        shadowOpacity: 0.2,
        shadowRadius: 10,
      },
      main: { display:"none" }, 
    },
  }
);

export default useStyles;
