import React from "react";
import SignUpProvider from "./provider";

import SignIn from './SignIn'

const Index = () => {

  return (
    <SignUpProvider>
      <SignIn></SignIn>
    </SignUpProvider>
  );
};

export default Index;
