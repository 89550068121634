import React from "react";
import { selectedDocumentParties, selectedEnableSignature } from "@src/ducks/slices/signsecure.slice";
import { compareAuthorVsSigner, getOtherActions } from "@src/utils/filter-helper";
import { View, Text, Pressable, useWindowDimensions } from "react-native";
import { SignatureContext } from "../../provider/signature";
import ActionsMenu, { MeasureValue } from "./actions-menu";
import { useAppSelector } from "@src/ducks/ducksHook";
import Icon from "@expo/vector-icons/MaterialIcons";
import AffixButton from "./affix-button";
import useStyles from "./styles.css";
import colors from "@assets/colors";

const AffixSignature = (props: any) => {
  const styles = useStyles();
  const { width } = useWindowDimensions();
  const actionRef = React.useRef<any>(null);
  const userParty = useAppSelector(selectedDocumentParties);
  const [isFocus, setFocus] = React.useState<boolean>(false);
  const enableSignature = useAppSelector(selectedEnableSignature);
  const {document, onActionButton } = React.useContext(SignatureContext);
  const [measure, setMesasure] = React.useState<MeasureValue>({} as MeasureValue);
  const options = React.useMemo(() => {
    const isSameAuthor = compareAuthorVsSigner(document, userParty);

    return getOtherActions(document?.workflow?.status, userParty, isSameAuthor, enableSignature);
  }, 
  [userParty.role, document, enableSignature]);

  React.useEffect(() => {
    if (actionRef.current) {
      actionRef.current?.measure(
        (
          fx: any,
          fy: any,
          width: number,
          height: any,
          px: number,
          py: number
        ) => {
          setMesasure({
            x: px,
            y: py,
            width,
            height,
          });
        }
      );
    }
  }, [actionRef.current, isFocus, width]);

  return (
    <View style={styles.header_container}>      
      <AffixButton />
      <View style={styles.divider} />
      <Pressable
        ref={actionRef}
        onPress={() => setFocus(true)}
        style={[styles.btn_other, { opacity: isFocus ? 0 : 1}]}>
        <Text selectable={false} style={[styles.btn_label_other, { color: props.color || colors.black3}]}>Other Actions</Text>
        <Icon name="expand-more" size={18} color={props.color || colors.black3} />
      </Pressable>
      <ActionsMenu
        visible={isFocus}
        measures={measure}
        onSelected={onActionButton}
        onClose={() => setFocus(false)}
        options={options}
      />
    </View>
  );
};

export default AffixSignature;
