import React from "react";
import { View, Image, Text } from "react-native";
import { mobileSize } from "@src/utils/screensize-helper";
import Background from "./background";
import UnawaProduct from "./unawaProduct";
import useStyles from "./styles.css";
import images from "@assets/images";
import SVGIcon from '@assets/svg';


export default (props: any) => {
  const styles = useStyles();
  const isMobile = mobileSize();

  const sizeLogo = React.useMemo(() => {
    if(isMobile){
      return {
        width: 120,
        height: 25,
      };
    } 

    return {
      width: 150,
      height: 30,
    };
  },[isMobile]);

  const sizeMaintenanceLogo = React.useMemo(() => {
    if(isMobile){
      return {
        width: 200,
        height: 200,
        left: -30,
      };
    } 

    return {
      width: 300,
      height: 300,
      left: -20,
    };
  },[isMobile]);

  return (
    <View style={[styles.container, props.style]}>
      <Background />
      <View style={styles.maintenance_logo_container}>
        <Image style={sizeMaintenanceLogo} source={images["maintenance_logo"]} resizeMode="contain" />
        <View style={styles.t_logo_container}>
          <SVGIcon name="signsecure-no-v2" {...sizeLogo} fill={`#000000`}  />
          <View>
            <Text style={[styles.t_logo, { color: '#ffffff', fontWeight: 'bold' }]}>
              Service Upgrade{`\n`}In Progress
            </Text>
            <Text style={{ flex: 1, color: '#ffffff', paddingRight: 80, }}>
              We are in the process of upgrading our services to serve you better. Your patience is greatly appreciated.{`\n\n`}
            </Text>
            <Text style={{ flex: 1, color: '#ffffff', paddingRight: 10, }}>
              For urgent matters, please reach out to support@signsecure.com
              Thank you for choosing <Text style={{ fontWeight: "bold" }}>SignSecure</Text>
            </Text>

            <UnawaProduct />
          </View>
        </View>
      </View>
    </View>
  );
};
