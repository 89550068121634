import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    viewContainer: {
      flex: 1,
      marginTop: 20,
    },
    v_notes: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    t_header: {
      ...TypographyStyle.Body2Regular,
      color: colors.black3,
      fontSize: 18,
    },
    body2: {
      ...TypographyStyle.Body2Regular,
      color: colors.black3,
      textAlign: "center",
    },
    btn_pick_other: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyS: {
      ...TypographyStyle.BodySRegular,
      color: colors.black3,
      textAlign: "center",
      marginHorizontal: 21,
    },
    fieldText: {
      fontFamily: "DMSans-Medium",
      fontSize: 18,
      color: colors.black3,
      textAlign: "center",
      marginTop: 23,
      marginHorizontal: 53,
    },
    BGLightGray: {
      // marginTop: 15,
      // backgroundColor: colors.offwhite,
      borderRadius: 10,
      paddingBottom: 34,
    },
    iconClose: { alignSelf: "flex-end", padding: 24 },
    iconUpload: { alignSelf: "center", marginTop: 25, marginBottom: 16 },
    v: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginVertical: 23,
    },
    dash: {
      height: 1,
      width: 41,
      backgroundColor: colors.darkGray,
    },
    btn: {
      height: 40,
      borderWidth: 1,
      borderColor: colors.blue,
      backgroundColor: colors.blue
    },
    btnSee: {
      width: 180,
      alignSelf: "center",
      marginVertical: 30,
    },
    imageStyle: { width: "100%", height: 220 },

    btn_delete: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    // SEND OTP
    v_footer: { marginTop: 20 },
    t_okay: {
      fontFamily: "DMSans",
      fontSize: 13,
      color: colors.black3,
      textAlign: "center",
    },
    v_actions: {
      marginTop: 20,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    btn_no: {
      width: "46%",
      borderWidth: 1,
      backgroundColor: colors.transparent,
      borderColor: colors.purple,
    },
    btn_yes: { width: "40%" },
    label_yes: { color: colors.black4 },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
