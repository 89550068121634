import React from "react";
import { Group, Rect, Text } from "react-konva";
import HtmlSignature from "./html-signature";
import QuickSigning from "./quick-signing";
import SignatureSigned from "./signature-signed";
import SignatureText from "./signature-text";

type InitialsProps = {
  field: any;
  onSelect: () => void;
  onQuickSign: () => void;
};

const Initials = (props: InitialsProps) => {
  const { field } = props;

  return (
    <React.Fragment>
      <Group
        x={field.config.x}
        y={field.config.y}
        width={field.config.width}
        height={field.config.height}
        onTap={props.onSelect}
        onClick={props.onSelect}
        rotation={field.config.rotation}
      >
        {field.signature ? 
          <>
            <HtmlSignature
              width={field.config.width}
              documentId={field.documentId}
              signatureDate={field.signatureDate}
              height={field.config.height * 0.3} />
              {field.signatureType === "Type" &&
                <SignatureText
                  x={0}
                  y={field.config.height * 0.4}
                  width={field.config.width}
                  height={field.config.height * 0.67}
                  // src={field.signature}
                  text={field.signatureParams.signatureText}
                  font={field.signatureParams.font}
                />
              }
              {field.signatureType !== "Type" &&
                <SignatureSigned
                  x={0}
                  y={field.config.height * 0.33}
                  width={field.config.width}
                  height={field.config.height * 0.67}
                  src={field.signature}
                />
              }
          </> :
          <>
            <Rect
              fill={`${field.config.fill}30`}
              width={field.config.width}
              height={field.config.height}
              dash={[5, 5]}
              dashOffset={2}
              strokeWidth={2}
              cornerRadius={6.25}
              stroke={field.config.fill}
            />
            <Text
              align="center"
              fontFamily="DMSans"
              verticalAlign="middle"
              fill={field.config.fill}
              width={field.config.width}
              height={field.config.height}
              text={field.textConfig.text}
              fontSize={field.textConfig.fontSize}
            />
          </>}
      </Group>
      <QuickSigning
        field={field}
        onQuickSign={props.onQuickSign}
      />
    </React.Fragment>
  );
};

export default React.memo(Initials);
