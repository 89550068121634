import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import TypographyStyle from "@assets/typography";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      marginTop: 20
    },
    header_container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    t_header: {
      ...TypographyStyle.Body2Regular,
      color: colors.darkGray,
      fontSize: 16,
      fontWeight: 400
    },   
    t_input: {
      marginBottom: 30,
    },

    // Footer buttons
    inputBtnV: {
      flexDirection: 'column',
      height: 140,
    },
    btnReset: {
      flex: 1,
      borderWidth: 1,
      borderColor: colors.purple,
      backgroundColor: colors.white,
    },
    btnInput: {
      flex: 1,
      marginVertical: 15,
    },
  }
);

export default useStyles;