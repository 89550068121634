import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: 200, 
      height: "100%", 
      alignItems: "center",
    },
    item_thumbnails: {  width: "100%", height: "100%",
      backgroundColor: "white", marginBottom: 5,
      shadowColor: "gray",
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.4,
      shadowRadius: 5, },
    item_active: { borderWidth: 0.5, borderColor: colors.black3},
    text_page: { fontFamily: "DMSans-Bold", fontSize: 10.5, 
      lineHeight: 13.67, color: colors.black },
    v_height: {height: 30},
  },
  {
    [DEVICE_SIZES.LG]: {
      container: { width: 148, top: 10 },
      text_page: { fontSize: 10.5, lineHeight: 13.67, marginTop: 8 },
      v_height: {height: 20},
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      container: { display: "none"},
    },
  }
);

export default useStyles;
