import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import { mobileSize } from "@src/utils/screensize-helper";
import React from "react";
import { StyleProp, Text, TextStyle, View, ViewStyle, useWindowDimensions } from "react-native";
import useStyles from "./styles.css";
import { useRoute } from "@react-navigation/native";

type Props = {
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  content?: string;
  subtitlePlan: string;
}

const LogoTop = (props: Props) => {
  const styles = useStyles();
  const isMobile = mobileSize();
  const route = useRoute<any>();
  const code = route?.params?.plan;

  const { width } = useWindowDimensions();
  const size = React.useMemo(() => {
    const WIDTH = (width - 80) >= 620 ? 620 : width - 80;
    if (isMobile){
      return {
        width: WIDTH >= 200 ? 120 : WIDTH,
        height: 50,
      };
    }

    return {
      width: WIDTH >= 620 ? 260 : WIDTH,
      height: 50,
    };
  },[width]);

  const textTitle = () => {
    if(code === "pro" || code === "pro-12"){
      return "Professional"
    } else if(code === "biz" || code === "biz-12"){
      return "Business"
    }
  }


  return (
    <View style={[styles.v_logo_top, props.style]}>
      <View style={styles.logo_PlanContainer}>
      {isMobile ? <SVGIcon name="SignsecureMobileLogo" /> : <SVGIcon name="SignSecure"  {...size} fill={colors.colorPrimary} /> } 
        <Text style={styles.textColorPlan}>
          {textTitle()}
        </Text>
      </View>
     
      <Text style={[styles.t_logo, props.textStyle]}>{props.content}</Text>
      <Text style={[styles.t_logo, props.textStyle]}>{props.subtitlePlan} <br/>Use code <b>SSV2FREE30</b> to get 1 month <br/>for FREE!</Text>
    </View>
  );
};

export default LogoTop;