import React from "react";
import AffixDocument from "./affix-document";
import DocumentApproved from "./document-response/document-approved";
import DocumentCancelled from "./document-response/document-cancelled";
import DocumentDeclined from "./document-response/document-declined";
import DocumentDenied from "./document-response/document-denied";
import DocumentNoAvailable from "./document-response/document-no-available";
import DocumentRejected from "./document-response/document-rejected";
import DocumentSigned from "./document-response/document-signed";
import DocumentValid from "./document-response/document-valid";
import { SignatureContext } from "./provider/signature";

function Screens() {
  const { screen } = React.useContext(SignatureContext);

  switch(screen){
  case "APPROVED":
    return <DocumentApproved />;
  case "CANCELLED":
    return <DocumentCancelled />;
  case "DECLINED":
    return <DocumentDeclined />;
  case "DENIED":
    return <DocumentDenied />;
  case "NOTAVAILABLE":
    return <DocumentNoAvailable />;
  case "REJECTED":
    return <DocumentRejected />;
  case "SIGNED":
    return <DocumentSigned />;
  case "NOTVALID":
    return <DocumentValid />;
  default:
    return <AffixDocument />;
  }
}

export default Screens;
