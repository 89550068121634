import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: { 
      flex: 1,
      alignItems: "flex-end",
      backgroundColor: colors.blurBG,
    },
    formContainer: {
      width: 524,
      height: "100%",
      paddingTop: 52,
      paddingHorizontal: 40,
      backgroundColor: colors.white,
      shadowColor: "gray",
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.6,
      shadowRadius: 10,
    },
    h4Bold: {
      fontFamily: "DMSans",
      fontWeight: "700",
      color: colors.blue,
      fontSize: 24,
      lineHeight: 20,
    },
    btn_close: {position: "absolute", right: 50},
    body2: {
      fontFamily: "DMSans",
      fontSize: 14,
      lineHeight: 24,
      marginTop: 5,
      color: colors.black3,
    },
  
    // PARTIES

    partiesContainer: {
      marginTop: 40,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    partiesContainer2: {
      flex: 1,
      marginLeft: 15,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    witnessNumber: {
      height: 30,
      width: 30,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.green2,
    },
    name: {
      flex: 0.8,
      fontSize: 14,
      lineHeight: 16,
      color: colors.black3,
      fontFamily: "DMSans-Bold",
    },
    role: {
      fontSize: 14,
      lineHeight: 25,
      color: colors.black3,
      fontFamily: "DMSans",
    },
    remarks: {
      marginTop: 7,
      fontSize: 14,
      lineHeight: 25,
      color: colors.darkGray,
      fontFamily: "DMSans",
    },
    v_height60: { height: 60 },
  },
  {
    [DEVICE_SIZES.LG]: {
      formContainer: {
        width: 450,
        paddingTop: 42,
        paddingHorizontal: 30,
      },
      h4Bold: {
        fontSize: 18,
        lineHeight: 18,
        marginBottom: 12,
      },
      body2: {
        fontSize: 11,
        lineHeight: 12,
      },
      v_height60: { height: 40 },
      witnessNumber: { width: 25, height: 25 },
      name: { fontSize: 11, lineHeight: 13 },
      role: { fontSize: 11, lineHeight: 20 },
      remarks: { fontSize: 11, lineHeight: 18 },
      partiesContainer: { marginTop: 30 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: {
        paddingHorizontal: 0,
        paddingLeft: 50
      },
      v_height60: { height: 20 },
      formContainer: {
        width: "100%",
        paddingHorizontal: 20,
      },
      partiesContainer2: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      name: {
        fontSize: 14,
        lineHeight: 18,
      },
      btn_close: {position: "absolute", top: 0, right: 10},
    },
  }
);

export default useStyles;
