import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import TypographyStyle from "@assets/typography";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, marginTop: 20 },
    header_container: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 40,
      paddingLeft: 20,
    },
    flatlist: { maxHeight: 300 },
    t_header: {
      ...TypographyStyle.Body2Regular,
      color: colors.black3,
      fontSize: 18,
    },
    v_headercomponent: {height: 150, alignItems: "center", justifyContent: "center"},
    v_separator: {
      height: 1,
      backgroundColor: colors.border8,
    },

    // DATA ITEM
    r_style: {
      height: 110,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 35,
      justifyContent: "space-between",
      backgroundColor: colors.white,
    },
    image_preview: { height: 100, width: 200 },
    btn_select: { paddingHorizontal: 20, paddingVertical: 8, borderRadius: 50 },
    btn_select_active: { backgroundColor: colors.lightGray },
    t_select: {
      fontFamily: "DMSans-Medium",
      fontSize: 16,
      lineHeight: 22,
      color: colors.blue
    },

    // HEADER
    v_header: {
      height: 240,
      alignItems: "center",
      justifyContent: "center",
    },
    t_nodata: {
      fontFamily: "DMSans",
      fontSize: 14,
      lineHeight: 20,
      color: colors.black3
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      flatlist: { maxHeight: 300 },
    },
    [DEVICE_SIZES.SM]: {
      v_separator: {
        height: 1,
        opacity: 0.3,
        backgroundColor: colors.purple,
      },
    },
    [DEVICE_SIZES.XS]: {
      v_separator: {
        height: 1,
        opacity: 0.3,
        backgroundColor: colors.purple,
      },
    },
  }
);

export default useStyles;
