import colors from "@assets/colors";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    paginationContainer: {
      width: "100%",
      height: 56,
      backgroundColor: colors.blue,
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
    },
    pageLabel:{
      color: colors.white1,
      fontFamily: "DMSans",
      fontSize: 20,
      paddingRight: 20,
      paddingLeft: 20,
      lineHeight: 35
    },
    pageButton: {
      marginTop: 10
    }
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      pageLabel: { fontSize: 12, lineHeight: 16 }
    },
  }
);

export default useStyles;
