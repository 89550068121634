import colors from "@assets/colors";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      paddingVertical: 58,
    },
    logoMaya: {
      alignItems: "flex-start"
    },
    t_order_summary: {
      fontFamily: "DMSans-Bold",
      fontSize: 20,
      lineHeight: 23,
      color: colors.black
    },
    t_confirm: {
      fontFamily: "DMSans",
      fontSize: 15,
      lineHeight: 20,
      color: colors.black3
    },
    v_divider: {
      width: "100%",
      height: 0.75,
      marginTop: 16,
      backgroundColor: colors.gray10
    },
    v_flex_row: {
      gap: 23,
      flexDirection: "row",
      alignItems: "center",
    },
    v_plan_description: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    v_height: { height: 50 },
    ti_style: {
      flex: 1, width: 256, minWidth: 100, 
      maxWidth: 256, minHeight: 60 
    },
    ti_card: { minWidth: 256 },
    labelStyle: { 
      fontFamily: "DMSans-Bold",
      fontSize: 16,
      lineHeight: 20,
      color: colors.black3
    },
    changeYourMind: {
      fontFamily: "DmSans",
      fontSize: 14
    },
    ti_borderStyle: { minHeight: 48, borderRadius: 8, backgroundColor: colors.white },
    v_expire_divider: {width: 8},
    v_buttons_row: { 
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: 60,
    },

    btn_proceed: { marginTop: 20, height: 50, backgroundColor: colors.blue, marginHorizontal: "30%" },
    lbl_proceed: { fontFamily: "DMSans-Bold", fontSize: 16, lineHeight: 16, color: "white" },
  },
  {
    [DEVICE_SIZES.LG]: {
      container: {
        paddingVertical: 45,
      },
      t_order_summary: {
        fontSize: 15,
        lineHeight: 17.2,
      },
      t_confirm: {
        fontSize: 11.25,
        lineHeight: 15,
      },
      v_divider: { marginTop: 13 },
  
    
      ti_style: {width: 192, maxWidth: 192 },
      ti_borderStyle: { minHeight: 36, borderRadius: 6 },

      v_buttons_row: { 
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: 35,
      },
      v_flex_row: {
        gap: 18,
      },
      labelStyle: { 
        fontSize: 11,
        lineHeight: 15,
        color: colors.black3
      },

      btn_proceed: { marginTop: 16, height: 40, backgroundColor: colors.blue, borderRadius: 6 },
      lbl_proceed: { fontSize: 12, lineHeight: 12, color: "white" },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      container: {
        width: "100%",
      },
      ti_style: { width: "100%", maxWidth: "100%" },
      v_plan_description: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      v_buttons_row: { 
        flexDirection: "column", 
        alignSelf: "auto",
        marginTop: 50,
      },
      t_confirm: {
        textAlign: "center"
      },
      logoMaya: {
        alignItems: "center"
      },
      v_expire_divider: {width: 0},
      ti_card: { minWidth: "100%" },
      v_flex_row: {
        gap: 18,
        flexWrap: "wrap"
      },

      btn_proceed: { width: "100%", marginHorizontal: 0 },
    },

  }
);

export default useStyles;
