import React from "react";
import { Button01 } from "@src/components/button";
import { View, Text } from "react-native";
import useStyles from "./styles.css";
import colors from "@assets/colors";

type Props = {
  isLoading: boolean;
  onNo: () => void;
  onYes: () => void;
};

const SendFooter = (props: Props) => {
  const styles = useStyles();

  return (
    <View style={styles.v_footer}>
      <Text style={[styles.t_okay]}>Is this signature ok?</Text>
      <View style={styles.v_actions}>
        <Button01
          onPress={props.onNo}
          label="No, select another"
          style={styles.btn_no}
          labelStyle={{ color: colors.purple }}
        />
        <Button01
          isLoading={props.isLoading}
          color={colors.white}
          onPress={props.onYes}
          label="Yes, Send OTP"
          style={styles.btn_yes}
        />
      </View>
    </View>
  );
};

export default SendFooter;
