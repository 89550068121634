import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedDocumentParties } from "@src/ducks/slices/signsecure.slice";
import React, { memo, useContext } from "react";
import { Layer } from "react-konva";
import { CanvasContext } from "../../provider/canvas";
import Items from "./items";

type LayerCanvasProps = {
  item: any;
  layerId: number;
  documentId: string;
  onCheck: (value: any) => void;
  onTextFocus: (value: any) => void;
  onTextUpdate: (value: any) => void;
  onShowSignature: (value: any) => void;
  onShowQuickSigning: (value: any) => void;
  onShowTextBox: (value: any) => void;
}

const LayerCanvas = ({ item, layerId, documentId, onShowSignature, onShowQuickSigning, onCheck, onTextFocus, onTextUpdate, onShowTextBox }: LayerCanvasProps) => {
  const { canvas } = useContext(CanvasContext);
  const userParty = useAppSelector(selectedDocumentParties);

  return (
    <Layer>
      {item.map((field: any, index: number) => {
        const isTextInput = field.type === "textinput"
        if (isTextInput) {
          return null
        }
        if(field.trackId !== userParty.trackId){
          return null;
        }
        return (
          <Items
            key={index.toString()}
            field={{
              ...field,
              documentId,
              config: {
                ...field.config,
                x: field?.config?.x * canvas.scale,
                y: field?.config?.y * canvas.scale,
                width: field?.config?.width * canvas.scale,
                height: field?.config?.height * canvas.scale,
              },
              textConfig: {
                ...field.textConfig,
                fontSize: field?.textConfig?.fontSize * canvas.scale,
              },
              nameConfig: {
                ...field.nameConfig,
                y: field?.nameConfig?.y * canvas.scale,
                fontSize: field?.nameConfig?.fontSize * canvas.scale,
              },
              dateConfig: {
                ...field.dateConfig,
                y: field?.dateConfig?.y * canvas.scale,
                fontSize: field?.dateConfig?.fontSize * canvas.scale,
              },
              designationConfig: {
                ...field.designationConfig,
                y: field?.designationConfig?.y * canvas.scale,
                fontSize: field?.designationConfig?.fontSize * canvas.scale,
              },
              checkConfig: {
                ...field.checkConfig,
                width: field?.checkConfig?.width * canvas.scale,
                height: field?.checkConfig?.height * canvas.scale,
                marginRight: field?.checkConfig?.marginRight * canvas.scale,
              },
              inputConfig: {
                ...field.inputConfig,
                fontSize: field?.inputConfig?.fontSize * canvas.scale,
                width: field?.inputConfig?.width * canvas.scale,
                height: field?.inputConfig?.height * canvas.scale,
              },
            }}
            onSelectTextBox={() => onShowTextBox({ layerId, fieldId: index, type: field.type })}
            onSelect={() => onShowSignature({ layerId, fieldId: index, type: field.type })}
            onQuickSign={() => onShowQuickSigning({ layerId, fieldId: index, type: field.type })}
            onCheck={() => onCheck({ layerId, fieldId: index, type: field.type })}
            onTextFocus={() => onTextFocus({ layerId, fieldId: index, type: field.type, value: true })}
            onTextUpdate={(value) => onTextUpdate({ layerId, fieldId: index, type: field.type, value })}
          />
        );
      })}
    </Layer>
  );
};

export default memo(LayerCanvas);
