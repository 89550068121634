import colors from "@assets/colors";
import { Feather } from "@expo/vector-icons";
import { RenderPrintProps } from "@react-pdf-viewer/print";
import { RenderZoomOutProps } from "@react-pdf-viewer/zoom";
import { AuthContext } from "@src/AuthProvider";
import { Button02 } from "@src/components/button";
import { useFetchGet } from "@src/utils/api/hooks";
import downloadjs from "downloadjs";
import _ from "lodash";
import React, { useContext } from "react";
import { View } from "react-native";
import { CanvasContext } from "../provider/canvas";
import { PluginContext } from "../provider/plugin";
import useStyles from "./styles.css";
import { SignatureContext } from "../provider/signature";
import { useAuthService } from "@src/ducks/hooks";

const PDFPages = ({ document }: any) => {
  const styles = useStyles();
  const { canvas } = useContext(CanvasContext);
  const { onErrorMessage } = useContext(AuthContext);
  const { data, runRequest, error } = useFetchGet({responseType: "blob"});
  const { zoomPluginInstance, printPluginInstance, pageNavigationPluginInstance } = useContext(PluginContext);
  const { setEncryptedPassword, encryptedPassword} = useContext(SignatureContext);
  const { jumpToPage } = pageNavigationPluginInstance;
  const { ZoomIn, ZoomOut } = zoomPluginInstance;
  const { Print } = printPluginInstance;
  const { userId } = useAuthService();

   const showPDFLock = document.workflow.status == "COMPLETED" &&
                       document.password == "" && 
                       document.createdBy ===  userId ? true : false;
  const onDownload = () => {
    runRequest("/directory/download/" + document.id);
  };

  React.useEffect(() => {
    if(data){
      downloadjs(
        data,
        `${document.name}.pdf`,
        "application/pdf"
      );
    }
  },[data]);

  React.useEffect(() => {
    if(!_.isEmpty(error)){
      onErrorMessage(error.message);
    }
  },[error]);
  const encryptPasswords = () => {
    setEncryptedPassword(true)
};
  return (
    <View pointerEvents="box-none" style={styles.main}>
      <View style={styles.zoom_container}>
        <ZoomIn>
          {({ onClick }: RenderZoomOutProps) => (
            <Button02
              disabled={canvas.scale > 1.7}
              onPress={() => {
                onClick();
                const timeout = setTimeout(() => {
                  clearTimeout(timeout);
                  jumpToPage(canvas.pageIndex);
                }, 100);
              }}
              style={styles.pdf_btn_zoom}
            >
              <Feather
                name="zoom-in"
                color={canvas.scale > 1.7 ? colors.offwhite3 : colors.white}
                size={20}
              />
            </Button02>
          )}
        </ZoomIn>
        <View style={styles.divider} />
        <ZoomOut>
          {({ onClick }: RenderZoomOutProps) => (
            <Button02
              disabled={canvas.scale < 0.7}
              style={styles.pdf_btn_zoom}
              onPress={() => {
                onClick();
                const timeout = setTimeout(() => {
                  clearTimeout(timeout);
                  jumpToPage(canvas.pageIndex);
                }, 100);
              }}
            >
              <Feather
                name="zoom-out"
                color={canvas.scale < 0.7 ? colors.offwhite3 : colors.white}
                size={20}
              />
            </Button02>
          )}
        </ZoomOut>
        <View style={styles.divider} />
        <Button02
          onPress={onDownload}
          style={styles.pdf_btn_zoom}
        >
          <Feather
            name="download"
            color={colors.white}
            size={20}
          />
        </Button02>
        <View style={styles.divider} />
        <Print>
          {({ onClick }: RenderPrintProps) => (
            <Button02
              onPress={onClick}
              style={styles.pdf_btn_zoom}
            >
              <Feather
                name="printer"
                color={colors.white}
                size={20}
              />
            </Button02>
          )}
        </Print>
      
        <View style={styles.divider} />
        { showPDFLock &&
        <Button02
          onPress={encryptPasswords}
          style={styles.pdf_btn_zoom}
        >
          <Feather
            name="lock"
            color={colors.white}
            size={20}
          />
        </Button02>
        }
      </View>
    </View>
  );
};

export default React.memo(PDFPages);
