import React from "react";
import { View, Text, ScrollView, Pressable, Modal, useWindowDimensions } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import useStyles from "./styles.css";
import Parties from "./parties";
import Details from "./details";
import MailContent from "./mail-content";
import ExpirationReminder from "./expiration-reminder";
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";
import { SignatureContext } from "../provider/signature";
import { useDocumentDetails } from "@src/utils/screensize-helper";
const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

const DocumentDetailsMobile = () => {
  const styles = useStyles();
  const size = useDocumentDetails();
  const animated = useSharedValue(size);
  const { height } = useWindowDimensions();
  const { showDocumentDetails, setShowDocumentDetails } = React.useContext(SignatureContext);
  const onClose = () => {
    animated.value = withTiming(size, { duration:  1 });
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      setShowDocumentDetails(false);
    }, 0);
  };

  const animateStyle = useAnimatedStyle(() => {
    const translateX = animated.value;

    return {
      transform: [{ translateX }],
      height,
    };
  }, []);

  React.useEffect(() => {
    if (showDocumentDetails) {
      animated.value = withTiming(0, { duration: 0 });
    }
  }, [showDocumentDetails]);

  if (!showDocumentDetails) {
    return null;
  }

  return (
    <Modal
      transparent
      visible={showDocumentDetails}
      animationType={"none"}
      onRequestClose={onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <View style={styles.container}>
        <AnimatedScrollView style={styles.formContainer}>
          <Animated.View >
          <View style={{flexDirection:"row"}}>
            <Text style={styles.h4Bold}>Document Details</Text>
            <Pressable onPress={onClose} style={styles.btn_close} >
              <Icon name="close" size={24} color={"black"} />
            </Pressable>
          </View>
            <Details />
            <MailContent />
            <Parties />
            <ExpirationReminder />
            <View style={styles.v_height60} />
          </Animated.View>
        </AnimatedScrollView>
      </View>
    </Modal>
  );
};

export default DocumentDetailsMobile;
