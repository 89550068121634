import React, { useCallback, useContext, useMemo } from "react";
import { Stage } from "react-konva";
import { View } from "react-native";
import { CanvasContext } from "../provider/canvas";
import { SignatureContext } from "../provider/signature";
import SigningModal from "../signing-modal";
import TextBoxModal from "../textbox-modal";
import LayerCanvas from "./layer/index";
import useStyles from "./styles.css";

function Stages(props: any) {
  const styles = useStyles();
  const { loading, documentId, layers } = props;
  const { canvas, stageRef } = useContext(CanvasContext);
  const { onShowModal, onShowTextBoxModal, onShowQuickSigning,
    onCheckBoxItem, onTextBoxFocus, onTextBoxItem } = React.useContext(SignatureContext);

  const { CANVAS_WIDTH, CANVAS_HEIGHT } = useMemo(() => {
    const C_WIDTH = canvas.width * canvas.scale  || 0;
    const C_HEIGHT = canvas.height * canvas.scale || 0;

    return { CANVAS_WIDTH: C_WIDTH, CANVAS_HEIGHT: C_HEIGHT };
  }, [canvas]);

  const checkDeselect = (e: any) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      layers[canvas.pageIndex].forEach((field: any, index: number) => {
        if(field.type === "textbox" && field.isFocus){
          onTextBoxFocus({ layerId: canvas.pageIndex, fieldId: index, type: field.type, value: false });
        }
      });
    }
  };

  const onShowTextBox = useCallback(onShowTextBoxModal,[]);
  
  return (
    <View
      style={[
        styles.v_stage_container,
        {
          width: CANVAS_WIDTH,
          height: CANVAS_HEIGHT,
        },
      ]}
    >
      <Stage
        ref={stageRef}
        width={CANVAS_WIDTH}
        height={CANVAS_HEIGHT}
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
      >
        {loading === false &&
          layers.map((item: any, index: number) => {
            if (index === canvas.pageIndex) {
              return (
                <LayerCanvas
                  key={index.toString()}
                  item={item}
                  layerId={index}
                  documentId={documentId}
                  onCheck={onCheckBoxItem}
                  onTextFocus={onTextBoxFocus}
                  onTextUpdate={onTextBoxItem}
                  onShowSignature={onShowModal}
                  onShowQuickSigning={onShowQuickSigning}
                  onShowTextBox={onShowTextBox}
                />
              );
            }

            return null;
          })}
      </Stage>
      <SigningModal />
      <TextBoxModal />
    </View>
  );
}

export default React.memo(Stages);
