import colors from "@assets/colors";
import { nanoid } from "@reduxjs/toolkit";
import { AuthContext } from "@src/AuthProvider";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { selectedDraftDocument, signsecureActions } from "@src/ducks/slices/signsecure.slice";
import { selectSubscriptionSimultaneousUpload } from "@src/ducks/slices/user.slice";
import _ from "lodash";
import React, { createContext, useCallback, useState, Dispatch, SetStateAction } from "react";
import { FileValue, ProviderProps } from "./types";
import AddEncrpytPassword from "../modals/Index";
import AddSuccessful from "../modals/password-success";

const parties = [{
  trackId: nanoid(),
  name: "",
  email: "",
  designation: "",
  role: "Needs to sign",
  fill: colors.signature2,
  ref: React.createRef(),
}];

export interface ContextValue {
  folderId: string;
  files: FileValue[];
  shareable: boolean;
  encyptedPassword: boolean;
  checkBoxTick: boolean;
  enableGeneratePassword: boolean;
  addSuccess: boolean;
  foldername: string;
  generatePassword: string;
  pdfpasswords: string;
  setGeneratePassword:  Dispatch<SetStateAction<string>>;
  enableApplyToAllParties: boolean;
  pdfpassword: string;
  fileActiveIndex: number;
  onSetShareable: () => void;
  onSetPdfEncryptPassword: (value: any) => void;
  onRemoveFile: (index: any) => void;
  onSelectFile: (value: any) => void;
  onSetFolderName: (value: string) => void;
  onApplyToAllPrivacyType: (value: string) => void;
  onUpdateFile: (index: number, key: string, value: any) => void;
  onApplyToAllParties: () => void;
  copyPartiesToAll: () => void;
  setGeneratePasswordTick: Dispatch<SetStateAction<boolean>>;
  setPDFPassword: Dispatch<SetStateAction<string>>;
  setPasswordEncrpyt: Dispatch<SetStateAction<boolean>>;
  setSuccessful: Dispatch<SetStateAction<boolean>>;
  setCheckBoxTick: Dispatch<SetStateAction<boolean>>;
  setFileActiveIndex:  Dispatch<SetStateAction<Number>>;
}

const DocumentContext = createContext<ContextValue>({} as ContextValue);

const DocumentProvider = ({ children }: ProviderProps) => {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<any>([]);
  const [folderId, setFolderId] = useState<string>("");
  const [generatePassword, setGeneratePassword] = React.useState<string>("");
  const [pdfpasswords, setPDFPassword] = React.useState<string>("");
  const { onErrorMessage } = React.useContext(AuthContext);
  const [foldername, setFoldername] = useState<string>("");
  const [shareable, setShareable] = useState<boolean>(false);
  const [encyptedPassword, setPasswordEncrpyt] = useState<boolean>(false);
  const [checkBoxTick, setCheckBoxTick] = useState<boolean>(false);
  const [enableGeneratePassword, setGeneratePasswordTick] = useState<boolean>(false);
  const [pdfpassword, setPdfpassword] = useState<string>("");
  const draftDocument = useAppSelector(selectedDraftDocument);
  const [enableApplyToAllParties, setEnableIamSignatory] = React.useState(false);
  const maxSimultaneousUpload = useAppSelector(selectSubscriptionSimultaneousUpload);
  const [ addSuccess, setSuccessful] = React.useState(false);
  const [fileActiveIndex ,setFileActiveIndex]= React.useState<number>(0);

  const onSetShareable = useCallback(() => {
    setShareable(!shareable);
  }, [shareable]);

  const onSetPdfEncryptPassword = useCallback((value: any) => {
   
    setPdfpassword(value);
  }, [pdfpassword]);

  const onApplyToAllPrivacyType = useCallback((privacyType: string) => {
    setFiles((prevData: any) =>
      prevData.map((item: FileValue) => {
        const newItem = {...item, privacyType};

        return newItem;
      })
    );
  }, [files]);

  const onSetFolderName = useCallback(setFoldername, [foldername]);

  const onApplyToAllParties = useCallback(() => {
    setEnableIamSignatory(!enableApplyToAllParties);
  }, [enableApplyToAllParties]);

  const copyPartiesToAll = useCallback(() => {
    const firstparties = files[0].parties;
    const emails = firstparties.map((party: any) => party.email);
    setFiles((prevData: any) =>
      prevData.map((item: FileValue) => {
        const newItem = {...item};

        newItem.parties = firstparties;
        if(!_.isEmpty(newItem.signatures)){
          newItem.signatures = newItem.signatures.map((item: any ) => {
            const layerFields = item.layerFields.reduce((result: any[], layer: any) => {
              if(layer.email && !emails.includes(layer.email)){
                // do nothing
              } else{
                result.push(layer);
              }
    
              return result;
            },[]);
    
            return {...item, layerFields};
          });
        }

        return newItem;
      })
    );
  }, [files]);

  const onRemoveFile = useCallback((item: any) => {
    const updatedData = files.filter((i: any) => !_.isEqual(i, item));
    setFiles(updatedData);
    if(updatedData.length > 0){
      onSetFolderName(updatedData[0].name);
    }
  }, [files]);

  const onSelectFile = useCallback((value: any[]) => {
    if(files.length + value.length > maxSimultaneousUpload){
      const message = `Limit upload to ${maxSimultaneousUpload} files only.`;
      onErrorMessage(message);
      return;
    }

    const addedFiles = value.map((item: any) => {
      return {
        size: item.size,
        name: item.name,
        path: item.file,
        subject: `Please sign ${item.name}`,
        type: item.mimeType,
        lastModified: item.lastModified,
        lastModifiedDate: item.lastModifiedDate,
        uri: item.uri,
        parties,
        trackId: nanoid(), 
        ref: React.createRef(), 
        qrposition: "Bottom right",
        privacyType: "CONFIDENTIAL",
        password: ""
      };
    });
    const newfiles = files.concat(addedFiles);

    setFiles(newfiles);
    if(newfiles.length > 0){
      onSetFolderName(newfiles[0].name);
    }
  }, [files, maxSimultaneousUpload]);

  const onUpdateFile = useCallback(
    (index: number, key: string, value: any) => {
      setFiles((prevData: any) =>
        prevData.map((item: FileValue, i: number) =>
          i === index ? { ...item, [key]: value } : item
        )
      );
    },
    [files]
  );

  const value = {
    files,
    shareable,
    foldername,
    encyptedPassword,
    addSuccess,
    folderId,
    enableApplyToAllParties,
    pdfpassword,
    checkBoxTick, 
    onSetShareable,
    onSetFolderName,
    onRemoveFile,
    onSelectFile,
    onUpdateFile,
    onApplyToAllParties,
    copyPartiesToAll,
    onApplyToAllPrivacyType,
    setPasswordEncrpyt,
    setSuccessful,
    onSetPdfEncryptPassword,
    setCheckBoxTick,
    enableGeneratePassword, setGeneratePasswordTick,
    generatePassword, setGeneratePassword,
    pdfpasswords, setPDFPassword,
    fileActiveIndex, setFileActiveIndex
  };

  React.useEffect(() => {
    if(!_.isEmpty(draftDocument)){
      const clone = _.cloneDeep(draftDocument.files);
      setFiles(clone);
      setFolderId(draftDocument.folderId);
      setFoldername(draftDocument.folderName);
      setShareable(draftDocument.isShareToTeam);
      dispatch(signsecureActions.saveDraftDocument({}));
    }
  }, [draftDocument]);

  return (
    <DocumentContext.Provider value={value}>
      {children}
      <AddEncrpytPassword 
        visible={encyptedPassword}
      />
      <AddSuccessful 
        visible={addSuccess}
      />
    </DocumentContext.Provider>
  );
};

export { DocumentContext, DocumentProvider };

