import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.blurBG,
    },
    v_modal: {
      width: 512,
      height: 398,
      borderRadius: 10,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    t_message: {fontFamily: "DMSans-Bold", fontSize: 24, marginTop: 35,
      lineHeight: 28, color: colors.colorPrimary, textAlign: "center",},
    btn_cancel: { width: 156, height: 47, marginTop: 46, paddingHorizontal: 0,
      backgroundColor: colors.transparent, borderWidth: 1, borderColor: colors.colorPrimary },
    label_cancel: { fontFamily: "DMSans-Bold", color: colors.colorPrimary },
    btn_proceed: { width: 156, height: 47, marginTop: 46, 
      paddingHorizontal: 0, backgroundColor: colors.colorPrimary },
    label_proceed: { fontFamily: "DMSans-Bold" },
    divider: { width: 22 },

    // DECLINE TO SIGN
    v_modal_decline: {
      width: 776,
      height: 453,
      borderRadius: 8,
      alignItems: "center",
      paddingHorizontal: 50,
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    v_row_label: {width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between"},
    t_declinetosign: { fontFamily: "DMSans-Bold", fontSize: 24, 
      lineHeight: 28, color: colors.colorPrimary},
    ti_message: {fontSize: 18, lineHeight: 24, color: colors.darkGray},
    ti_borderStyle: {width: 676, height: 193, marginTop: 15},
    v_row_buttons: {flexDirection: "row", alignItems: "center"},
    decline_btn_width: { width: 328 },
  },
  {
    [DEVICE_SIZES.LG]: {
      v_modal: { width: 492, height: 385, paddingHorizontal: 40 },
      t_message: { fontSize: 18, lineHeight: 22.5, marginTop: 25, },
      btn_proceed: { width: 145, height: 35, marginTop: 40, borderRadius: 6 },
      btn_cancel: { width: 145, height: 35, marginTop: 40, borderRadius: 6 },
      label_proceed: { fontSize: 10.5, lineHeight: 10.5 },
      label_cancel: { fontSize: 10.5, lineHeight: 10.5 },
      divider: { width: 17 },

      v_modal_decline: { width: 700, height: 413, paddingHorizontal: 45 },
      t_declinetosign: { fontSize: 18, lineHeight: 22.5 },
      ti_message: {fontSize: 15, lineHeight: 20},
      ti_borderStyle: {width: 610, height: 180, marginTop: 12},
      decline_btn_width: { width: 297 },

    },
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {
      v_row_buttons: {flexDirection: "column", alignItems: "center", paddingTop: 50},
      v_modal_decline: {
        width: 381,
        height: 600,
        borderRadius: 8,
        alignItems: "center",
        paddingHorizontal: 20,
        justifyContent: "center",
        backgroundColor: colors.white,
      },
      v_row_label: {
        paddingTop: 0
      },  
      v_modal: {
        width: "95%",
      },
      decline_btn_width: { width: 331 },
      btn_proceed: { width: 145, height: 45, marginTop: 20, borderRadius: 6 },
      btn_cancel: { width: 145, height: 45, marginTop: 20, borderRadius: 6 },
      ti_borderStyle: {width: 331, height: 224, marginTop: 15},
      ti_message: {fontSize: 16, lineHeight: 24, color: colors.darkGray},
      t_declinetosign : {
          color: colors.black3
      },
    },
  }
);

export default useStyles;
