import React from "react";
import { ActivityIndicator, Text, View } from "react-native";
import useStyles from "./styles.css";

function Header(props: any) {
  const styles = useStyles();

  if(props.hide){
    return null;
  }

  return (
    <View style={styles.v_header}>
      {props.loading ? 
        <ActivityIndicator animating size={30} color="black" /> : 
        <Text style={styles.t_nodata}>There is currently no data available.</Text>}
    </View>
  );
}

export default Header;