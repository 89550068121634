import { tabletSize } from "@src/utils/screensize-helper";
import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { ScrollView, View } from "react-native";
import CopyRightContent from "./copy-right";
import SignUpNow from "./signupnow";
import useStyles from "./styles.css";
import WelcomGuest from "./welcome-guest";

const DrawerComponentScreen = () => {
  const styles = useStyles();
  const isMobile = tabletSize();

  return (
    <View pointerEvents="box-none" style={[styles.v_drawer_container ]}>
      <LinearGradient
        colors={["#4E70BB", "#253A60", "#253A60", "#253A60"]}
        style={[styles.linear_style]}
      />
      <View style={[styles.linear_style]}>
        <ScrollView showsHorizontalScrollIndicator={false}>
          <WelcomGuest />
          <View style={styles.v_height} />
          <View style={styles.divider} />
          <SignUpNow />
        </ScrollView>
        {!isMobile ? <CopyRightContent /> : "" }
      </View>
    </View>
  );
};

export default React.memo(DrawerComponentScreen);
