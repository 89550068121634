import React from "react";
import File from "./file.svg";
import Folder from "./folder.svg";
import Chat from "./chat.svg";
import { IconProps } from "@src/components/icon/interface";

const SVGDashboard = (props: IconProps) => {
  const { name, width, height } = props;
  switch (name) {
  case "file":
    return <File width={width || 14} height={height || 14} {...props} />;
  case "folder":
    return <Folder width={width || 14} height={height || 12} {...props} />;
  case "chat":
    return <Chat width={width || 50} height={height || 30} {...props} />;
  default:
    return null;
  }
};

export default React.memo(SVGDashboard);
