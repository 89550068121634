import React  from "react";
import { SignatureContext } from "../provider/signature";
import { Text, View } from "react-native";
import useStyles from "./styles.css";

const ExpireReminder = () => {
  const styles = useStyles();
  const { document } = React.useContext(SignatureContext);

  return (
    <View>
      <Text style={styles.body1MR}>Expiration and Reminder Options</Text>
      <Text style={[styles.body2, { marginTop: 5}]}>
        <Text style={styles.body2_bold}>Document Expiry Date: </Text> 
        {document?.workflow?.reminders?.expiration}
      </Text>
      <Text style={[styles.body2, { marginTop: 5}]}>
        <Text style={styles.body2_bold}>Send Reminder Date: </Text> 
        {document?.workflow?.reminders?.expiration}
      </Text>
    </View>
  );
};

export default React.memo(ExpireReminder);
