import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import { ODOO_HELPDESK_TOKEN, ODOO_HELPDESK_URL } from "@env";
import Icon from "@expo/vector-icons/MaterialIcons";
import { AuthContext } from "@src/AuthProvider";
import Button from "@src/components/button/Button01";
import { TextInput02 } from "@src/components/textinput";
import { useAppSelector } from "@src/ducks/ducksHook";
import {
  selectUserEmail,
  selectUserFullname,
} from "@src/ducks/slices/user.slice";
import { useInputHelper } from "@src/utils/inputs-helper";
import { desktopSize, mobileSize, tabletSize } from "@src/utils/screensize-helper";
import { convertFileListToArray } from "@src/utils/transform-helper";
import { useValidation } from "@src/utils/validation-helper";
import * as DocumentPicker from "expo-document-picker";
import _ from "lodash";
import React, { forwardRef, useContext, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { DrawerContext } from "../drawer-screens/drawer-provider";
import useStyles from "./styles";
import SuccessModal from "./success-modal";
import useOrientationHelper from "@src/utils/orientation-helper";
import BackButton from "../affix-document/header/affix-signature/back-button";
import { selectedAuthGuestRole } from "@src/ducks/slices/auth.slice";

const Support = forwardRef(({ isVisible, setIsVisible }: any, ref: any) => {
  const styles = useStyles();
  const email = useAppSelector(selectUserEmail);
  const fullname = useAppSelector(selectUserFullname);
  const { onErrorMessage } = useContext(AuthContext);
  const { activeTab } = useContext(DrawerContext);
  const [source, setSource] = React.useState<any>(null);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const { errorInput, checkValidation, onSetError } = useValidation();
  const { state, onDispatch, onSetInitial } = useInputHelper({
    email: "",
    fullname: "",
    message: "",
    files: "",
  });
  const { onActiveTab, setIsMaximize } = useContext(DrawerContext);
  const isMobile = mobileSize();
  const isTablet = tabletSize();
  const isDesktop = desktopSize();
  const isLandScape = useOrientationHelper();
  const isGuest = useAppSelector(selectedAuthGuestRole);

  React.useEffect(() => {
    onSetInitial({
      email,
      fullname,
      message: "",
      files: "",
    });
  }, [email, fullname]);

  React.useEffect(() => {
    if (!_.isEmpty(errorInput)) {
      onSetError({});
    }
  }, [state]);

  const closeSuccessModal = React.useCallback(() => {
    setOpenSuccessModal(false);
  }, [openSuccessModal]);

  const onClickMaximize = () => {
    onActiveTab("Support");
    setIsVisible(false);
    setIsMaximize(true);
  };

  const submitSupportBtn = async () => {
    const requiredFields = ["fullname", "email", "message"];
    const errors = checkValidation(requiredFields, state);

    if (_.isEmpty(errors)) {

      try {

        const createUserParams = {
          jsonrpc: "2.0",
          params: {
            record_type : "res.partner",
            action : "create", //create | update
            records : [{
              name : fullname,
              email: email
            }]
          }
        };

        const responseUserCreation = await fetch(`${ODOO_HELPDESK_URL}`, {
          method: "POST",
          headers: {
            Authorization: `${ODOO_HELPDESK_TOKEN}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(createUserParams),
        });

        const odooContactId = responseUserCreation.json().then(sample => {
          console.log(sample.result.data[0]);

          return sample.result.data[0].id;
        });


        const params = {
          jsonrpc: "2.0",
          params: {
            record_type: "helpdesk.ticket",
            action: "create", //create | update
            records: [{
              name: "Support Ticket From SignSecure V2",
              team_id: 1,
              user_id: 13,
              partner_id: odooContactId,
              partner_name : fullname,
              partner_email: email,
              priority: "0",
              description: state.message
            }]
          }
        };

        const response = await fetch(`${ODOO_HELPDESK_URL}`, {
          method: "POST",
          headers: {
            Authorization: `${ODOO_HELPDESK_TOKEN}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        });

        if (response.ok === true) {
          setOpenSuccessModal(true);
          setSource(null);

          onSetInitial({
            email,
            fullname,
            message: "",
            files: "",
          });
        }
  
        console.log(response);
          
      } catch (error) {
        console.error("Error submitting support request:", error);
        onErrorMessage("An unexpected error occurred. Please try again later.");
      }
    }
    onSetError(errors);
  };

  const handleUpload = async () => {
    DocumentPicker.getDocumentAsync({
      type: ["image/png", "image/jpg", "image/jpeg"],
      multiple: true,
    })
      .then(async (res: any) => {
        const files = await convertFileListToArray(res.output);
        const totalFileSize = files.reduce((totalSize, file) => totalSize + file.size, 0);
        const maxSizeLimit = 25 * 1024 * 1024; // 25MB limit

        if (totalFileSize > maxSizeLimit) {
          onErrorMessage("Exceeded file size limit of 25MB.");
        } else {
          setSource(files);
        }

      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...source];
    updatedFiles.splice(index, 1);
    setSource(updatedFiles);
  };

  const onClickFAQsBtn = () => {
    onActiveTab("FAQs");
    setIsVisible(false);
  };

  const ContainerScrollView = isMobile ? View : ScrollView;

  return (
    <>
    <View style={!isVisible ? styles.backButtonGuest : styles.displayHide}>
        { isVisible||isGuest && <BackButton />}
    </View>
    <View ref={ref} style={[isMobile && styles.container, isLandScape && styles.containerLandscape]}>
      <Text style={[styles.header_label]}>{isVisible || isMobile ? "" : "How can we help you?"}</Text>
      {
        isVisible &&
        <View style={styles.header}>
          <Text style={styles.headerText}>Support</Text>
          <View style={styles.icons}>
            <Icon style={styles.icon} onPress={() => setIsVisible(false)} name="minimize" size={15} color="white" />
            <Icon style={styles.icon} onPress={onClickMaximize} name="open-in-full" size={15} color="white" />
            <Icon style={styles.icon} onPress={() => setIsVisible(false)} name="close" size={15} color="white" />
          </View>
        </View>
      }
      <ContainerScrollView 
        contentContainerStyle={[styles.contentContainer, 
          activeTab === "Support" && styles.contentContainerHeight,
          isVisible && styles.marginChatbox && styles.tabletContainerSize, 
          isGuest && styles.tabletGuest, isLandScape && styles.landScapeMobile]} 
        showsVerticalScrollIndicator={false} 
        showsHorizontalScrollIndicator={false}
      >
        <View style={[!isVisible && styles.cardContainer, isVisible && styles.maximizedSupportPage, isTablet && styles.tabContainer]}>
          <Text style={[styles.title, (!isVisible && !isMobile) && styles.titleViewWeb]}>
            { 
              isMobile ? 
                "Please fill out the form below to connect \nwith us, and we will get back to you at the \nsoonest possible time." 
                : 
                "Please fill out the form below to connect with us, and we will get back to you at the soonest possible time."
            }
          </Text>
          <TextInput02
            label="Email*"
            value={state.email}
            error={errorInput.email}
            inputStyle={{ backgroundColor: colors.white }}
            onChangeText={onDispatch("email")}
            placeholder="Enter your username"
          />
          <TextInput02
            label="Name*"
            value={state.fullname}
            error={errorInput.fullname}
            inputStyle={{ backgroundColor: colors.white }}
            onChangeText={onDispatch("fullname")}
            placeholder="Enter your name"
            style={isVisible && styles.inputMargin}
          />
          <View style={{ minHeight: 150 }}>
            <TextInput02
              multiline
              label="Message*"
              value={state.message}
              error={errorInput.message}
              borderStyle={styles.message}
              inputStyle={{ paddingTop: 20, backgroundColor: colors.white }}
              onChangeText={onDispatch("message")}
              placeholder="Input your message here"
              style={isVisible && styles.inputMargin}
            />
          </View>

          <View style={[styles.v_actions, !isVisible && styles.v_actions_web]}>
            <View style={[styles.v_upload_web, isVisible && styles.v_actions]}>
              <View style={styles.flex1}>
                <Text style={[styles.fieldTextWeb, isVisible && styles.fieldText]}>Upload attachment/s:</Text>
                <Text style={[styles.fieldTextWeb, isVisible && styles.fieldText]}>Max size: 25mb</Text>
              </View>
              <View style={[styles.upload_chatbox]}>
                <Button
                  style={styles.chooseFile}
                  labelStyle={{ color: colors.lightPurple }}
                  label="Choose File/s"
                  onPress={handleUpload}
                />
                <View style={styles.flex1 && styles.alignCenter}>
                  {source && source.length > 0 && (
                    <Text style={styles.fieldText}>Attached files:</Text>
                  )}
                  {source && source.length > 0 ? (
                    source.map((file: any, index: number) => (
                      <View key={index} style={styles.fileContainer}>
                        <Text style={[styles.fieldText, (isVisible || isMobile) && styles.fileNames]}>{file.name}</Text>
                        <TouchableOpacity
                          onPress={() => handleRemoveFile(index)}
                          style={styles.deleteIcon}
                        >
                          <Icon name="delete" color={colors.purple} />
                        </TouchableOpacity>
                      </View>
                    ))
                  ) : (
                    <Text style={styles.fieldText}>No file chosen</Text>
                  )}
                </View>
              </View>
            </View>
            <Button label="Submit" style={[styles.btnSubmit, (!isVisible && !isMobile) && styles.btnSubmitWeb]} onPress={() => submitSupportBtn()} />
            <View style={styles.itemSeparator} />
            {(!isDesktop || (isDesktop && isVisible)) && 
              ( 
                <View style={styles.faqs_container}>
                  <Text style={styles.faqs_redirect}>Need information on SignSecure V2?</Text>
                  <Text style={styles.faqs_clickhere}>{"Click here to access our FAQs"}</Text>
                  <Button label="SignSecure V2 FAQs" style={styles.faqs_btn} onPress={onClickFAQsBtn} />
                </View> 
              )
            }
          </View>
          <View style={[styles.v_power_by_odoo, 
            (!isDesktop || (isDesktop && isVisible)) && styles.v_power_by_odoo_desktop]}>
            <SVGIcon name="power-by-odoo" />
          </View>
        </View>
      </ContainerScrollView>
      <SuccessModal visible={openSuccessModal} onClose={closeSuccessModal} />
      <View style={styles.v_extraheight} />
    </View>
    </>
  );
});

export default Support;