import React from "react";
import { Button01 } from "@src/components/button";
import useStyles from "./styles.css";
import colors from "@assets/colors";
import images from "@assets/images";
import { API_BASE_URL } from "@env";

function GoogleButton(props: any) {
  const styles = useStyles();

  const onSignIn = async () => {
    window.location.href = `${API_BASE_URL}/auth/google`;
  };

  return (
    <Button01
      onPress={onSignIn}
      source={images.google}
      color={colors.gray1}
      label=" "
      isLoading={props.isLoading}
      labelStyle={{marginRight: -45, width: 30, height: 30}}
      style={styles.btn_google}
    />
  );
}

export default React.memo(GoogleButton);