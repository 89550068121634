import Loading from "@src/components/loading";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useSignsecureService } from "@src/ducks/hooks";
import { selectedAuthAccessToken } from "@src/ducks/slices/auth.slice";
import { selectedAffixDocument, selectedDocumentParties, selectedSignSecureLoading } from "@src/ducks/slices/signsecure.slice";
import { downloadSignature, signatureDefault } from "@src/utils/api";
import useOrientationHelper from "@src/utils/orientation-helper";
import { countSignatures } from "@src/utils/validation-helper";
import _ from "lodash";
import React from "react";
import { Pressable, Text, View } from "react-native";
import { SignatureContext } from "../../provider/signature";
import FaceRecognition from "./camera";
import useStyles from "./styles.css";

const ScreenTwo = (props: any) => {
  const styles = useStyles();
  const isLandScape = useOrientationHelper();
  const [loading, setLoading] = React.useState(false);
  const { signDocumentRequest } = useSignsecureService();
  const [completed, setCompleted] = React.useState(false);
  const userParty = useAppSelector(selectedDocumentParties);
  const affixing = useAppSelector(selectedSignSecureLoading);
  const affixDocument = useAppSelector(selectedAffixDocument);
  const accessToken = useAppSelector(selectedAuthAccessToken);
  const { document, onSetSignature, setShowQuickSigning } = React.useContext(SignatureContext);
  const signature = React.useMemo(() => countSignatures(document, userParty), [document, userParty]);
  
  const handleScanComplete = React.useCallback(async() => {
    try {
      setLoading(true);
      const result = await signatureDefault(accessToken);
      const base64 = await downloadSignature(result.data._id || result.data.id, accessToken);
      
      onSetSignature({
        signature: base64,
        signatureType: "Signed with UNAWA Face Recognition",
        signatureId: result.data._id || result.data.id
      });

      if(signature.count > 1){
        setShowQuickSigning(false);
        props.onChangeScreen("SCREEN1");
      }else{
        setCompleted(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  },[accessToken, signature.count]);

  React.useEffect(() => {
    if(completed){
      signDocumentRequest(document);
    }
  }, [completed]);

  React.useEffect(() => {
    if(!_.isEmpty(affixDocument)){
      props.onChangeScreen("SCREEN3");
    }
  }, [affixDocument]);

  return (
    <View style={styles.v_screen_1}>
      <Pressable onPress={() => setShowQuickSigning(false)} style={styles.btn_close} />
      <View style={[ styles.v_screen_2 ]} >
        <Text style={styles.t_note2}>Focus on your face</Text>
        <FaceRecognition
          isLandScape={isLandScape}
          onScanning={() => console.log("scanning")}
          onScanFailed={() => console.log("scan failed")}
          onScanCompleted={handleScanComplete}
        />
        {(loading || affixing) && <Loading style={{position: "absolute"}} color={"white"} />}
      </View>
    </View>
  );
};

export default ScreenTwo;
