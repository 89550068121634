import React from "react";
import { View, Text, Modal } from "react-native";
import { SignatureContext } from "../provider/signature";
import { ButtonIcon } from "@src/components/button";
import TextboxSection from "./textbox-section";
import useStyles from "./styles.css";
import images from "@assets/images";

const TextBoxModal = () => {
  const styles = useStyles();
  const { selectedItem, showTextbox, setShowTexbox } = React.useContext(SignatureContext);
  const onClose = () => setShowTexbox(false);

  return (
    <Modal
      transparent
      visible={showTextbox}
      animationType={"none"}
      onRequestClose={onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <View style={styles.textBoxModalBlurBG}>
        <View style={styles.textBoxContainer}>
          <View style={styles.textBoxHeader}>
            <Text style={styles.textBoxTextHeader}>Text Input</Text>
            <ButtonIcon
              onPress={onClose}
              source={images.icon_close}
              style={styles.i_close}
            />
          </View>
          <TextboxSection selectedItem={selectedItem} onClose={onClose} />
        </View>
      </View>
    </Modal>
  );
};

export default TextBoxModal;