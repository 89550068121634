import colors from "@assets/colors";
import Icon from "@expo/vector-icons/MaterialIcons";
import Button from "@src/components/button/Button01";
import { TextInput02 } from "@src/components/textinput";
import { useSignsecureService } from "@src/ducks/hooks";
import React from "react";
import { Modal, Pressable, Text, View } from "react-native";
import { SignatureContext } from "../provider/signature";
import useStyles from "./styles.css";

const DeclineToSign = () => {
  const styles = useStyles();
  const [message, setMessage] = React.useState("");
  const { declineDocumentRequest, rejectDocumentRequest } = useSignsecureService();
  const { document, showdecline, showreject, setRejectModal, setDeclineModal } = React.useContext(SignatureContext);

  const onProceed = () => {
    onClose();
    if(showreject){
      rejectDocumentRequest({
        id: document.id,
        reason: message
      });
    }else{
      declineDocumentRequest({
        id: document.id,
        reason: message
      });
    }
  };

  const onClose = () => {
    setDeclineModal(false);
    setRejectModal(false);
  };

  return (
    <Modal
      transparent
      visible={showdecline || showreject}
      animationType={"none"}
      onRequestClose={onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <View style={styles.container}>
        <View style={styles.v_modal_decline} >
          <View style={[styles.v_row_label]}>
            <Text style={styles.t_declinetosign}>{showreject ? "Reject Document" : "Decline to sign?"}</Text>
            <Pressable onPress={onClose}>
              <Icon name="close" size={25} color={colors.black2} />
            </Pressable>
          </View>
          <View>
            <TextInput02 
              multiline
              value={message}
              label={showreject ? 
                "Reason for rejecting document (optional)" : 
                "Please tell us why you’re not signing the document"}
              onChangeText={setMessage}
              labelStyle={styles.ti_message}
              borderStyle={styles.ti_borderStyle} />
          </View>
          <View style={styles.v_row_buttons}>
            <Button
              label="Submit"
              disabled={showreject ? false : !message}
              onPress={onProceed}
              style={[styles.btn_proceed, styles.decline_btn_width]}
              labelStyle={styles.label_proceed}
            />
            <View style={styles.divider} />
            <Button
              label="Cancel"
              onPress={onClose}
              style={[styles.btn_cancel, styles.decline_btn_width]}
              labelStyle={styles.label_cancel}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default DeclineToSign;
