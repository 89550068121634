import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useNavigation } from "@react-navigation/native";
import useStyles from "./styles.css";
import { NavigationScreenProps } from "@src/navigators/NavigatorTypes";

interface TermsProps {
  isCheck: boolean;
  onCheck: () => void;
}

const Terms = ({ isCheck, onCheck }: TermsProps) => {
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<"terms-condition" | "privacy-policy">>();

  return (
    <View style={styles.v_agree_container}>
      <TouchableOpacity
        activeOpacity={0.9}
        style={styles.v_wrap_agree}
        onPress={onCheck} >
        <View style={[styles.v_agree_box, isCheck && styles.v_agree_box_active]} >
          <Icon name="check" size={14} color="white" />
        </View>
      </TouchableOpacity>
      <Text style={styles.t_remember_me}>
        I agree to the
        <Text style={styles.t_terms} onPress={() => navigation.navigate("terms-condition")}> Terms of Use </Text>and have read and
        consent to processing of personal data in accordance with the
        <Text style={styles.t_terms} onPress={() => navigation.navigate("privacy-policy")}> Privacy Policy</Text> of SignSecure.
      </Text>
    </View>
  );
};

export default Terms;
