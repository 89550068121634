import React from "react";
import { View, Text, ScrollView, Pressable, Modal, useWindowDimensions } from "react-native";
import { useDocumentDetails } from "@src/utils/screensize-helper";
import { SignatureContext } from "../provider/signature";
import Icon from "@expo/vector-icons/MaterialIcons";
import useStyles from "./styles.css";
import Parties from "./parties";
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

const AuditTrailMobile = () => {
  const styles = useStyles();
  const size = useDocumentDetails();
  const animated = useSharedValue(size);
  const { height } = useWindowDimensions();
  const { showAuditTrail, setShowAuditTrail } = React.useContext(SignatureContext);
  const onClose = () => {
    animated.value = withTiming(size, { duration: 1 });
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      setShowAuditTrail(false);
    }, 0);
  };

  const animateStyle = useAnimatedStyle(() => {
    const translateX = animated.value;

    return {
      transform: [{ translateX }],
      height,
    };
  }, []);

  React.useEffect(() => {
    if (showAuditTrail) {
      animated.value = withTiming(0, { duration: 0 });
    }
  }, [showAuditTrail]);

  if (!showAuditTrail) {
    return null;
  }

  return (
    <Modal
      transparent
      visible={showAuditTrail}
      animationType={"none"}
      onRequestClose={onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <View style={styles.container}>
        <AnimatedScrollView style={[styles.formContainer]}>
          <Animated.View>
            <View style={{flexDirection:"row"}}>
              <Text style={styles.h4Bold}>Audit Trail</Text>
              <Pressable onPress={onClose} style={styles.btn_close} >
                <Icon name="close" size={20} color={"black"} />
              </Pressable>
            </View>
            <Text style={styles.body2}>View a history of the documents changes here.</Text>

            <View style={styles.v_height60} />
            <Parties />
            <View style={styles.v_height60} />
          </Animated.View>
        </AnimatedScrollView>
      </View>
    </Modal>
  );
};

export default React.memo(AuditTrailMobile);
