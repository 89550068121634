import React from "react";
import { Text } from "react-konva";
import colors from "@assets/colors";

type TextProps = {
  field: any;
};

const Designation = (props: TextProps) => {
  const { field } = props;

  return (
    <React.Fragment>
      <Text
        x={field.config.x}
        y={field.config.y}
        align="center"
        fill={colors.black}
        fontFamily="DMSans"
        verticalAlign="bottom"
        rotation={field.config.rotation}
        text={field.designationConfig.text}
        fontSize={field.designationConfig.fontSize}
      />
    </React.Fragment>
  );
};

export default React.memo(Designation);
