import React, { createContext } from "react";
import {
  PageNavigationPlugin,
  pageNavigationPlugin,
} from "@react-pdf-viewer/page-navigation";
import { zoomPlugin, ZoomPlugin } from "@react-pdf-viewer/zoom";
import { printPlugin, PrintPlugin } from "@react-pdf-viewer/print";
import { thumbnailPlugin, ThumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import * as Types from "./types";

interface PluginContextValue {
  pageNavigationPluginInstance: PageNavigationPlugin;
  zoomPluginInstance: ZoomPlugin;
  printPluginInstance: PrintPlugin;
  thumbnailPluginInstance: ThumbnailPlugin;
}

const PluginContext = createContext<PluginContextValue>({
  pageNavigationPluginInstance: {} as PageNavigationPlugin,
  zoomPluginInstance: {} as ZoomPlugin,
  printPluginInstance: {} as PrintPlugin,
  thumbnailPluginInstance: {} as ThumbnailPlugin,
});

const PluginProvider = ({ children }: Types.DocumentProviderProps) => {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const printPluginInstance = printPlugin();
  const zoomPluginInstance = zoomPlugin();
  const thumbnailPluginInstance = thumbnailPlugin({thumbnailWidth: 80});

  const value = {
    pageNavigationPluginInstance,
    printPluginInstance,
    zoomPluginInstance,
    thumbnailPluginInstance,
  };

  return (
    <PluginContext.Provider value={value}>{children}</PluginContext.Provider>
  );
};

export { PluginProvider, PluginContext };
