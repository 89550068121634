import AffixIcon from "@assets/svg/affix-signature";
import Button from "@src/components/button/Button01";
import { useSignsecureService } from "@src/ducks/hooks";
import React from "react";
import { Modal, Text, View } from "react-native";
import { SignatureContext } from "../provider/signature";
import useStyles from "./styles.css";

const CancelDocument = () => {
  const styles = useStyles();
  const { cancelDocumentRequest } = useSignsecureService();
  const { document, showcancel, setCancelModal } = React.useContext(SignatureContext);

  const onProceed = () => {
    setCancelModal(false);
    cancelDocumentRequest({
      id: document.id,
      reason: "Cancel this document!"
    });
  };

  return (
    <Modal
      transparent
      visible={showcancel}
      animationType={"none"}
      onRequestClose={() => setCancelModal(false)}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <View style={styles.container}>
        <View style={[ styles.v_modal ]} >
          <AffixIcon name="docu-cancelled" />
          <Text style={styles.t_message}>Are you sure you want to cancel this document?</Text>
          <View style={{flexDirection: "row", alignItems: "center"}}>
            <Button
              label="Cancel"
              style={styles.btn_cancel}
              labelStyle={styles.label_cancel}
              onPress={() => setCancelModal(false)}
            />
            <View style={styles.divider} />
            <Button
              label="Yes"
              onPress={onProceed}
              style={styles.btn_proceed}
              labelStyle={styles.label_proceed}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default CancelDocument;
