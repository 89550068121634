// import { useForgotService } from "@src/ducks/hooks";
import React from "react";
// import PasswordChanged from "./password-changed";
import ResetPassword from "./signin-otp";

const Screens = () => {
  return <ResetPassword />;
};

export default Screens;
