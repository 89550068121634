import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    v_body: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    scrollview: { width: "100%" },
    v_signup: {
      width: "50%",
      height: "100%",
      alignSelf: "center",
      justifyContent: "center",
      paddingHorizontal: "8%",
      backgroundColor: colors.white,
    },
    v_signup_landscape: {
      width: "50%",
      justifyContent: "center",
      paddingHorizontal: "9%",
      backgroundColor: colors.white,
    },
    signInCenter: { marginTop: 30 },
    txtNote1: {
      fontFamily: "DMSans-Bold",
      fontSize: 45,
      lineHeight: 52,
      textAlign: "center",
      color: colors.blue,
    },
    txtTM: { fontFamily: "DMSans", fontWeight: "300" },
    txtNote2: {
      fontFamily: "DMSans-Medium",
      fontSize: 20,
      lineHeight: 30,
      textAlign: "center",
      color: colors.black12,
      marginTop: 21
    },
    txtNote3: {
      fontFamily: "DMSans",
      fontSize: 16,
      lineHeight: 25,
      textAlign: "center",
      paddingHorizontal: 11,
      color: colors.black12,
    },
    v_field: { marginTop: 0 },
    v_wrap_row: { flexDirection: "row" },
    v_flex1: { flex: 1 },
    v_divider: { width: 25 },
    t_signup: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginTop: 35,
        textAlign: "center",
        alignSelf: "center",
        color: colors.black3,
        paddingBottom: 35
      },
    ]),

    v_agree_container: {
      marginTop: 50,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    v_wrap_agree: {
      alignItems: "center",
      justifyContent: "center",
    },
    v_agree_box: {
      width: 22,
      height: 22,
      borderWidth: 1,
      borderRadius: 4,
      alignItems: "center",
      justifyContent: "center",
      borderColor: colors.border2,
    },
    v_agree_box_active: {
      borderColor: colors.black1,
      backgroundColor: colors.black1,
    },
    t_remember_me: {
      fontFamily: "DMSans",
      fontSize: 14,
      lineHeight: 18,
      color: colors.black3,
      marginLeft: 10,
    },
    t_terms: {
      color: colors.blue,
      fontWeight: "700",
    },
    t_error_message: {
      marginTop: 12,
      fontFamily: "DMSans",
      fontSize: 13,
      color: colors.red,
      textAlign: "center",
    },
    btn_signup: { marginTop: 30, backgroundColor: colors.blue },
    lbl_signup: { fontFamily: "Montserrat-Bold" },
    v_height: { height: 50 },
    v_height_top: { height: 50 },
    btn_google: {
      marginTop: 25,
      backgroundColor: colors.white,
      borderWidth: 1,
      justifyContent: "center",
      alignItems: "center",
      shadowColor: "gray",
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.6,
      shadowRadius: 10,
      borderColor: colors.border2,
    },

    // Email Sent
    t_email_sent: TypographyStyle.heading7Bold,
    t_email_sent_notes: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { marginTop: 45, color: "#4B4B4B" },
    ]),
    t_email_sent_notes2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      { marginTop: 34, textAlign: "center", color: "#4B4B4B" },
    ]),
    btn_backtologin: {
      height: 60,
      marginTop: 45,
      marginHorizontal: 30,
      backgroundColor: colors.blue,
    },

    termsStyle: { bottom: 0, position: "relative", fontSize: 14, lineHeight: 21 },
    v_privacy: { display: "none", marginTop: 52 },

    // LOGO COMPONENT
    t_notes: { 
      right: 63,
      textAlign: "right",
      position: "absolute", 
      fontFamily: "DMSans-Bold", 
      fontSize: 50, 
      lineHeight: 55, 
      color: colors.white,
    },
    v_logo_container: {
      width: "50%",
      alignItems: "center",
      justifyContent: "center",
    },
    v_image_cover: {width: "100%", height: "90%", alignItems: "flex-end", justifyContent: "center"},
    v_logo: {position: "absolute", zIndex: 2, top: 65, right: 63},
    i_logo_background: {
      width: "100%",
      height: "100%",
      resizeMode: "stretch",
      backgroundColor: colors.black12
    },
    i_logo: { width: 620, height: 161 },
    t_logo: {
      fontFamily: "DMSans-Bold", 
      marginTop: 45,
      fontSize: 32, 
      lineHeight: 21, 
      color: colors.white, 
    },
    v_logo_terms: {
      height: "10%", width: "100%", alignItems: "center", 
      justifyContent: "center", backgroundColor: colors.black12},
    txtNote5: {
      fontFamily: "DMSans",
      fontWeight: "400",
      fontSize: 12,
      color: colors.black2,
    },
    itemSeparator: {
      height: 1,
      marginHorizontal: 10,
      backgroundColor: colors.gray4,
      marginTop: 10,
      width: 190
    },
    recaptcha: {
      marginTop: 25,
    }
  },
  {
    [DEVICE_SIZES.XL]: {
      v_signup: { paddingHorizontal: "12%" },
      v_height_top: {height: 90},
    },
    [DEVICE_SIZES.LG]: {
      v_flex1: { marginTop: 17, },
      v_height_top: {height: 101.25},
      txtNote1: { fontSize: 37, lineHeight: 40 },
      txtNote2: { fontSize: 15, lineHeight: 18, marginTop: 16 },
      txtNote3: { fontSize: 12, lineHeight: 18 },
      t_email_sent: { fontSize: 30, lineHeight: 39 },
      t_email_sent_notes: { fontSize: 18, lineHeight: 21, marginTop: 38 },
      t_email_sent_notes2: { fontSize: 10.5, lineHeight: 10.5, marginTop: 24 },
      btn_backtologin: { height: 45, borderRadius: 6 },
      lbl_signup: { fontSize: 10.5, lineHeight: 12 },
      t_signup: { fontSize: 10.5, lineHeight: 10.75 },
      v_agree_box: { width: 16.39, height: 16.39, borderRadius: 3 },
      t_remember_me: { fontSize: 10.5, lineHeight: 10.75 },
      btn_signup: { marginTop: 20 },
      v_logo: {position: "absolute", zIndex: 2, top: 58, right: 60},
      v_image_cover: {width: "100%", height: "91%" },
      v_logo_terms: {height: "9%" },
      t_notes: { 
        right: 60,
        fontSize: 45, 
        lineHeight: 40,
      },
      termsStyle: { bottom: 0, fontSize: 10, lineHeight: 16 },
      itemSeparator: {
        width: "30%"
      },
    },
    [DEVICE_SIZES.MD]: {
      v_flex1: { marginTop: 20 },
      v_body: {
        flexDirection: "column",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
      },
      v_signup: {
        flex: 1,
        minWidth: "100%",
        maxWidth: "100%",
        paddingTop: 100,
        alignSelf: "center",
        paddingVertical: 0,
        paddingHorizontal: "20%",
        justifyContent: "flex-start",
      },
      v_height_top: { height: 0 },
      v_logo_container: {
        width: "100%",
        height: 350,
        maxHeight: 350,
        overflow: "hidden"
      },
      i_logo_background: {
        position: "absolute",
        width: "100%",
        height: "200%",
        top: "-40%",
        resizeMode: "stretch",
      },
      t_notes: { 
        marginTop: 40,
        right: 53,
        fontSize: 42, 
        lineHeight: 40,
      },
      txtNote1: {
        fontSize: 27,
        lineHeight: 31,
      },
      txtNote2: {
        fontSize: 20,
        lineHeight: 30,
      },
      txtNote3: {
        fontSize: 16,
        lineHeight: 25,
      },
      v_image_cover: {width: "100%", height: "100%"},
      v_logo: {top: 50, right: 56},
      v_logo_terms: {display: "none"},
      v_privacy: { display: "flex", marginTop: 150},
      itemSeparator: {
        width: "30%"
      },
      v_signup_landscape: {
        width: '100%',
      }
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_body: {
        flexDirection: "column",
        width: "100%",
        height: "100%",
      },
      v_logo_container: {
        width: "100%",
        height: 400,
        maxHeight: 400,
      },
      v_height_top: { height: 0 },
      v_signup: {
        flex: 1,
        paddingBottom: 50,
        borderRadius: 0,
        minWidth: "100%",
        maxWidth: "100%",
        paddingVertical: 0,
        paddingHorizontal: 20,
        justifyContent: "flex-start",
      },
      txtNote1: {
        fontSize: 27,
        lineHeight: 31,
      },
      txtNote2: {
        textAlign: "center",
        marginTop: 15,
        fontSize: 18,
        lineHeight: 23,
      },
      txtNote3: {
        fontSize: 14,
        lineHeight: 20,
      },
      v_image_cover: {width: "100%", height: "100%"},
      v_logo: {top: 50, right: 15},
      v_logo_terms: {display: "none"},
      t_notes: { 
        marginTop: 30,
        right: 21,
        fontSize: 30, 
        lineHeight: 26,
      },
      t_email_sent: {
        fontSize: 22,
        lineHeight: 25,
      },
      t_email_sent_notes: {
        fontSize: 16,
        lineHeight: 20,
      },
      t_email_sent_notes2: {
        fontSize: 12,
        lineHeight: 20,
      },
      v_wrap_row: {
        flexDirection: "column",
      },
      v_privacy: { display: "flex", marginTop: 150},
      v_agree_container: {
        marginTop: 50,
        alignItems: "flex-start",
      },
      btn_signup: { marginTop: 32 },
      itemSeparator: {
        width: "30%"
      },
      v_signup_landscape: {
        width: '100%',
      }
    },
    [DEVICE_SIZES.XS]: {
      itemSeparator: {
        width: "30%"
      },
      txtNote5: {
        fontFamily: "DMSans",
        fontWeight: "400",
        fontSize: 14,
        color: colors.black2,
      },
      v_signup_landscape: {
        width: '100%',
      }
    }
  }
);

export default useStyles;
