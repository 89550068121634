import React, { useState, useEffect, useContext } from "react";
import { Text, View, ScrollView } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import ReCAPTCHA from "react-google-recaptcha";
import { Button01 } from "@src/components/button";
import { TextInput02 } from "@src/components/textinput";
import { useSignupService } from "@src/ducks/hooks";
import { SignupInput } from "@src/ducks/types";
import { useInputHelper } from "@src/utils/inputs-helper";
import useOrientationHelper from "@src/utils/orientation-helper";
import _ from "lodash";
import Validator from "validator";
import GoogleButton from "./google-button";
import { SignupContext } from "./SignupProvider";
import useStyles from "./styles.css";
import Terms from "./terms";
import colors from "@assets/colors";
import { RECAPTCHA_SITE_KEY } from "@env";
import { MicrosoftSignInButton } from "./microsoft-button";

const initialInputState = {
  givenName: "",
  lastName: "",
  email: "",
  password: "",
  referralCode: "",
  "user-id": "",
} as SignupInput;

const SignUpFields = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const userId = route?.params?.id;
  const email = route?.params?.email;
  const navigation = useNavigation<any>();
  const isLandScape = useOrientationHelper();
  const [terms, setTerms] = useState<boolean>(false);
  const [captchaVerified, setCaptchaVerified] = useState<boolean>(false); // Captcha state
  const { updateScreen } = useContext(SignupContext);
  const { data, failed, isLoading, onSignup } = useSignupService();
  const [error, setError] = useState<SignupInput>({} as SignupInput);
  const { state, onDispatch } = useInputHelper({
    ...initialInputState,
    ["user-id"]: userId,
    email,
  });

  const onChangeText = (type: string) => (value: string) => {
    onDispatch(type)(value);
    setError({} as SignupInput);
  };

  const handleCaptchaChange = (token: string | null) => {
    if (token) {
      setCaptchaVerified(true); // Set captcha as verified
    } else {
      setCaptchaVerified(false);
    }
  };

  const handleSubmit = () => {
    const newErr: Partial<SignupInput & { captcha?: string }> = {};
  
    const requiredFields = ["email", "givenName", "lastName", "password"];
    requiredFields.forEach((field) => {
      if (_.isEmpty(state[field])) {
        newErr[field] = "This field is required";
      }
    });
  
    if (state.email && !Validator.isEmail(state.email)) {
      newErr.email = "Invalid email format";
    }
  
    if (state.password.length <= 5) {
      newErr.password = "Password must be at least six characters";
    }
  
    if (!captchaVerified) {
      newErr.captcha = "Please complete the captcha verification.";
    }
  
    setError(newErr);
    if (_.isEmpty(newErr)) {
      onSignup(state);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(failed)) {
      setError({ message: failed.message } as SignupInput);
    }
  }, [failed]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      updateScreen("emailsent");
    }
  }, [data]);

  return (
    <View style={isLandScape ? styles.v_signup_landscape : styles.v_signup}>
      <ScrollView showsVerticalScrollIndicator={false} style={styles.scrollview}>
        <View style={styles.v_height_top} />
        <View style={styles.signInCenter}>
          <Text style={styles.txtNote1}>Create a free account</Text>
          <Text style={styles.txtNote2}>No credit card required!</Text>
        </View>

        <GoogleButton />
        <MicrosoftSignInButton />
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            marginTop: 25,
            justifyContent: "center",
          }}
        >
          <View style={styles.itemSeparator} />
          <Text style={styles.txtNote5}>or Sign Up With Email</Text>
          <View style={styles.itemSeparator} />
        </View>

        <View style={styles.v_field}>
          <TextInput02
            label="Email"
            value={state.email}
            error={error.email}
            style={styles.v_flex1}
            placeholder="Enter your email"
            editable={userId ? false : true}
            onChangeText={onChangeText("email")}
          />
          <View style={styles.v_wrap_row}>
            <TextInput02
              label="First Name"
              value={state.givenName}
              error={error.givenName}
              style={styles.v_flex1}
              placeholder="First Name"
              onChangeText={onChangeText("givenName")}
            />
            <View style={styles.v_divider} />
            <TextInput02
              label="Last Name"
              style={styles.v_flex1}
              value={state.lastName}
              error={error.lastName}
              placeholder="Last Name"
              onChangeText={onChangeText("lastName")}
            />
          </View>
          <TextInput02
            hasPassword
            label="Password"
            style={styles.v_flex1}
            value={state.password}
            error={error.password}
            placeholder="Enter your password here"
            onChangeText={onChangeText("password")}
          />

          <View style={styles.recaptcha}>
            <ReCAPTCHA
              // size="invisible"
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleCaptchaChange}
            />
            {error.captcha && (
              <Text style={styles.t_error_message}>{error.captcha}</Text>
            )}
          </View>

          <Terms isCheck={terms} onCheck={() => setTerms(!terms)} />
          <Button01
            label="Sign Up for FREE"
            disabled={!terms || !captchaVerified}
            color={colors.white}
            isLoading={isLoading}
            onPress={handleSubmit}
            style={styles.btn_signup}
            labelStyle={styles.lbl_signup}
          />
          {!_.isEmpty(error.message) && (
            <Text style={styles.t_error_message}>{error.message}</Text>
          )}
          <Text style={styles.t_signup}>
            Already have an account?
            <Text
              onPress={() => navigation.navigate("Signin")}
              style={[styles.t_terms, { marginLeft: 10 }]}
            >
              {" "}
              Sign In
            </Text>
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};

export default SignUpFields;
