import React from "react";
import useStyles from "./styles.css";
import { Text } from "react-native";

const DefaultFooter = () => {
  const styles = useStyles();

  return (
    <Text style={[styles.fieldText]}>
      We will send an OTP (One-Time Password) to your email address to validate
      your uploaded signature
    </Text>
  );
};

export default DefaultFooter;
