import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    signModalBlurBG: {
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.blurBG,
    },
    signContainer: {
      padding: 40,
      width: "46%",
      borderRadius: 20,
      backgroundColor: colors.white,
    },
    signHeader: {
      width: "100%",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 31,
    },
    i_close: { height: 24, width: 24 },
    signTextHeader: {
      color: colors.black2,
      fontFamily: "Montserrat",
      fontWeight: "700",
      fontSize: 24,
      lineHeight: 29,
    },
    signTabsContainer: {
      width: "100%",
      height: 50,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: 7,
      paddingVertical: 2,
      borderRadius: 8,
      backgroundColor: "#ECF3FF",
    },
    signTabBG: {
      width: "22%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 6,
    },
    signTabText: {
      color: colors.black2,
      fontFamily: "Lato",
      fontWeight: "400",
      fontSize: 13,
      lineHeight: 16,
    },
    signTabImg: { width: 18, height: 18 },

    //Tab Guest
    signTabsContainerGuest: {
      width: "100%",
      height: 50,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 7,
      paddingVertical: 2,
      borderRadius: 8,
      backgroundColor: "#ECF3FF",
    },
    signTabBGGuest: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 6,
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {
      signContainer: {
        width: "76%",
        borderRadius: 15,
        paddingHorizontal: 20,
      },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      signModalBlurBG: {
        backgroundColor: "transparent",
      },
      signContainer: {
        flex: 1,
        width: "100%",
        marginTop: 60,
        borderRadius: 0,
        paddingHorizontal: 20,
      },
    },
  }
);

export default useStyles;
