import SVGIcon from "@assets/svg";
import useSVGSizeHelper from "@src/utils/svg-size-helper";
import React from "react";
import { Text, View } from "react-native";
import useStyles from "./styles.css";
import UnawaProduct from "./unawaProduct";
import { useRoute } from "@react-navigation/native";

type LogoProps = {
  logoColor?: string;
  termsColor?: string;
  termsStyle?: any;
  title?: string;
  subtitle?: string;
  subtitlePlan?: string;
}

const Logo = (props: LogoProps) => {
  const styles = useStyles();
  const size = useSVGSizeHelper({
    TABLET: { width: 242, height: 40},
    LARGE: { width: 250, height: 47},
    DEFAULT: { width: 262, height: 49},
  });
  const route = useRoute<any>();
  const code = route?.params?.plan;


  const textTitle = () => {
    if(code === "pro" || code === "pro-12"){
      return "Professional"
    } else if(code === "biz" || code === "biz-12"){
      return "Business"
    }
  }

  return (
    <View style={styles.v_logo_container}>
      <View style={styles.logo_PlanContainer}>
        <SVGIcon name="signsecure-no-v2" {...size} fill={props.logoColor}  />
        <Text style={styles.textColorPlan}>
          {textTitle()}
        </Text>
      </View>
      {props.title && <Text style={[styles.t_logo, { color: props.logoColor}]}>{props.title}</Text>}
      {props.subtitle && <Text style={[styles.t_experience, { color: props.logoColor}]}>{props.subtitle}</Text>}
      {props.subtitlePlan && <Text style={[styles.t_experience_plan, { color: props.logoColor}]}>{props.subtitlePlan} <br/>Use code <b>SSV2FREE30</b> to get 1 month <br/>for FREE!</Text>}
      <UnawaProduct />
    </View>
  );
};

export default Logo;
