import React from "react";
import { View, Text, Pressable, Image } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import colors from "@assets/colors";

const Tabs = (props: any) => {
  const styles = useStyles();
  const tabs = ["Draw", "Upload", "Choose", "Type"];

  return (
    <View style={styles.signTabsContainer}>
      {tabs.map((tab, index) => {
        const isActive = tab === props.activeTab;
        const backgroundColor = isActive && {
          backgroundColor: colors.white,
        };
        const tintColor = isActive && {
          tintColor: colors.purple,
        };
        const textColor = isActive && {
          color: colors.purple,
        };

        const icon =
          tab === "Draw"
            ? images.icon_pen
            : tab === "Upload"
              ? images.icon_upload
              : tab === "Choose"
                ? images.icon_gallery
                : images.icon_text;

        return (
          <Pressable
            key={`signindx ${index}`}
            onPress={() => props.setActiveTab(tab)}
            style={[styles.signTabBG, backgroundColor]}
          >
            <Text style={[styles.signTabText, textColor]}>{tab}</Text>
            <Image
              source={icon}
              style={[styles.signTabImg, tintColor]}
              resizeMode="contain"
            />
          </Pressable>
        );
      })}
    </View>
  );
};

export default Tabs;
