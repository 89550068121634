import React from "react";
import {TextInput, View, Pressable } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { Group, Rect } from "react-konva";
import { Html } from "react-konva-utils";
import colors from "@assets/colors";

type QRCodeSignProps = {
  field: any;
  onCheck: () => void;
};

const Checkbox = (props: QRCodeSignProps) => {
  const { field } = props;

  return (
    <React.Fragment>
      <Group
        x={field.config.x}
        y={field.config.y}
        width={field.config.width}
        height={field.config.height}
        rotation={field.config.rotation}
      >
        <Rect width={field.config.width} height={field.config.height} />
        <Html
          divProps={{
            style: {
              width: field.config.width,
              height: field.config.height,
              backgroundColor: colors.transparent,
            },
          }}
        >
          <View style={{flexDirection: "row" }}>
            <Pressable onPress={props.onCheck} style={{
              borderWidth: 1,
              alignItems: "center",
              justifyContent: "center",
              borderColor: colors.black3,
              width: field.checkConfig.width,
              height: field.checkConfig.height,
              marginRight: field.checkConfig.marginRight,
            }}>
              {field.isChecked && <Icon name="check" 
                size={field.checkConfig.width * 0.8} color={colors.black3} />}
            </Pressable>
            <View style={{
              width: field.inputConfig.width,
              height: field.inputConfig.height,
              pointerEvents: "none",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <TextInput
                editable={false}
                style={{
                  fontFamily: "DMSans",
                  paddingLeft: 2,
                  width: field.inputConfig.width,
                  height: field.inputConfig.height,
                  fontSize: field.inputConfig.fontSize
                }} 
                value={field.inputConfig.text} />
            </View>
          </View>
        </Html>
      </Group>
    </React.Fragment>
  );
};

export default React.memo(Checkbox);
