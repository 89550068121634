import AffixIcon from "@assets/svg/affix-signature";
import Button from "@src/components/button/Button01";
import CopyRightContent from "@src/screens/create-document/document-sent/copy-right";
import MobileHeader from "@src/screens/drawer-screens/drawer-header/mobile";
import WebHeader from "@src/screens/drawer-screens/drawer-header/web-view";
import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import { mobileSize } from "@src/utils/screensize-helper";
import React from "react";
import { Text, View } from "react-native";
import useStyles from "./styles.css";

const DocumentDeclined = () => {
  const styles = useStyles();
  const { onActiveTab } = React.useContext(DrawerContext);
  const isMobileSize = mobileSize();

  return (
    <>
      <View style={styles.drawer_item_header}>
        {isMobileSize ? <MobileHeader /> : <WebHeader/>}
      </View>
      <View style={styles.container}>
        <AffixIcon name="docu-declined" />
        <Text style={[styles.t_title]}>You declined to sign this document</Text>
        <Text style={[styles.t_notes]}>Sender will be notified that you declined to sign this document.</Text>
        <Button
          onPress={() => onActiveTab("Dashboard")}
          label="Go to Dashboard"
          style={styles.btn_proceed}
          labelStyle={styles.labelStyle}
        />
      </View>
      <View >
        <CopyRightContent />
      </View>
    </>
  );
};

export default DocumentDeclined;
