import React from "react";
import { useNavigation } from "@react-navigation/native";
import { useAuthService } from "@src/ducks/hooks";
import { Button01 } from "@src/components/button";
import { Text, View, TouchableOpacity } from "react-native";
import useStyles from "./styles.css";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAuthEmail, selectedAuthUserID } from "@src/ducks/slices/auth.slice";
import DrawerIcon from "@assets/svg/drawer";

const SignUpNow = () => {
  const styles = useStyles();
  const { onLogout } = useAuthService();
  const navigation = useNavigation<any>();
  const id = useAppSelector(selectedAuthUserID);
  const email = useAppSelector(selectedAuthEmail);

  const onPress = () => {
    const params = { id, email };
    onLogout();
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      navigation.navigate("Signup", params );
    }, 100);
  };

  return (
    <>
    <View style={[styles.signupnow_container]}>
      <Text style={styles.t_welcome}>Ready to experience the full potential of UNAWA SignSecure?</Text>
      <Text style={styles.t_welcome_description}>Join us today and elevate your corporate experience with our upgraded SignSecure plan.</Text>
      <Button01 label="SIGN UP NOW" 
        onPress={onPress}
        style={styles.btn_signupnow} labelStyle={styles.label_signupnow} />
    </View>
    <View style={styles.alignmentLogout}>
        <TouchableOpacity
        onPress={onLogout}
        activeOpacity={0.9}
        style={styles.LogOutButton}>
          <DrawerIcon name="Logout"/> 
          <Text style={styles.logOut_Guest}>Logout</Text>
        </TouchableOpacity>
    </View>
    </>
  );
};

export default SignUpNow;
