import React from "react";
import { Button01 } from "@src/components/button";
import { View, Text, Image } from "react-native";
import Icon from "@expo/vector-icons/Ionicons";
import useStyles from "./styles.css";
import colors from "@assets/colors";

const DropView = (props: any) => {
  const styles = useStyles();

  if (props.source) {
    return (
      <Image
        style={styles.imageStyle}
        source={{ uri: props.source }}
        resizeMode="contain"
      />
    );
  }

  return (
    <View style={[styles.BGLightGray]}>
      <Icon
        name="cloud-upload"
        size={56}
        color={colors.grayPrimary}
        style={styles.iconUpload}
      />
      <Text style={styles.body2}>Drag and drop to upload</Text>
      <View style={styles.v}>
        <Text style={styles.bodyS}>Or select from your computer</Text>
        <Button01
          label="Browse"
          style={styles.btn}
          labelStyle={{ color: colors.white }}
        />
      </View>
    </View>
  );
};

export default DropView;
