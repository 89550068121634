import React from "react";
import { StyleSheet, Pressable } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { DrawerComponentContext } from "./provider";
import colors from "@assets/colors";
import Animated, {
  useAnimatedStyle,
  interpolate,
  Extrapolation,
} from "react-native-reanimated";


function FloatingButton() {
  const { animated, onMinimizeState } = React.useContext(DrawerComponentContext);

  const animateStyle = useAnimatedStyle(() => {
    const rotate = interpolate(
      animated.value,
      [360, 110],
      [0, 180],
      Extrapolation.CLAMP,
    );

    return {
      transform: [{ rotate: `${rotate}deg` }],
    };
  }, []);

  return (
    <Pressable onPress={onMinimizeState} style={styles.container}>
      <Animated.View style={[styles.v_center, animateStyle]} >
        <Icon name="chevron-left" size={24} color={colors.white} />
      </Animated.View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 44, height: 44, borderRadius: 44 / 2, 
    position: "absolute", bottom: "14%", right: -22, zIndex: 1,
    alignItems: "center", justifyContent: "center", backgroundColor: colors.white
  },
  v_center: {
    width: 28, height: 28, borderRadius: 28 / 2, 
    alignItems: "center", justifyContent: "center", backgroundColor: colors.colorPrimary
  },
});

export default FloatingButton;