import { tabletSize } from "@src/utils/screensize-helper";
import React, { useMemo } from "react";
import { View, Text } from "react-native";
import Background from "./background";
import Logo from "./logo";
import LogoTop from "./logo-top";
import SignUpProvider from "./provider";
import Screens from "./screens";
import useStyles from "./styles.css";
import { useRoute } from "@react-navigation/native";
import { useSignupContext } from "./provider";
const SignIn = (props: any) => {
  const styles = useStyles();
  const minTablet = tabletSize();
  const route = useRoute<any>();
  const code = route?.params?.plan;
  const { activeIndex } = useSignupContext();
  console.log(activeIndex)

  const titlePlan = () => {
    switch (activeIndex) {
      case 0:
        return "The easiest e-signature \nexperience is only a few \nclicks away!";
      case 1:
        return  "Is this the right plan \nfor you?";
      case 2:
        return  "Have a promo code? \nNow’s the time to use it!";
      default:
        return; 
    }
  };

  const titlePlanMobile = () => {
    switch (activeIndex) {
      case 0:
        return "The easiest e-signature experience \n is only a few clicks away!";
      case 1:
        return  "Is this the right plan for you?";
      case 2:
        return  "Have a promo code? \nNow’s the time to use it!";
      default:
        return; 
    }
  };

  const plan = () => {
    switch (code) {
      case 'pro':
        return "SignSecure Professional.";
      case 'pro-12':
        return "SignSecure Professional.";
      case 'biz':
        return "SignSecure Business.";
      case 'biz-12':
        return "SignSecure Business.";
      default:
        break; 
    }
  };

  return (
    // <SignUpProvider>
      <View style={styles.container}>
        <Background />
        <View style={styles.v_body}>
          {minTablet ? 
            <LogoTop 
              content={titlePlanMobile()}
              subtitlePlan ={"You are getting " + plan()}
              /> : 
            <Logo 
              title={titlePlan()}
              subtitlePlan ={"You are getting " + plan() }
              /> } 
          <Screens />
        </View>
      </View>
    // </SignUpProvider>
  );
};

export default SignIn;
