import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import { Button01 } from "@src/components/button";
import { TextInput02 } from "@src/components/textinput";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedCustomSignatureID } from "@src/ducks/slices/signsecure.slice";
import React, { useRef, useState } from "react";
import { FlatList, Pressable, Text, View } from "react-native";
import ViewShot from "react-native-view-shot";
import { SignatureContext } from "../../provider/signature";
import DataItem from "./data-item";
import useStyles from "./styles.css";
import {
  selectUsernoMiddleNameFullname
} from "@src/ducks/slices/user.slice";

const TypeSection = (props: any) => {
  const styles = useStyles();
  const viewShotRef = useRef<any>(null);
  const signatureId = useAppSelector(selectedCustomSignatureID);
  const { onSetSignature } = React.useContext(SignatureContext);
  const fullnameNoMiddle = useAppSelector(selectUsernoMiddleNameFullname);
  
  const [signatureText, setSignatureText] = useState(fullnameNoMiddle);
  const [selected, setSelected] = useState<{ font: string; index: number } | null>(null);

  const onSign = React.useCallback(async() => {
    try {
      if (selected) {
        const uri = await viewShotRef.current.capture();
        onSetSignature({
          signature: uri,
          signatureType: `Type`,
          signatureId: signatureId,
          signatureParams: {...selected, signatureText: signatureText}
        });
        props.onClose();
      }
    } catch (error) {
      console.error("Error capturing view:", error);
    }
  }, [props.onClose, onSetSignature, selected, signatureText]);
  
  const onSelect = React.useCallback((item: string, index: number) => {
    setSelected({
      font: item,
      index: index
    });
  }, [props.onClose]);

  return (
    <View style={styles.container}>
      <View style={styles.header_container}>
        <Text style={styles.t_header}>Type your signature below</Text>
        
        <Pressable onPress={() => setSelected(null)} style={styles.change_container}>
          <SVGIcon name="change" style={styles.t_change_icon} />
          <Text style={styles.t_change}>Change style</Text>
        </Pressable>
      </View>

      <TextInput02
        style={styles.t_search}
        value={signatureText}
        onChangeText={setSignatureText}
      />

      {signatureText && selected && (
        <ViewShot ref={viewShotRef} 
                  options={{ format: "png", quality: 0.9 }}
                  style={[styles.view_shot_container]}>
          <Text style={[styles.preview_text, { fontFamily: selected.font }]}>
            {signatureText}
          </Text>
        </ViewShot>
      )}

      {signatureText && !selected && (
        <FlatList
          data={props.data}
          style={styles.flatlist}
          keyExtractor={(item: any, index: number) => `r_index${index}`}
          ItemSeparatorComponent={() => <View style={styles.v_separator} />}
          renderItem={({item, index}) => (
            <DataItem
              key={`${index}`}
              item={item}
              index={index} 
              signatureText={signatureText}
              onPress={onSelect}
            />
          )}
        />
      )}

      <View style={styles.signBtnV}>
        <Button01
          label="Cancel"
          style={styles.btnReset}
          onPress={() => props.onClose()}
          labelStyle={{ color: colors.blue2 }}
        />
        <Button01 
          label="Sign"
          onPress={onSign}
          disabled={!selected}
          style={styles.btnSign} />
      </View>
    </View>
  );
};

export default TypeSection;
