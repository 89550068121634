import { Button01 } from "@src/components/button";
import { selectAuthLoginInput } from "@src/ducks/slices/auth.slice";
import { useFetchPost } from "@src/utils/api/hooks";
import useOrientationHelper from "@src/utils/orientation-helper";
import React from "react";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";
import SignupEmailSent from "../signup-emailsent";
import useStyles from "./styles.css";

const EmailSent = () => {
  const styles = useStyles();
  const isLandScape = useOrientationHelper();
  const { email } = useSelector(selectAuthLoginInput);
  const { loading, data, runRequest } = useFetchPost();

  const onResend = () => {
    runRequest("/auth/resend-verification", { email });
  };

  if(data){
    return <SignupEmailSent />;
  }

  return (
    <View style={isLandScape ? styles.v_signup_landscape : styles.v_signup }>
      <View style={styles.signInCenter}>
        <Text style={styles.t_email_sent}>Verify your UNAWA SignSecure™{"\n"}account</Text>
        <Text style={styles.t_email_sent_notes}>
        You need to verify your email to be able to proceed to Dashboard. Please click the button below to send the verification email.
        </Text>
      </View>
      <Button01
        onPress={onResend}
        label="Verify Email"
        isLoading={loading}
        style={styles.btn_backtologin}
        labelStyle={styles.lbl_signup}
      />
      <Text style={styles.t_email_sent_notes2}>
        Did not receive the email? Check your spam or junk folder
      </Text>
    </View>
  );
};

export default EmailSent;
