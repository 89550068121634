import React  from "react";
import { SignatureContext } from "../provider/signature";
import { Text, View } from "react-native";
import useStyles from "./styles.css";
import { getAuthorFromAuditTrail } from "@src/utils/filter-helper";

const Details = () => {
  const styles = useStyles();
  const { document } = React.useContext(SignatureContext);
  const author = React.useMemo(() => getAuthorFromAuditTrail(document),[document]);

  return (
    <View>
      <Text style={[styles.body2]}>
        <Text style={styles.body2_bold}>Author: </Text> 
        {author?.author?.name} ({author?.author?.email})
      </Text>
      <Text style={[styles.body2]}>
        <Text style={styles.body2_bold}>Document Title:{" "}</Text> 
        {document.name}
      </Text>
      <Text style={[styles.body2]}>
        <Text style={styles.body2_bold}>Document Description:{" "}</Text>
        {document.description}
      </Text>
      <Text style={[styles.body2]}>
        <Text style={styles.body2_bold}>Document Privacy:{" "}</Text>
        {document.privacyType}
      </Text>
    </View>
  );
};

export default React.memo(Details);
