import React, { memo, useState } from "react";
import { Image, View, Text, Pressable, ActivityIndicator } from "react-native";
import { selectedAuthAccessToken } from "@src/ducks/slices/auth.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import { downloadSignature } from "@src/utils/api";
import useStyles from "./styles.css";
import colors from "@assets/colors";

type DataItemProps = {
  item: any;
  onPress: (uri: any) => void;
};

const DataItem = (props: DataItemProps) => {
  const { item, onPress } = props;
  const styles = useStyles();
  const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const accessToken = useAppSelector(selectedAuthAccessToken);

  const onSelect = async() => {
    try{
      setLoading(true);
      const base64 = await downloadSignature(item.id, accessToken);
      onPress({...item, uri: base64});
      setLoading(false);
    }catch(e){
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <View style={styles.r_style}>
      <Image
        source={{uri: item.path}}
        style={styles.image_preview}
        resizeMode="contain"
      />
      <Pressable
        onPress={onSelect}
        onHoverIn={() => setFocus(true)}
        onHoverOut={() => setFocus(false)}
        style={[styles.btn_select, focus && styles.btn_select_active]}>
        {loading ? <ActivityIndicator animating size={20} color={colors.blue} /> : 
          <Text style={styles.t_select}>Select</Text> }
      </Pressable>
    </View>
  );
};

export default memo(DataItem);
