import AffixIcon from "@assets/svg/affix-signature";
import Button from "@src/components/button/Button01";
import useSVGSizeHelper from "@src/utils/svg-size-helper";
import React from "react";
import { Text, View, Pressable } from "react-native";
import useStyles from "./styles.css";
import { SignatureContext } from "../../provider/signature";

const ScreenOne = (props: any) => {
  const styles = useStyles();
  const { setShowQuickSigning } = React.useContext(SignatureContext);
  const svgsize = useSVGSizeHelper({
    "MOBILE": { width: 200, height: 250},
    "LARGE": { width: 201, height: 250},
    "DEFAULT": { width: 237, height: 281},
  });

  return (
    <View style={styles.v_screen_1}>
      <Pressable onPress={() => setShowQuickSigning(false)} style={styles.btn_close} />
      <View style={[ styles.v_modal ]} >
        <Text style={styles.t_title}>Please verify your identity {"\n"}to affix your signature.</Text>
        <View style={styles.v_face_scanning}>
          <AffixIcon name="face-scanning" {...svgsize} />
        </View>
        <Button
          label="Verify Identity"
          onPress={props.onPress}
          style={styles.btn_proceed}
          labelStyle={styles.label_proceed}
        />
      </View>
    </View>
  );
};

export default ScreenOne;
