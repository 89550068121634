import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    flex1: { flex: 1 },
    signDrawText: {
      color: colors.black2,
      fontFamily: "DMSans",
      fontWeight: "400",
      fontSize: 16,
      lineHeight: 21,
      marginTop: 20,
      marginBottom: 27,
    },
    v_signature_canvas: {
      width: "100%",
      height: 240,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ECF3FF",
    },
    signBtnV: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 30,
    },
    btnReset: {
      width: "30%",
      borderWidth: 1,
      marginRight: 12,
      borderColor: colors.purple,
      backgroundColor: colors.white,
    },
    btnSign: {
      width: "30%",
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [maxSize(DEVICE_SIZES.SM)]: {
      flex1: { marginTop: 30 },
      v_signature_canvas: {
        height: 350,
      },
    },
  }
);

export default useStyles;
