import React from "react";
import { Modal, View } from "react-native";
import { SignatureContext } from "../../provider/signature";
import ScreenOne from "./screen-one";
import ScreenThree from "./screen-three";
import ScreenTwo from "./screen-two";
import useStyles from "./styles.css";

const QuickSigning = () => {
  const styles = useStyles();
  const [screen, setScreen] = React.useState("");
  const { showQuickSigning } = React.useContext(SignatureContext);

  const RenderScreen = React.useMemo(() => {
    switch(screen){
    case "SCREEN2":
      return <ScreenTwo onChangeScreen={setScreen}/>;
    case "SCREEN3":
      return <ScreenThree />;
    default:
    case "SCREEN1":
      return <ScreenOne onPress={() => setScreen("SCREEN2")} />;
    }
  },[screen]);

  if(!showQuickSigning){
    return null;
  }

  return (
    <Modal
      transparent
      visible={true}
      animationType={"none"}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <View style={styles.container}>
        {RenderScreen}
      </View>
    </Modal>
  );
};

export default QuickSigning;
