import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: { 
      flex: 1,
      alignItems: "flex-end",
      backgroundColor: colors.blurBG,
    },
    formContainer: {
      width: 524,
      height: "100%",
      paddingTop: 52,
      paddingHorizontal: 40,
      backgroundColor: colors.white,
      shadowColor: "gray",
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.6,
      shadowRadius: 10,
    },
    h4Bold: {
      fontFamily: "DMSans",
      fontWeight: "700",
      color: colors.blue,
      fontSize: 24,
      lineHeight: 20,
      marginBottom: 17,
    },
    btn_close: {position: "absolute",  right: 50},
    body2: {
      fontFamily: "DMSans",
      fontSize: 14,
      marginTop: 20,
      lineHeight: 24,
      color: colors.black3,
    },
    body2_bold: { fontFamily: "DMSans-Bold" },
    
    body1MR: {
      marginTop: 43,
      fontSize: 14,
      lineHeight: 24,
      color: colors.purple,
      fontFamily: "DMSans-Bold",
    },
  
    // PARTIES
    addContainer: { flexDirection: "row", alignItems: "center" },
    t_number: {
      fontSize: 14,
      lineHeight: 24,
      color: colors.black3,
      fontFamily: "DMSans-Bold",
    },
    partiesContainer: {
      marginTop: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    partiesContainer2: {
      flex: 1,
      marginLeft: 15,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    witnessNumber: {
      height: 32,
      width: 32,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.lightGray,
    },
    name: {
      fontSize: 14,
      lineHeight: 16,
      color: colors.black3,
      fontFamily: "DMSans-Bold",
    },
    role: {
      flex: 0.8,
      fontSize: 14,
      lineHeight: 18,
      color: colors.black3,
      fontFamily: "DMSans",
      textAlign: "center",
    },
    actions: {
      fontSize: 14,
      lineHeight: 18,
      fontFamily: "DMSans",
      color: colors.green,
      textTransform: "capitalize",
    },
    v_height10: { height: 10 },
    v_height60: { height: 60 },
  },
  {
    [DEVICE_SIZES.LG]: {
      formContainer: {
        width: 450,
        paddingTop: 42,
        paddingHorizontal: 30,
      },
      h4Bold: {
        fontSize: 18,
        lineHeight: 18,
        marginBottom: 12,
      },
      body2: {
        fontSize: 11,
        marginTop: 16,
        lineHeight: 12,
      },
      body1MR: { fontSize: 11, lineHeight: 13 },
      witnessNumber: { width: 28, height: 28 },
      t_number: { fontSize: 11, lineHeight: 13 },
      name: { fontSize: 11, lineHeight: 13 },
      role: { fontSize: 11, lineHeight: 13 },
      actions: { fontSize: 11, lineHeight: 13 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: {
        paddingHorizontal: 0,
        paddingLeft: 50
      },
      v_height60: { height: 20 },
      formContainer: {
        width: "100%",
        paddingHorizontal: 20,
      },
      partiesContainer2: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      name: {
        fontSize: 14,
        lineHeight: 18,
      },
      actions: {
        fontSize: 12,
        lineHeight: 16,
      },
      btn_close: {position: "absolute", top: 0, right: 10},
    },
  }
);

export default useStyles;
