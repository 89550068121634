import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    index_container: { flex: 1 },
    public_view_cointainer: { flex: 1, paddingHorizontal: 70 },
    container: { flex: 1, flexDirection: "row" },
    body_style: { flex: 1, alignItems: "flex-start" },
    v_height: { height: 40 },
  },
  {
    [DEVICE_SIZES.LG]: {
      public_view_cointainer: { flex: 1, paddingHorizontal: 50 },
      v_height: { height: 30.75 },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      public_view_cointainer: { flex: 1, paddingHorizontal: 0 },
    },
  }
);

export default useStyles;
