import { useNavigation } from "@react-navigation/native";
import { Button01 } from "@src/components/button";
import useOrientationHelper from "@src/utils/orientation-helper";
import React from "react";
import { Text, View } from "react-native";
import { SignupContext } from "./SignupProvider";
import useStyles from "./styles.css";

const EmailSent = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const isLandScape = useOrientationHelper();
  const { updateScreen } = React.useContext(SignupContext);

  const onBack = () => {
    navigation.navigate("Signin");
    updateScreen("");
  };

  return (
    <View style={isLandScape ? styles.v_signup_landscape : styles.v_signup }>
      <View style={styles.signInCenter}>
        <Text style={styles.t_email_sent}>Email Sent</Text>
        <Text style={styles.t_email_sent_notes}>
          Click the link that was sent to your email to complete the
          verification process. You will only be able to access the dashboard
          once you are verified.
        </Text>
      </View>
      <Button01
        label="Back to Sign In"
        style={styles.btn_backtologin}
        labelStyle={styles.lbl_signup}
        onPress={onBack}
      />
      <Text style={styles.t_email_sent_notes2}>
        Did not receive the email? Check your spam or junk folder
      </Text>
    </View>
  );
};

export default EmailSent;
