import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    v_drawer_container: {
      zIndex: 3,
      width: 360,
      maxWidth: 360,
      height: "100%",
      minHeight: "100%",
      maxHeight: "100%",
    },
    v_drawer_translate: {
      zIndex: 2,
      height: "100%",
      borderTopRightRadius: 30,
    },
    linear_style: {  
      width: "100%",
      height: "100%",
      paddingLeft: 30,
      position: "absolute",
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
    },
    btn_invisible: {
      height: "100%",
      width: "100%",
      position: "absolute",
      backgroundColor: colors.red,
    },

    // DRAWER HEADER
    drawer_user_frame_container: { 
      alignItems: "center", 
      justifyContent: "center", 
      marginRight: 30,
      marginTop: 39,
    },
    user_edit_profile_container: {
      marginTop: 46,
      paddingLeft: 18,
      flexDirection: "row",
      alignSelf: "flex-start",
    },
    user_profile_picture: {
      height: 60,
      width: 60,
      borderRadius: 60 / 2,
      marginRight: 16,
    },
    v_details: { justifyContent: "center" },
    v_account: {flexDirection: "row", alignItems: "center", marginTop: 15},
    user_fullname: {
      color: colors.white,
      fontSize: 20,
      lineHeight: 14,
      fontWeight: "700",
      fontFamily: "DMSans-Bold",
      textTransform: "capitalize"
    },
    user_plan: {
      fontSize: 12,
      lineHeight: 16,
      color: colors.white,
      fontFamily: "DMSans-Bold",
    },
    divider: {
      width: "100%",
      height: 1,
      backgroundColor: colors.white,
      marginTop: 16,
      opacity: 0.1,
    },
    icon_notif: {
      top: 0,
      left: -3,
      position: "relative",
      backgroundColor: colors.green,
      height: 9,
      width: 9,
      borderRadius: 10,
    },
    ti_style: { marginTop: 0, width: "100%", height: "100%" },
    ti_search: {
      flex: 1,
      borderRadius: 8,
      backgroundColor: "white",
    },
    ti_border_style: { flex: 1, backgroundColor: colors.white },
    btn_account: {
      height: 25,
      width: 104,
      marginLeft: 12,
      borderRadius: 8,
      paddingHorizontal: 0,
      backgroundColor: colors.blue,
    },
    label_account: {
      fontFamily: "DMSans-Bold", fontSize: 10, 
      lineHeight: 14, color: colors.white
    },
    v_height: { height: 190 },
    drawer_logout: {
      marginLeft: 0,
      position: "absolute",
      bottom: "13%",
      paddingLeft: 0,
      minWidth: 0,
    },

    // COPYRIGHT
    drawer_content_footer: {
      bottom: 10,
      left: 0,
      right: 0,
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { marginBottom: 20, alignSelf: "center", textAlign: "center", color: colors.white },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([
      TypographyStyle.copyRightButton,
      { fontWeight: "400" },
    ]),
    signModalBlurBG: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: colors.blurBG,
      justifyContent: "center",
      alignItems: "center",
    },
    signContainer: {
      backgroundColor: colors.white,
      width: "35%",
      left: 200,
      borderRadius: 20,
      padding: 40,
    },
    signHeader: {
      width: "100%",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 31,
    },
    signTextHeader: {
      color: colors.black2,
      fontFamily: "Montserrat",
      fontWeight: "700",
      fontSize: 24,
      lineHeight: 29,
    },
    signTabsContainer: {
      width: "100%",
      height: 50,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: 7,
      paddingVertical: 2,
      borderRadius: 8,
      backgroundColor: colors.lilac,
    },
    signTabBG: {
      width: "22%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 6,
    },
    signTabText: {
      color: colors.black2,
      fontFamily: "Lato",
      fontWeight: "400",
      fontSize: 13,
      lineHeight: 16,
    },
    signTabImg: { width: 18, height: 18, tintColor: colors.black2 },
    signDrawText: {
      color: colors.black2,
      fontFamily: "DMSans",
      fontWeight: "400",
      fontSize: 16,
      lineHeight: 21,
      marginTop: 40,
      marginBottom: 27,
    },
    signBtnV: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 30,
    },
    btnReset: {
      backgroundColor: colors.white,
      borderWidth: 1,
      borderColor: colors.white,
      width: "30%",
      marginRight: 12,
    },
    adminTabs: StyleSheet.flatten([
      TypographyStyle.Body2Regular,
      {
        paddingVertical: 15,
        paddingHorizontal: 26,
        color: colors.black3,
      },
    ]),
  },
  {
    [DEVICE_SIZES.LG]: {
      v_drawer_container: {
        width: 270,
        maxWidth: 270,
        minHeight: "100%",
        maxHeight: "100%",
      },
      v_height: { height: 108 },
      user_edit_profile_container: { paddingLeft: 13.5 },
      user_profile_picture: { height: 45, width: 45, borderRadius: 45 / 2, marginRight: 10 },
      user_fullname: { fontSize: 15, lineHeight: 10.5 },
      user_plan: { fontSize: 10, lineHeight: 11.72 },
      btn_account: { width: 78, height: 18.75, borderRadius: 6 },
      label_account: { fontSize: 7.5, lineHeight: 10.56 },
      linear_style: { 
        paddingLeft: 22.5,
        borderTopRightRadius: 22.5,
        borderBottomRightRadius: 22.5
      },
      copyRightStyle: { fontSize: 9, lineHeight: 13.5 },
      copyRightBtnStyle: { fontSize: 9, lineHeight: 13.5 },
      drawer_logout: { marginLeft: 3, bottom: "14%"},
    },
    [DEVICE_SIZES.MD]: {
      user_fullname: { fontSize: 12 },
      v_height: { height: 50 },
      user_plan: { fontSize: 9, lineHeight: 11.72 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_drawer_translate: {
        height: "100%",
        width: "50%",
        transform: [{ translateX: -500 }],
      },
      v_height: { height: 0 },
      drawer_logout: { bottom: "14%" },
      user_profile_picture: { height: 45, width: 45, borderRadius: 45 / 2 },
      user_fullname: {
        fontSize: 12,
        // marginTop: 15,
        // marginBottom: 7,
      },
      v_drawer_container: {
        zIndex: 3,
        width: 340,
        maxWidth: 340,
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
      },
      user_plan: {
        fontSize: 9,
        lineHeight: 11,
        textAlign: "center",
      },
      user_edit_profile_container: {
        marginTop: 39,
      },
    },
  }
);

export default useStyles;
