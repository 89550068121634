import SVGIcon from "@assets/svg";
import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import useSVGSizeHelper from "@src/utils/svg-size-helper";
import React, { useContext } from "react";
import { Pressable, Text, View } from "react-native";
import useStyles from "./styles.css";

const WelcomeGuest = () => {
  const styles = useStyles();
  const { onActiveTab } = useContext(DrawerContext);
  const size = useSVGSizeHelper({
    "MOBILE": { width: 204, height: 42},
    "LARGE": { width: 185, height: 61.5},
    "DEFAULT": { width: 270, height: 56},
  });
  
  return (
    <View style={[styles.drawer_user_frame_container]}>
      <Pressable android_ripple={null} android_disableSound={true}  onPress={() => onActiveTab("Dashboard")} style={styles.logo}>
        <SVGIcon name="SignSecure" {...size} />
      </Pressable>
      <View style={[styles.user_edit_profile_container]}>
        <View style={[styles.v_details]}>
          <Text style={styles.t_welcome}>Welcome Guest!</Text>
          <Text style={styles.t_welcome_description}>
            We're thrilled to have you on board for a seamless and efficient e-signature experience. 
            Our user-friendly platform has been designed with you in mind, ensuring a hassle-free journey from 
            start to finish.</Text>
        </View>
      </View>
    </View>
  );
};

export default WelcomeGuest;
