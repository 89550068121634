import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import Icon from "@expo/vector-icons/MaterialIcons";
import React from "react";
import { Modal, Pressable, Text, View } from "react-native";
import Button from "@src/components/button/Button01";
import { DrawerContext } from '@src/screens/drawer-screens/drawer-provider';
import useStyles from "./modal-styles.css";

const SuccessModal = (props: any) => {
    const { onActiveTab } = React.useContext(DrawerContext);
    const styles = useStyles();

    const onCloseModal = () => {
        props.onClose();
        onActiveTab("Dashboard");
    }

    return (
        <Modal
        transparent
        visible={props.visible}
        animationType={"none"}
        onRequestClose={props.onClose}
        supportedOrientations={[
            "portrait",
            "portrait-upside-down",
            "landscape",
            "landscape-left",
            "landscape-right",
        ]}
        >
        <View style={styles.container}>
            <View style={[ styles.v_modal]} >
            <SVGIcon name="email-sent" width={180} height={85} fill={colors.colorPrimary} />
            <Text style={styles.t_title}>Your message has been sent successfully!</Text>
            <Pressable onPress={props.onClose} style={styles.btn_close}>
                <Icon name="close" size={24} color="white" />
            </Pressable>
            <Button label="Back to Dashboard" style={styles.back_btn} onPress={onCloseModal} />
            </View>
        </View>
        </Modal>
    );
};

export default SuccessModal;
