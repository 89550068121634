import colors from "@assets/colors";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useAuthContext } from "@src/AuthProvider";
import { Button01 } from "@src/components/button";
import Loading from "@src/components/loading";
import { useFetchGet } from "@src/utils/api/hooks";
import { convertStringBreakline } from "@src/utils/transform-helper";
import _ from "lodash";
import numeral from "numeral";
import React, { useEffect } from "react";
import { Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useSignupContext } from "../provider";
import useStyles from "./styles.css";

const PLANCODES: any = {
  pro: "Professional",
  "pro-12": "Professional",
  biz: "Business",
  "biz-12": "Business",
};

const ReviewPlan = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const code = route?.params?.plan;
  const navigation = useNavigation<any>();
  const { onErrorMessage } = useAuthContext();
  const { data, loading, error, runRequest } = useFetchGet();
  const { state, subscriptionPlan, setSubscriptionPlan, setActiveIndex } = useSignupContext();

  const getAmountCost = React.useMemo(() => {
    const totalCost = numeral(subscriptionPlan.cost).format("0,000");
    return `₱${totalCost}`;
  },[subscriptionPlan]);

  useEffect(() => {
    runRequest("/subscription-plans/all");
  }, []);

  useEffect(() => {
    if(!_.isEmpty(data?.data?.monthly)){
      const subscriptions = ["pro-12","biz-12"].includes(code) 
        ? data?.data?.annual ?? [] : data?.data?.monthly ?? [];
      const result = subscriptions.find((item: any) => {
        return item?.name === PLANCODES[code];
      });
      setSubscriptionPlan(result);
    }

    if(!_.isEmpty(error?.message)){
      onErrorMessage(error.message);
    }
  }, [data, code, error]);

  if(loading){
    return <Loading />;
  }

  const textTitle = () => {
    if(code === "pro" || code === "pro-12"){
      return "Business Plan";
    } else if(code === "biz" || code === "biz-12"){
      return "Professional Plan";
    }
  };
  const nextTarget = () => {
    switch(code){
    case "pro":
      setActiveIndex(0);
      return navigation.navigate("PromoSignup", { plan: "biz"});
    case "pro-12":
      setActiveIndex(0);
      return  navigation.navigate("PromoSignup", { plan: "biz-12"});
    case "biz":
      setActiveIndex(0);
      return navigation.navigate("PromoSignup", { plan: "pro"});
    case "biz-12":
      setActiveIndex(0);
      return  navigation.navigate("PromoSignup", { plan: "pro-12"});
    default:
      return null;
    }
  };

  return (
    <>
      <View style={styles.promoAlert}>
        <Text style={styles.promoAlertText}>
          Have a promo code? Use it in the next step!
        </Text>
      </View>
      <View style={styles.os_container}>
        <Text style={styles.t_order_summary}>Order Summary</Text>
        <Text style={[styles.t_document_title, styles.t_add_margin]}>Please confirm if all details are correct.</Text>
        <View style={styles.v_divider} />
        <Text style={styles.t_order_summary}>{subscriptionPlan.name}</Text>
        <Text style={[styles.t_package_details, styles.t_add_margin]}>
          {convertStringBreakline(subscriptionPlan.pageDescription)}
        </Text>
        <View style={styles.v_divider2} />
        <View style={[styles.v_item_rows]}>
          <Text style={[styles.t_document_title, styles.t_font_bold]}>Email</Text>
          <Text style={styles.t_document_title}>{state.email}</Text>
        </View>
        <View style={[styles.v_item_rows, styles.add_row_margin_top]}>
          <Text style={[styles.t_document_title, styles.t_font_bold]}>Plan</Text>
          <Text style={styles.t_document_title}>{subscriptionPlan.name}</Text>
        </View>
        <View style={[styles.v_item_rows, styles.add_row_margin_top]}>
          <Text style={[styles.t_document_title, styles.t_font_bold]}>Billing</Text>
          <Text style={styles.t_document_title}>{subscriptionPlan.paymentMode}</Text>
        </View>
        <View style={[styles.v_item_rows, styles.add_row_margin_top]}>
          <Text style={[styles.t_document_title, styles.t_font_bold]}>Amount</Text>
          <Text style={styles.t_document_title}>{getAmountCost}</Text>
        </View>
        <View style={[styles.v_item_rows, styles.add_row_margin_top]}>
          <Text style={styles.t_total} />
          <Text style={[styles.t_total]}>Total Due    {getAmountCost}</Text>
        </View>
        <View style={styles.v_divider2} />
        <Text style={styles.changeYourMind}>
          Changed your mind? 
          <TouchableOpacity onPress={nextTarget}>
            <b><u> Click here </u></b>
          </TouchableOpacity>
          to get the {textTitle()} instead.
        </Text> 
        <Button01
          label="Proceed to Payment"
          color={colors.white}
          onPress={() => setActiveIndex(2)}
          style={styles.btn_proceed}
          labelStyle={styles.lbl_proceed}
        />
        <View style={styles.v_height} />
      </View>
    </>
  );
};

export default ReviewPlan;
