import React from "react";
import { Image, Text } from "react-konva";
import useImage from "use-image";

const SignatureText = (props: any) => {
  const [image] = useImage(props.src);

  const scale = React.useMemo(() => {
    const imageWidth = image?.width ?? props.width;
    const imageHeight = image?.height ?? props.height;
    const imgHeightScale = props.height / imageHeight;
    const newWidth = imageWidth * imgHeightScale;

    return { width: newWidth, height: props.height, scaleY: imgHeightScale};
  }, [props.width, props.height, image]);

  return (
    <Text
      align="center"
      fontSize={scale.height * .3}
      fontFamily={props.font}
      text={props.text}
      width={scale.width}
      height={scale.height}
      image={image}
      x={(props.width - scale.width) / 2}
      y={props.y}
      scale={props.scale}
      scaleX={props.scaleX}
      scaleY={props.scaleY}
    />
  );
};

export default SignatureText;
