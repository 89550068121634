import SVGIcon from "@assets/svg";
import { largeSize, tabletSize } from "@src/utils/screensize-helper";
import React from "react";
import { Text, View } from "react-native";
import useStyles from "./styles.css";
import UnawaProduct from "./unawaProduct";

type LogoProps = {
  logoColor?: string;
  termsColor?: string;
  termsStyle?: any;
}

const Logo = (props: LogoProps) => {
  const styles = useStyles();
  const isTablet = tabletSize();
  const isLargeSize = largeSize();

  const size = React.useMemo(() => {
    if(isLargeSize){
      return {
        width: 250,
        height: 47,
      };
    } 
    if (isTablet){
      return {
        width: 242,
        height: 40,
      };
    }

    return {
      width: 262,
      height: 49,
    };
  },[isLargeSize]);

  return (
    <View style={styles.v_logo_container}>
      <SVGIcon name="signsecure-no-v2" {...size} fill={props.logoColor}  />
      <Text style={[styles.t_logo, { color: props.logoColor}]}>Freedom from fraud {"\n"}with secure and efficient {"\n"}e-signatures</Text>
      <UnawaProduct />
    </View>
  );
};

export default Logo;
