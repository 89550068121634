import React from "react";
import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import { getActiveIndex } from "@src/utils/filter-helper";
import { LiveRequestSteps } from "@src/utils/mockdata";
import SendButton from "./send-button";
import useStyles from "./styles.css";
import { View } from "react-native";
import Items from "./items";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedDraftDocument } from "@src/ducks/slices/signsecure.slice";

const CDStepsHeader = () => {
  const styles = useStyles();
  const { activeDocument, onSetCreateDocumentScreen } = React.useContext(DrawerContext);
  const activeIndex = React.useMemo(() => getActiveIndex(activeDocument), [activeDocument]);  
  const document = useAppSelector(selectedDraftDocument);
  let isDraft = false
  if (document && document.files) {
    isDraft = document?.files[0]?.isDraft;
  }
  
  return (
    <View style={styles.container}>
      <View style={styles.steps_container}>
        {LiveRequestSteps.map((item, index) => {
          return (
            <Items
              key={`stepIdx${index}`}
              item={item}
              disabled={isDraft ? false : activeIndex < index}
              isActive={isDraft ? false : activeIndex === index}
              isDraft={isDraft}
              isDone={activeIndex > index}
              onPress={() => onSetCreateDocumentScreen(item)}
            />
          );
        })}
      </View>
      <SendButton />
    </View>
  );
};

export default React.memo(CDStepsHeader);
