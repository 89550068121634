import images from "@assets/images";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedEnableQuickSigning } from "@src/ducks/slices/signsecure.slice";
import LocalImage from "@src/screens/create-document/fields/stages/Image";
import React from "react";
import { CanvasContext } from "../../provider/canvas";

type Props = {
  field: any;
  onQuickSign: () => void;
};

const QuickSigning = (props: Props) => {
  const { field } = props;
  const { canvas } = React.useContext(CanvasContext);
  const isQuickSigningEnable = useAppSelector(selectedEnableQuickSigning);

  const { CANVAS_WIDTH, ACTION_QUICK_SIGNING_WIDTH, ACTION_QUICK_SIGNING_HEIGHT, ACTION_QUICK_SIGNING_MARGIN } = React.useMemo(() => {
    const C_WIDTH = canvas.width * canvas.scale  || 0;
    const C_HEIGHT = canvas.height * canvas.scale || 0;
    const ACTION_QUICK_SIGNING_WIDTH = C_WIDTH * 0.29;
    const ACTION_QUICK_SIGNING_HEIGHT = C_HEIGHT * 0.032;
    const ACTION_QUICK_SIGNING_MARGIN = C_WIDTH * 0.016;

    return { CANVAS_WIDTH: C_WIDTH, ACTION_QUICK_SIGNING_WIDTH, ACTION_QUICK_SIGNING_HEIGHT, ACTION_QUICK_SIGNING_MARGIN };
  }, [canvas]);

  const actionState = React.useMemo(() => {
    let checkX = field.config.x - ((ACTION_QUICK_SIGNING_WIDTH - field.config.width) / 2);
    let checkY = field.config.y + (field.config.height + ACTION_QUICK_SIGNING_MARGIN );

    if(Math.trunc(field.config.rotation) === 90){
      checkX = field.config.x - (field.config.height + ACTION_QUICK_SIGNING_MARGIN);
      checkY = field.config.y - ((ACTION_QUICK_SIGNING_WIDTH - field.config.width) / 2);
    }else if(Math.trunc(field.config.rotation) === -90){
      checkX = field.config.x + field.config.height + ACTION_QUICK_SIGNING_MARGIN;
      checkY = field.config.y + ((ACTION_QUICK_SIGNING_WIDTH - field.config.width) / 2);
    }else if(Math.trunc(field.config.rotation) === 180){
      checkX = field.config.x + ((ACTION_QUICK_SIGNING_WIDTH - field.config.width) / 2);
      checkY = field.config.y - (field.config.height + ACTION_QUICK_SIGNING_MARGIN );
    }

    return {
      checkX,
      checkY,
    };
  }, [CANVAS_WIDTH]);

  if(field?.signature || !isQuickSigningEnable){
    return null;
  }

  return (
    <LocalImage
      x={actionState.checkX}
      y={actionState.checkY}
      rotation={field.config.rotation}
      width={ACTION_QUICK_SIGNING_WIDTH}
      height={ACTION_QUICK_SIGNING_HEIGHT}
      onClick={props.onQuickSign}
      onTap={props.onQuickSign}
      verticalAlign="middle"
      src={images["quick-signing"]}
    />
  );
};

export default React.memo(QuickSigning);
