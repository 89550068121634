import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import Button from "@src/components/button/Button01";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useUserService } from "@src/ducks/hooks";
import { selectSubscriptionTeamID } from "@src/ducks/slices/user.slice";
import { useFetchPatch } from "@src/utils/api/hooks";
import _ from "lodash";
import React, { useContext } from "react";
import { Modal, Text, View, } from "react-native";
import useStyles from "./styles.css";
import AddInfo from "./add-new-team-text";
import GeneratePassword from "./generatePassword";
import AddSuccessful from "./password-success";
import { DocumentContext } from "../provider/document";
import PasswordFields from "./password-fields";

interface ErrorProps {
  password: string;
  confirmpassword: string;
}

const ErrorValue = {
 password: "",
 confirmpassword: "",
};


const AddEncrpytPassword = (props: any) => {
  const styles = useStyles();
  const [success, setSuccess] = React.useState(false);
  const { loading, data, runRequest } = useFetchPatch();
  const teamId = useAppSelector(selectSubscriptionTeamID);
  const { files,onUpdateFile, setSuccessful, setPasswordEncrpyt, onSetPdfEncryptPassword,enableGeneratePassword,  setCheckBoxTick,generatePassword,setGeneratePassword, setGeneratePasswordTick,pdfpasswords, setPDFPassword, fileActiveIndex} = React.useContext(DocumentContext)


  const errorRef = React.useRef<any>(null);
   const [confirmPdfPassword, setConfirmPdfPassword] = React.useState<string>("");
    const [isGeneratedPassword, setIsGeneratedPassword] = React.useState(false);
   const [error, setError] = React.useState<ErrorProps>(ErrorValue);


  const onDeleteMember = () => {
    runRequest("/subscription-team/member/remove/" + teamId, {
      "memberEmail": props.email
    });
  };

  
  const onSubmit = () => {
    const newError: any = {};

        if(!enableGeneratePassword){
          if (!pdfpasswords) {
            newError.password = "Password is required.";
          }
      
          if (!confirmPdfPassword) {
            newError.confirmpassword = "Confirm Password is required.";
          }
      
          if (pdfpasswords !== confirmPdfPassword) {
            newError.confirmpassword = "Password not match";
          }
      
          if (pdfpasswords == confirmPdfPassword) {
            onSetPdfEncryptPassword(pdfpasswords)
          }

      
        }
      
        setError(newError);

        if (_.isEmpty(newError)) {
          if(enableGeneratePassword){
            onSetPdfEncryptPassword(generatePassword);
            onUpdateFile(fileActiveIndex,"password",generatePassword)
          }else{
            if(pdfpasswords == confirmPdfPassword) {
              onSetPdfEncryptPassword(pdfpasswords)
              onUpdateFile(fileActiveIndex,"password",pdfpasswords)
            }
          }
     
          setSuccessful(true)
          setPasswordEncrpyt(false) 
          console.log("files2", files);
        }
  }

  const onClose = () => {
    if(enableGeneratePassword == true){
        <PasswordFields />
        setGeneratePasswordTick(false)
        setIsGeneratedPassword(false)
        setPasswordEncrpyt(false)
        setCheckBoxTick(false)
    } else if(!enableGeneratePassword){
        <PasswordFields />
        setPasswordEncrpyt(false)
        setCheckBoxTick(false)
    } 
    else {
      <PasswordFields />
        setPasswordEncrpyt(false)
        onSetPdfEncryptPassword("")
        setIsGeneratedPassword(true)
        setCheckBoxTick(false)
    }
  };

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      setSuccess(true);
    }
  }, [data]);

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={"none"}
      onRequestClose={props.onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      
      <View style={styles.container}>
        <View style={[ styles.email_sent_modal ]} >
          <AddInfo />
          <GeneratePassword isGeneratePassword={setIsGeneratedPassword} generatePassword={setGeneratePassword}/>
          {!enableGeneratePassword  &&  <PasswordFields password={setPDFPassword} confirmPassword={setConfirmPdfPassword} error={error}/> }
          <View style={styles.btn_alignment}>
          <Button
              onPress={onSubmit}
              // isLoading={loading || loadingPatch}
              style={styles.btn_proceed}
              labelStyle={styles.label_proceed}
              label={"Encrypt"}
            />
            <View style={styles.v_width} />
            <Button
            
              label="Cancel"
              style={styles.btn_cancel}
              labelStyle={styles.label_cancel}
              onPress={onClose}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default AddEncrpytPassword;
