import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: { 
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    linearStyle_portrait: { display: "none" },

    maintenance_logo_container: {
      flexDirection: 'row', 
      justifyContent: 'center', 
      alignItems: 'center', 
      bottom: 50, 
    },
    t_logo_container: {
      width: '42%', 
    },
    t_logo: {
      fontFamily: "DMSans",
      fontWeight: "500",
      paddingTop: 0,
      fontSize: 35,
      lineHeight: 45,
      marginTop: 27,
      marginBottom: 20,
      color: colors.white, 
    },

    //IMAGE BG
    i_bg: {
      ...StyleSheet.absoluteFillObject,
      width: "100%",
      height: "100%",
    },
    // BACKGROUND COMPONENT
    linearStyle: {
      position: "absolute",
      zIndex: -1,
      width: "100%",
      height: "100%",
    },

    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      {
        alignSelf: "center",textAlign: "center", 
        color: colors.white },
    ]),
    unawaProductStyle : StyleSheet.flatten([
      TypographyStyle.copyRightText,
      {  marginTop: 20,
        alignSelf: "flex-start",textAlign: "flex-start", 
        color: colors.white },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([TypographyStyle.copyRightButton]),


  },
  {
    [DEVICE_SIZES.XL]: {
    },
    [DEVICE_SIZES.LG]: {
      t_logo: { fontSize: 40, lineHeight: 45, marginTop: 21 },
    },
    [DEVICE_SIZES.MD]: {
      t_logo: { 
        fontSize: 35, 
        lineHeight: 35, 
        marginTop: 15, 
        fontWeight: "500",
        // textAlign: "center"
      },
      linearStyle_portrait: { display: "none" },
    }, 
    [DEVICE_SIZES.SM]: {
      t_logo_container: {
        width: '55%', 
      },
      t_logo: { 
        fontSize: 25, 
        lineHeight: 25, 
        marginTop: 15, 
        fontWeight: "500",
        // textAlign: "center"
      },

      //portrait
      linearStyle_portrait: { display: "none" },
    },
    [DEVICE_SIZES.XS]: {
      maintenance_logo_container: {
        flexDirection: 'column', 
        bottom: 20,
      },
      t_logo_container: {
        width: '90%', 
        marginLeft: 20,
        marginTop: 30,
      },
      t_logo: { 
        fontSize: 20, 
        lineHeight: 25, 
        marginTop: 15, 
        fontWeight: "500",
        // textAlign: "center"
      },
      linearStyle_portrait: { display: "none" },
    },
  }
);

export default useStyles;