import images from "@assets/images";
import { ButtonIcon } from "@src/components/button";
import { useFetchGet } from "@src/utils/api/hooks";
import React, { useMemo, useState } from "react";
import { Modal, ScrollView, Text, View } from "react-native";
import { SignatureContext } from "../provider/signature";
import ChooseSection from "./choose-section";
import DrawSection from "./draw-section";
import useStyles from "./styles.css";
import Tabs from "./tabs";
import TabsGuest from "./tabs_Guest";
import TypeSection from "./type-section";
import UploadSection from "./upload-section";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectAuthLoggedIn, selectedAuthGuestRole } from "@src/ducks/slices/auth.slice";

const SigningModal = () => {
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState("Draw");
  const { loading, data, runRequest } = useFetchGet();
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const { showSignature, setShowSignature } = React.useContext(SignatureContext);
  const isGuest = useAppSelector(selectedAuthGuestRole);

  const [fonts, setFonts] = useState(["Lora", "PlayfairDisplay", "Redressed", "Yellowtail"]);

  const onClose = () => setShowSignature(false);

  const RenderTabItem = useMemo(() => {
    switch (activeTab) {
    case "Upload":
      return <UploadSection onClose={() => {
        onClose();
        onFetchSignatures();
      }} />;
    case "Choose":
      return <ChooseSection loading={loading} data={data?.data ?? []} onClose={onClose} />;
    case "Type":
      return <TypeSection loading={loading} data={fonts ?? []} onClose={onClose} />;
    case "Draw":
    default:
      return <DrawSection />;
    }
  }, [activeTab, onClose, data]);

  const onFetchSignatures = () => {
    runRequest("/signature/all");
  };

  React.useEffect(() => {
    if(isLoggedIn){
      onFetchSignatures();
    }
  }, [isLoggedIn]);

  return (
    <Modal
      transparent
      visible={showSignature}
      animationType={"none"}
      onRequestClose={onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <View style={styles.signModalBlurBG}>
        <View style={styles.signContainer}>
          <View style={styles.signHeader}>
            <Text style={styles.signTextHeader}>Add your signature</Text>
            <ButtonIcon
              onPress={onClose}
              source={images.icon_close}
              style={styles.i_close}
            />
          </View>
          {isGuest ? <TabsGuest activeTab={activeTab} setActiveTab={setActiveTab} /> : 
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab} /> }
          <ScrollView>{RenderTabItem}</ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default SigningModal;
