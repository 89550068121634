import { Button04 } from "@src/components/button";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useSignsecureService } from "@src/ducks/hooks";
import { selectedDocumentParties, selectedEnableSignature, selectedSignSecureLoading, selectedSignSecureLoadingStatus } from "@src/ducks/slices/signsecure.slice";
import { countSignatures } from "@src/utils/validation-helper";
import _ from "lodash";
import React from "react";
import { SignatureContext } from "../../provider/signature";
import useStyles from "./styles.css";
// import { completeSignatureWithCreateManifest, submitOnlyAffixSignature } from "@src/utils/signature-helper";
// import Downloadjs from "downloadjs";

const getButtonLabel = (title: string) => {
  switch(title){
  case "APPROVE":
    return "Approve";
  case "SIGN":
  default:
    return "Affix Signature";
  }
};

const AffixButton = () => {
  const styles = useStyles();
  const { document } = React.useContext(SignatureContext);
  const userParty = useAppSelector(selectedDocumentParties);
  const isLoading = useAppSelector(selectedSignSecureLoading);
  const enableSignature = useAppSelector(selectedEnableSignature);
  const loadingLabel = useAppSelector(selectedSignSecureLoadingStatus);
  const { signDocumentRequest, approveDocumentRequest } = useSignsecureService();
  const ButtonLabel = React.useMemo(() => getButtonLabel(userParty.role), [userParty.role]);
  const result = React.useMemo(() => countSignatures(document, userParty), [document, userParty]);

  const onPress = React.useCallback(async() => {
    // const base64 = await completeSignatureWithCreateManifest(document.uri, document);
    // const blob = new Blob([base64], { type: "application/pdf" });
    // Downloadjs(
    //   blob,
    //   "files.pdf",
    //   "application/pdf"
    // );
    // return;
    switch(userParty.role){
    case "APPROVE":
      approveDocumentRequest(document);
      break;
    case "SIGN":
      signDocumentRequest(document);
      break;
    }
  },[userParty, document]);

  if(_.isEmpty(userParty) || ["DECLINED", "REJECTED", "CANCELLED", "COMPLETED"].includes(document?.workflow?.status) 
    || userParty.role === "COPY" || ["APPROVED", "SIGNED"].includes(userParty.status) || !enableSignature){
    return null;
  }

  return (
    <Button04
      onPress={onPress}
      disabled={result.count > 0}
      isLoading={isLoading}
      style={styles.btn_style}
      labelStyle={styles.btn_label}
      label={isLoading ? loadingLabel : ButtonLabel}
    />
  );
};

export default AffixButton;
