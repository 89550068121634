import SVGIcon from "@assets/svg";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedDocumentParties } from "@src/ducks/slices/signsecure.slice";
import { usePDFScale } from "@src/utils/screensize-helper";
import { getViewport } from "@src/utils/signature-helper";
import React, { useContext, useMemo } from "react";
import { View } from "react-native";
import { CanvasContext } from "../provider/canvas";

const SIGNATURE_TYPE = ["signature", "signature-name", "signature-date", "signature-name-designation", "initials"];

function DisplaySignHere(props: any) {
  const scale = usePDFScale();
  const { loading, layers, maxWidth } = props;
  const { canvas } = useContext(CanvasContext);
  const userParty = useAppSelector(selectedDocumentParties);

  const { CANVAS_WIDTH, CANVAS_HEIGHT } = useMemo(() => getViewport(canvas, scale),
    [canvas, scale]
  );

  return (
    <View pointerEvents="none" style={{
      maxWidth,
      position: "absolute",
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
    }}>
      {loading === false &&
        layers.map((item: any, index: number) => {
          if (index === canvas.pageIndex && item) {
            let hasData = "";
            return item.map((field: any, index: number) => {
              if(field.trackId === userParty.trackId && SIGNATURE_TYPE.includes(field.type) && !field.signature && !hasData){
                const config = field.config;
                hasData = field.id;
                return (
                  <View key={index.toString()} 
                    style={{position: "absolute", zIndex: 10, left: -100 * canvas.scale, top: config.y * canvas.scale}}>
                    <SVGIcon name="SignHere" width={140 * canvas.scale} height={35 * canvas.scale} />
                  </View>
                );
              }
              return null;
            });
          }
          return null;
        })}
    </View>
  );
}

export default DisplaySignHere;
