import React from "react";
import { View } from "react-native";
import { SignupProvider } from "./SignupProvider";
import Logo from "./logo";
import Screens from "./screens";
import useStyles from "./styles.css";

const Signup = () => {
  const styles = useStyles();

  return (
    <SignupProvider>
      <View style={styles.container}>
        <View style={styles.v_body}>
          <Logo />
          <Screens />
        </View>
      </View>
    </SignupProvider>
  );
};

export default Signup;
