import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, alignItems: "center", justifyContent: "center" },
    t_title: {fontFamily: "DMSans-Bold", fontSize: 24, marginTop: 35,
     color: colors.colorPrimary, textAlign: "center",},
    t_valid: { marginTop: 46, },
    t_notes: {
      fontFamily: "DMSans",
      fontSize: 16,
      lineHeight: 18,
      marginTop: 20,
      textAlign: "center",
      color: colors.black3,
    },
    copyRight:{
      display:"none"
    },
    drawer_item_header: {
      height: 109,
      paddingTop: 12,
      flexDirection: "row",
      alignItems: "center",
      borderBottomWidth: 0.5,
      borderBottomColor: colors.border6
    },
    buttonStyling: {
      flexDirection: "row", 
      alignItems: "center"
    },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { position: "absolute", bottom: 220,
        alignSelf: "center",textAlign: "center", 
        color: colors.black },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([TypographyStyle.copyRightButtonMobile]),
    t_notes_bold: { 
      fontSize: 18,
      lineHeight: 24,
      fontWeight: "600",
    },
    labelStyle: { fontFamily: "DMSans-Bold" },
    btn_proceed: { width: 250, height: 47, marginTop: 46, backgroundColor: colors.blue },
    btn_download: { width: 250, height: 47, marginTop: 46, 
      backgroundColor: colors.transparent, borderWidth: 1, borderColor: colors.blue },
    label_download: { fontFamily: "DMSans-Bold", color: colors.blue },
    divider: { width: 22 },
  },
  {
    [DEVICE_SIZES.LG]: {
      drawer_item_header: { height: 81.75, paddingTop: 9 },
      t_title: { fontSize: 18, lineHeight: 10.5, marginTop: 25, },
      t_valid: { marginTop: 40, },
      t_notes: { fontSize: 12, lineHeight: 13.5, marginTop: 14 },
      t_notes_bold: {  fontSize: 12.5, lineHeight: 14, fontWeight: "600" },
      btn_proceed: { width: 187, height: 35, marginTop: 40, borderRadius: 6 },
      btn_download: { width: 187, height: 35, marginTop: 40, borderRadius: 6 },
      labelStyle: { fontSize: 10.5, lineHeight: 10.5 },
      label_download: { fontSize: 10.5, lineHeight: 10.5 },
      divider: { width: 17 },
    },
    [DEVICE_SIZES.XS]: {
      buttonStyling: {
        flexDirection: "column-reverse", 
        alignItems: "center"
      },
      container: {
        paddingTop: "40%"
      },
      btn_proceed: { width: 350, height: 47, marginTop: 46, backgroundColor: colors.blue },
      btn_download: { width: 350, height: 47, marginTop: 17,},
      drawer_item_header: {
        display:"flex",
        height: 60,
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: "flex-end",
        backgroundColor: colors.colorPrimary,
      }
    }
  }
);

export default useStyles;
