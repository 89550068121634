import { useAuthService } from "@src/ducks/hooks";
import { largeSize, tabletSize } from "@src/utils/screensize-helper";
import { LinearGradient } from "expo-linear-gradient";
import React, { useContext } from "react";
import { ScrollView, View } from "react-native";
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
} from "react-native-reanimated";
import CopyRightContent from "./copy-right";
import DrawerList from "./drawer-list";
import DrawerItem from "./drawer-list/drawer-item";
import FloatingButton from "./floating-button";
import DrawerHeader from "./header";
import { DrawerComponentContext } from "./provider";
import useStyles from "./styles.css";

const DrawerComponentScreen = () => {
  const styles = useStyles();
  const isLargeSize = largeSize();
  const isTabletSize = tabletSize();
  const { onLogout } = useAuthService();
  const { animated, isMinimize } = useContext(DrawerComponentContext);

  const animateStyle = useAnimatedStyle(() => {
    const translateX = interpolate(
      animated.value,
      [360, 110],
      [0, isTabletSize ? -380 : 0],
      Extrapolation.CLAMP,
    );

    const width = interpolate(
      animated.value,
      [360, 110],
      [360, isTabletSize ? 0 : isLargeSize ? 81.15 : 110],
      Extrapolation.CLAMP,
    );

    return { 
      width,
      position: isTabletSize ? "absolute" : "relative",
      transform: [{ translateX }] 
    };
  }, [isTabletSize, isLargeSize]);

  const animatePaddingLeft = useAnimatedStyle(() => {
    const paddingLeft = interpolate(
      animated.value,
      [360, 110],
      [isLargeSize ? 22.5 : 30, isLargeSize ? 11.25 : 15],
      Extrapolation.CLAMP,
    );
    return {
      paddingLeft
    };
  }, [isLargeSize]);

  return (
    <Animated.View pointerEvents="box-none" style={[styles.v_drawer_container, animateStyle ]}>
      <LinearGradient
        colors={["#4E70BB", "#253A60", "#253A60", "#253A60"]}
        style={[styles.linear_style]}
      />
      <Animated.View style={[styles.linear_style, animatePaddingLeft]}>
        <ScrollView>
          <DrawerHeader />
          <DrawerList />
          <View style={styles.v_height} />
        </ScrollView>
        <DrawerItem
          item={{
            name: isMinimize ? "" : "Logout",
            icon: "Logout",
          }}
          style={[styles.drawer_logout]}
          onPress={onLogout}
        />
        {!isMinimize && <CopyRightContent /> }
      </Animated.View>
      <FloatingButton />
    </Animated.View>
  );
};

export default React.memo(DrawerComponentScreen);
