import React from "react";
import { SignatureContext } from "../provider/signature";
import { getRoleLabel } from "@src/utils/filter-helper";
import { View, Text } from "react-native";
import useStyles from "./styles.css";
import colors from "@assets/colors";

const Parties = () => {
  const styles = useStyles();
  const { document } = React.useContext(SignatureContext);

  return (
    <>
      <View style={[styles.addContainer]}>
        <Text style={styles.body1MR}>Parties</Text>
      </View>
      <View style={styles.v_height10} />
      {document?.workflow?.parties.map((item: any, index: number) => {
        const status = item.status || item.viewed && "Viewed" || "Unread";

        return (
          <View key={`parties_ids${index}`} style={[styles.partiesContainer]}>
            <View style={styles.witnessNumber}>
              <Text style={[styles.t_number]}>{index + 1}</Text>
            </View>
            <View style={styles.partiesContainer2}>
              <View style={{flex: 1}}>
                <Text style={styles.name}>{item.name}</Text>
                <Text style={[styles.name, { fontFamily: "DMSans" }]}>{item.email}</Text>
              </View>
              <Text style={styles.role}>{getRoleLabel(item.role)}</Text>
              <Text style={[styles.actions, status === "Unread" && { color: colors.black3 }]}>
                {status.toLowerCase()}</Text>
            </View>
          </View>
        );
      })}
    </>
  );
};

export default React.memo(Parties);
