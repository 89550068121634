import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import Button from "@src/components/button/Button01";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useAuthService } from "@src/ducks/hooks";
import { useFetchPost } from "@src/utils/api/hooks";
import _ from "lodash";
import React, { useContext } from "react";
import { Modal, Text, View, } from "react-native";
import useStyles from "./styles.css";
import AddInfo from "./add-new-team-text";
import GeneratePassword from "./generatePassword";
import PasswordFields from "./password-fields";
import { SignatureContext } from "../../provider/signature";
import {
  selectedViewDocument
} from "@src/ducks/slices/signsecure.slice";
import * as API from "@src/utils/api";
import { base64ToBlob } from "base64-blob";

interface ErrorProps {
  password: string;
  confirmpassword: string;
}

const ErrorValue = {
 password: "",
 confirmpassword: "",
};


const AddEncrpytPassword = (props: any) => {
  const styles = useStyles();
  const [success, setSuccess] = React.useState(false);
  const { loading, data, runRequest } = useFetchPost();
  const {setSuccessful, onSetPdfEncryptPassword,enableGeneratePassword,generatePassword,setGeneratePassword,pdfPassword, setGeneratePasswordTick, setPdfPassword,setEncryptedPassword} = React.useContext(SignatureContext)
  const document = useAppSelector(selectedViewDocument);
  const { accessToken } = useAuthService();
  const errorRef = React.useRef<any>(null);
   const [confirmPdfPassword, setConfirmPdfPassword] = React.useState<string>("");
    const [isGeneratedPassword, setIsGeneratedPassword] = React.useState(false);
   const [error, setError] = React.useState<ErrorProps>(ErrorValue);

  const encryptFile = async (password : string) => {
    console.log("Document--",document);

  
    const formData = new FormData();
    const file = await base64ToBlob(document?.uri);
    formData.append("file", file, `${document?.name}.pdf`);
    formData.append("password",  password);
    formData.append("type",  'encrypt-completed');
    const result = API.signsecureEncryptPDF(document.id,formData, accessToken);
  }
  
  const onSubmit = () => {
    const newError: any = {};

        if(!enableGeneratePassword){
          if (!pdfPassword) {
            newError.password = "Password is required.";
          }
      
          if (!confirmPdfPassword) {
            newError.confirmpassword = "Confirm Password is required.";
          }
      
          if (pdfPassword !== confirmPdfPassword) {
            newError.confirmpassword = "Password not match";
          }
      
        }
      
        setError(newError);

        if (_.isEmpty(newError)) {
          if(enableGeneratePassword){
              encryptFile(generatePassword);
          }else{
            if(pdfPassword == confirmPdfPassword) {
              encryptFile(pdfPassword);
            }
          }
     
          setSuccessful(true)
          setEncryptedPassword(false) 
        }
  }

  const onClose = () => {
    if(enableGeneratePassword == true){
        <PasswordFields />
        setGeneratePasswordTick(false)
        setIsGeneratedPassword(false)
        setEncryptedPassword(false)
    } else if(!enableGeneratePassword){
        <PasswordFields />
        setEncryptedPassword(false)
    } 
    else {
      <PasswordFields />
      setEncryptedPassword(false)
        onSetPdfEncryptPassword("")
        setIsGeneratedPassword(true)
    }
  };

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      setSuccess(true);
    }
  }, [data]);

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={"none"}
      onRequestClose={props.onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      
      <View style={styles.container}>
        <View style={[ styles.email_sent_modal ]} >
          <AddInfo />
          <GeneratePassword isGeneratePassword={setIsGeneratedPassword} generatePassword={setGeneratePassword}/>
          {!enableGeneratePassword  &&  <PasswordFields password={setPdfPassword} confirmPassword={setConfirmPdfPassword} error={error}/> }
          <View style={styles.btn_alignment}>
          <Button
              onPress={onSubmit}
              // isLoading={loading || loadingPatch}
              style={styles.btn_proceed}
              labelStyle={styles.label_proceed}
              label={"Encrypt"}
            />
            <View style={styles.v_width} />
            <Button
            
              label="Cancel"
              style={styles.btn_cancel}
              labelStyle={styles.label_cancel}
              onPress={onClose}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default AddEncrpytPassword;
