import { Platform } from "react-native";

export default {
  colorPrimary: "#253A60",
  colorPrimaryLight: "#1BA7EC",
  upgradePlanColor: "#335186",
  border: "#999999",
  border1: "#E9E9E9",
  border2: "#CCCCCC",
  border3: "#C3CADC",
  border4: "#D4D4D4",
  border5: "#dadce0",
  border6: "#C4C4C4",
  border7: "#9BADCA",
  border8: "#DFE0EB",
  black: "black",
  black1: "#262E37",
  black2: "#4B4B4B",
  black3: "#212221",
  black4: "rgba(75, 75, 75, 0.5)",
  black5: "#1C242C",
  black6: "#1C242C50",
  black7: "#323C47",
  black8: "#1E1E1E",
  black9: "#535353",
  black10: "#828282",
  black11: "#7D7D7D",
  black12: "#1D242C",
  blue: "#4E70BB",
  blue1: "#1E2F98",
  blue2: "#335186",
  blue3: "#C0EAF9",
  blue4: "#DDEDF4",
  blue5: "#4168AB",
  blue6: "rgba(27, 167, 236, 0.05)",
  blue7: "#D7F2FF",
  blue8: "rgba(27, 167, 236, 0.4)",
  blue9: "rgba(192, 234, 249, 1)",
  blue10: "#4F7FB3",
  blue11: "#4C6EB6",
  blue12: "#243655",
  darkBlue: "#1D242C",
  grayPrimary: "#A3A3A3",
  lightGray: "#CCCCCC30",
  lightGray1: "#EDEDED",
  lightGray2: "#E3E3E3",
  lightGray3: "#CCCCCC",
  lightGray4: "#F1F1F1",
  darkGray: "#666666",
  lightBlack: "#404040",
  gray1: "#757575",
  gray2: "#F0F0F0",
  gray3: "#ECECEC",
  gray4: "#D9D9D9",
  gray5: "#F2F2F2",
  gray6: "#838383",
  gray7:"#F7F7F7",
  gray8:"#F4F4F4",
  gray9:"#989DA3",
  gray10:"#D3D8DD",
  gray11:"#C2C2C1",
  orange: "orange",
  orange1: "rgba(255, 159, 0, 1)",
  orange2: "rgba(255, 159, 0, 0.15)",
  paleMarigold: "#FFBA45",
  red: "red",
  red1: "#FF4343",
  red2: "#c95757",
  red3: "#F04848",
  white: "white",
  white1: "#f4f3f4",
  offwhite: "#F5F5F5",
  offwhite2: "rgba(255,255,255,0.5)",
  offwhite3: "rgba(255,255,255,0.3)",
  transparent: "transparent",
  placeholder: Platform.OS === "ios" ? "#C7C7CD" : "#a8a8a8",
  btnBG: "#1E9CB9",
  btnBG1: "#ECEFF1",
  blurBG: "rgba(0,0,0,0.2)",
  blurBG2: "rgba(0,0,0,0.8)",
  blurBG3: "rgba(0,0,0,0.57)",
  lilac: "#EDEDFF",
  purple: "#44449B",
  purple1: "#44449B80",
  purple2: "#44449B70",
  purple3: "#2A2A41",
  lightPurple: "#6767BB",
  green: "#34A853",
  green1: "#23AC00",
  green2: "#009D12",
  signature1: "#FF9F00",
  signature2: "#FFAAAA",
  signature3: "#45459B",
  signature4: "#3B80AE",
  rowbg: "rgba(236, 239, 241, 0.5)",
};
