import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, alignItems: "center" },
    v_top_height: { height: 200 },
    i_document: { width: 108, height: 104 },
    t_title: {fontFamily: "DMSans-Bold", fontSize: 24, marginTop: 30,
      lineHeight: 14, color: colors.black3, textAlign: "center",},
    t_notes: {
      fontFamily: "DMSans",
      fontWeight: "400",
      fontSize: 16,
      lineHeight: 18,
      marginTop: 20,
      textAlign: "center",
      color: colors.black3,
    },
    copyRight:{
      display: "none"
    },
    buttonStyling: {
      flexDirection: "row", 
      alignItems: "center"
    },
    labelStyle: { fontFamily: "DMSans-Bold" },
    btn_proceed: { width: 250, height: 47, marginTop: 80, backgroundColor: colors.blue },
    btn_download: { width: 250, height: 47, marginTop: 80, 
      backgroundColor: colors.transparent, borderWidth: 1, borderColor: colors.blue },
    label_download: { fontFamily: "DMSans-Bold", color: colors.blue },
    divider: { width: 22 },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { position: "absolute",
        alignSelf: "center",textAlign: "center", 
        color: colors.black },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([TypographyStyle.copyRightButtonMobile]),
  },
  {
    [DEVICE_SIZES.LG]: {
      v_top_height: { height: 210},
      i_document: { width: 81, height: 78 },
      t_title: { fontSize: 18, lineHeight: 10.5, marginTop: 20, },
      t_notes: { fontSize: 12, lineHeight: 13.5, marginTop: 14 },
      btn_proceed: { width: 187, height: 35, marginTop: 40, borderRadius: 6 },
      labelStyle: { fontSize: 10.5, lineHeight: 10.5 },
      btn_download: { width: 187, height: 35, marginTop: 40, borderRadius: 6 },
      label_download: { fontSize: 10.5, lineHeight: 10.5 },
      divider: { width: 17 },
    },
    [DEVICE_SIZES.XS]: {
      buttonStyling: {
        flexDirection: "column-reverse", 
        alignItems: "center"
      },
      btn_proceed: { width: 350, height: 47, marginTop: 46, backgroundColor: colors.blue },
      btn_download: { width: 350, height: 47, marginTop: 17,},
      v_top_height: { height: 152 },
      copyRight:{
        display:"flex",
        bottom: 0
      },
    }
    
  }
);

export default useStyles;
