import React from "react";
import { Image } from "react-konva";
import useImage from "use-image";

const LocalImage = (props: any) => {
  const [image] = useImage(props.src);

  return <Image
    ref={props.ref}
    width={props.width}
    height={props.height}
    image={image}
    x={props.x}
    y={props.y}
    scale={props.scale}
    scaleX={props.scaleX}
    scaleY={props.scaleY}
    onClick={props.onClick}
    onTap={props.onTap}
    rotation={props.rotation}
  />;
};

export default LocalImage;
