import React from "react";
import { SignatureContext } from "../../provider/signature";
import { View, Text, FlatList } from "react-native";
import useStyles from "./styles.css";
import DataItem from "./data-item";
import Header from "./header";

const ChooseSection = (props: any) => {
  const styles = useStyles();
  const { onSetSignature } = React.useContext(SignatureContext);

  const onSelect = React.useCallback((item: any) => {
    onSetSignature({
      signature: item.uri,
      signatureType: "Choose",
      signatureId: item.id
    });
    props.onClose();
  },[props.onClose]);


  return (
    <View style={styles.container}>
      <View style={styles.header_container}>
        <Text style={styles.t_header}>Select a saved signature</Text>
      </View>
      <FlatList
        data={props.data}
        style={styles.flatlist}
        ListHeaderComponent={<Header hide={props.data.length > 0} loading={props.loading} />}
        keyExtractor={(item: any, index: number) => `r_index${index}`}
        ItemSeparatorComponent={() => <View style={styles.v_separator} />}
        renderItem={({item, index}) => (
          <DataItem
            key={`${index}`}
            item={item}
            onPress={onSelect}
          />
        )}
      />
    </View>
  );
};

export default ChooseSection;
