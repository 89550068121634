import React from "react";
import { Text } from "react-konva";
import colors from "@assets/colors";

type TextProps = {
  field: any;
};

const DateTime = (props: TextProps) => {
  const { field } = props;

  return (
    <React.Fragment>
      <Text
        x={field.config.x}
        y={field.config.y}
        align="center"
        fontFamily="DMSans"
        fill={colors.black}
        verticalAlign="bottom"
        text={field.dateConfig.text}
        rotation={field.config.rotation}
        fontSize={field.dateConfig.fontSize}
      />
    </React.Fragment>
  );
};

export default React.memo(DateTime);
