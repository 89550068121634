import colors from "@assets/colors";
import AffixIcon from "@assets/svg/affix-signature";
import { AuthContext } from "@src/AuthProvider";
import Button from "@src/components/button/Button01";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAffixDocument } from "@src/ducks/slices/signsecure.slice";
import CopyRightContent from "@src/screens/create-document/document-sent/copy-right";
import MobileHeader from "@src/screens/drawer-screens/drawer-header/mobile";
import WebHeader from "@src/screens/drawer-screens/drawer-header/web-view";
import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import { useFetchGet } from "@src/utils/api/hooks";
import { mobileSize } from "@src/utils/screensize-helper";
import downloadjs from "downloadjs";
import _ from "lodash";
import React from "react";
import { Text, View } from "react-native";
import useStyles from "./styles.css";

const DocumentSigned = () => {
  const styles = useStyles();
  const isMobileSize = mobileSize();
  const { onActiveTab } = React.useContext(DrawerContext);
  const { onErrorMessage } = React.useContext(AuthContext);
  const affixSignature = useAppSelector(selectedAffixDocument);
  const {loading, data, runRequest, error } = useFetchGet({responseType: "blob"});
  const onDownload = () => {
    runRequest("/directory/download/" + affixSignature.id);
  };

  React.useEffect(() => {
    if(data){
      downloadjs(
        data,
        `${affixSignature.name}.pdf`,
        "application/pdf"
      );
    }
  },[data]);

  React.useEffect(() => {
    if(!_.isEmpty(error)){
      onErrorMessage(error.message);
    }
  },[error]);

  return (
    <>
      <View style={styles.drawer_item_header}>
        {isMobileSize ? <MobileHeader /> : <WebHeader/>}
      </View>
      <View style={styles.container}>
        <AffixIcon name="docu-signed" />
        <Text style={[styles.t_title]}>Document Signed</Text>
        <Text style={[styles.t_notes]}>
          The document <Text style={styles.t_notes_bold} >“{affixSignature.name}”</Text>
          {"\n"}has been signed. Sender will be notified.
        </Text>
        <View style={styles.buttonStyling}>
          <Button
            isLoading={loading}
            onPress={onDownload}
            label="Download Document"
            style={styles.btn_download}
            color={colors.colorPrimaryLight}
            labelStyle={styles.label_download}
          />
          <View style={styles.divider} />
          <Button
            onPress={() => onActiveTab("Dashboard")}
            label="Go to Dashboard"
            style={styles.btn_proceed}
            labelStyle={styles.labelStyle}
          />
        </View>

      </View>
      <View >
        <CopyRightContent />
      </View>
    </>
  );
};

export default DocumentSigned;
