import React, { useRef, useState } from "react";
import useStyles from "./styles.css";
import { Text } from "react-native";

const Counter = (props: any) => {
  const styles = useStyles();
  const timerId = useRef<any>(null);
  const [timer, setTimer] = useState(180);

  React.useEffect(() => {
    if (props.sessionId) {
      setTimer(180);
    }
  }, [props.sessionId]);

  React.useEffect(() => {
    if (!timer) {
      timerId.current && clearInterval(timerId.current);
      props.endTimer(true);
      return;
    }

    timerId.current = setInterval(() => {
      setTimer((lastTimerCount) => lastTimerCount - 1);
    }, 1000);

    return () => clearInterval(timerId.current);
  }, [timer]);

  return (
    <Text style={[styles.t_okay, { fontSize: 12 }]}>
      {timer > 0
        ? `Your security One-Time Pin (OTP) has been sent to your registered email address. 
          It will expire in ${timer} ${timer > 1 ? "seconds" : "second"}.`
        : "Your security One-Time Pin (OTP) has expired. Please request another one to proceed."}
    </Text>
  );
};

export default React.memo(Counter);
