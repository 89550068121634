import { Button01 } from "@src/components/button";
import { TextInput02 } from "@src/components/textinput";
import React, { useState } from "react";
import { Text, View } from "react-native";
import { SignatureContext } from "../../provider/signature";
import useStyles from "./styles.css";
import colors from "@assets/colors";

const TextboxSection = (props: any) => {
  const styles = useStyles();
  const { onTextBoxFocus, onTextBoxItem } = React.useContext(SignatureContext);
  
  const [inputText, setinputText] = useState("");

  const onSubmit = React.useCallback(() => {
    onTextBoxFocus(props.selectedItem);
    const selectedItem = {
      ...props.selectedItem,
      value: {
        input: inputText
      }
    };

    onTextBoxItem(selectedItem);
    props.onClose()
  }, [props.onClose, onTextBoxFocus, inputText]);

  return (
    <View style={styles.container}>
      <View style={styles.header_container}>
        <Text style={styles.t_header}>Please input your text below</Text>
      </View>

      <TextInput02
        style={styles.t_input}
        value={inputText}
        onChangeText={setinputText}
      />

      <View style={styles.inputBtnV}>
        <Button01 onPress={() => onSubmit()} label="Save" style={styles.btnInput} />
        <Button01
          label="Cancel"
          onPress={() => props.onClose()}
          style={styles.btnReset}
          labelStyle={{ color: colors.blue2 }}
        />
      </View>
    </View>
  );
};

export default TextboxSection;