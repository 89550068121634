import React, { memo } from "react";
import { View, Text, Pressable } from "react-native";
import useStyles from "./styles.css";

type DataItemProps = {
  item: string;
  index: number;
  signatureText: string,
  onPress: (item: string, index: number) => void;
};

const DataItem = (props: DataItemProps) => {
  const { item, signatureText, onPress, index } = props;
  const styles = useStyles();

  const onSelect = (item: string, index: number) => {
    onPress(item, index);
  };

  return (
    <View style={styles.r_style}>
      <Text
        style={[styles.t_text, { fontFamily: item }]} 
      >
        {signatureText}
      </Text>
      <Pressable
        onPress={() => onSelect(item, index)}
        style={[styles.btn_select, styles.btn_select_active]}>
          <Text style={styles.t_select}>Select</Text>
      </Pressable>
    </View>
  );
};

export default memo(DataItem);