import { API_BASE_URL } from "@env";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { authActions, selectedAuthAccessToken } from "@src/ducks/slices/auth.slice";
import { useCallback, useEffect, useState } from "react";

export type ErrorValue = {
  message: string;
  error?: any;
}

type PromiseFunction = () => Promise<any>;

type optionsProps = {
  responseType?: string;
}

export type FetchPostProps = {
  loading: boolean;
  data: any;
  error: ErrorValue;
  runRequest: (routes: string, params: object) => void;
  runReset: () => void;
  runCallbackRequest: (
    arg1: PromiseFunction, 
    handleSuccess: (e: any) => void, 
    handleError: (e: any) => void) => void;
}

export const useFetchPost = (options: optionsProps = {}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const accessToken = useAppSelector(selectedAuthAccessToken);
  const [error, setError] = useState<ErrorValue>({} as ErrorValue);

  const runRequest = useCallback(
    async (routes: string, params: any) => {
      try {
        setLoading(true);
        setData(null);
        const response = await fetch(`${API_BASE_URL}${routes}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        });

        if (response.ok === false) {
          throw await response.json();
        }

        let result: any = null;

        switch(options.responseType){
        case "text":
          result = await response.text();
          setData(result);
          break;
        case "blob":
          result = await response.blob();
          setData(result);
          break;
        default:
        case "json":
          result = await response.json();
          setData({status: result.status, ...result.data});
          break;
        }
        setLoading(false);
      } catch (err: any) {
        let message = err.message || err.error || "Something went wrong";
        message = message.replace(/^(HttpException:|NotFoundException:)\s*/, "");
        message = message.replace("Invalid OTP", "Please enter a valid code.");
        setLoading(false);
        setError({ message });
        if ((message.includes("Unauthorized") || message.includes("Failed to fetch"))) {
          dispatch(authActions.logout());
        }
      }
    },
    [accessToken, dispatch]
  );

  const runCallbackRequest = useCallback(
    async (promiseFunction: () => Promise<any>, handleSuccess: (e: any) => void, handleError: (e: any) => void) => {
      try {
        setLoading(true);
        setData(null);

        if (typeof promiseFunction !== 'function') {
          handleError({ message: "Invalid argument" });
          return null; // or handle it accordingly
        }

        const response = await promiseFunction();

        setLoading(false);
        handleSuccess(response);
      } catch (err: any) {
        handleError(err);
        setLoading(false);
      }
    },
    [accessToken, dispatch]
  );

  const runReset = useCallback(() => {
    setError({} as ErrorValue);
    setData(null);
  },[]);

  useEffect(() => {
    if(loading){
      setError({} as ErrorValue);
    }
  },[loading]);

  return {
    loading,
    data,
    error,
    runRequest,
    runReset,
    runCallbackRequest,
  } as FetchPostProps;
};
