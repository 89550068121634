import SVGIcon from "@assets/svg";
import React from "react";
import { Pressable, Text, View } from "react-native";
import { PluginContext } from "../provider/plugin";
import useStyles from "./styles.css";

function PageNavigation() {
  const styles = useStyles();
  const { pageNavigationPluginInstance } = React.useContext(PluginContext);
  const { GoToNextPage, GoToPreviousPage, CurrentPageLabel } = pageNavigationPluginInstance;

  return (
    <View style={styles.paginationContainer}>
      <GoToPreviousPage>
        {(props: any) => (
          <Pressable style={styles.pageButton} onPress={props.onClick}>
            <SVGIcon name={props.isDisabled ? "backward-navigation-disabled" : "backward-navigation"} />
          </Pressable>
        )}
      </GoToPreviousPage>

      <Text style={styles.pageLabel}>
        <CurrentPageLabel>
          {(props: any) => (
            <span>{`${props.currentPage + 1} / ${props.numberOfPages}`}</span>
          )}
        </CurrentPageLabel>
      </Text>

      <GoToNextPage>
        {(props: any) => (
          <Pressable style={styles.pageButton} onPress={props.onClick}>
            <SVGIcon name={props.isDisabled ? "forward-navigation-disabled" : "forward-navigation"} />
          </Pressable>
        )}
      </GoToNextPage>
    </View>
  );
}

export default React.memo(PageNavigation);