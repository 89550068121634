import AffixIcon from "@assets/svg";
import Button from "@src/components/button/Button01";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useSignsecureService } from "@src/ducks/hooks";
import { selectedAffixDocument } from "@src/ducks/slices/signsecure.slice";
import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import useSVGSizeHelper from "@src/utils/svg-size-helper";
import React from "react";
import { Text, View } from "react-native";
import { SignatureContext } from "../../provider/signature";
import useStyles from "./styles.css";

const ScreenThree = () => {
  const styles = useStyles();
  const svgsize = useSVGSizeHelper({
    "MOBILE": { width: 80, height: 80},
    "LARGE": { width: 70, height: 70},
    "DEFAULT": { width: 80, height: 80},
  });
  const { viewDocumentRequest } = useSignsecureService();
  const { onActiveTab } = React.useContext(DrawerContext);
  const affixSignature = useAppSelector(selectedAffixDocument);

  const { setScreen, setShowQuickSigning } = React.useContext(SignatureContext);

  const onViewDocument = () => {
    viewDocumentRequest(affixSignature);
    setScreen("");
    setShowQuickSigning(false);
  };

  return (
    <View style={[ styles.v_modal ]} >
      <View style={styles.v_screen_3}>
        <AffixIcon name="ic-circle-check" {...svgsize} />
        <Text style={styles.t_note3}>Your identity has been
          {"\n"}successfully verified, and your
          {"\n"}signature has been affixed
          {"\n"}to the document.</Text>
      </View>
      <View style={styles.v_buttons_3}>
        <Button
          onPress={onViewDocument}
          label="View Document"
          style={styles.btn_proceed}
          labelStyle={styles.label_proceed}
        />
        <Button
          label="Back to Dashboard"
          style={styles.btn_cancel}
          labelStyle={styles.label_cancel}
          onPress={() => onActiveTab("Dashboard")}
        />
      </View>
    </View>
  );
};

export default ScreenThree;
