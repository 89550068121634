import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import TypographyStyle from "@assets/typography";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, marginTop: 20 },
    header_container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    flatlist: { maxHeight: 300, marginBottom: 30 },
    t_header: {
      ...TypographyStyle.Body2Regular,
      color: colors.darkGray,
      fontSize: 16,
      fontWeight: 400
    },
    change_container: {
      flexDirection: "row",
      alignItems: "center",
    },    
    t_change: {
      marginLeft: 5,
      fontSize: 16,
      fontWeight: 400,
      color: colors.darkGray
    },
    t_change_icon: {
      marginTop: 7
    },
    t_search: {
      marginBottom: 30
    },
    view_shot_container: {
      marginBottom: 30,
      width: 'auto',
      height: 258,
      justifyContent: 'center',
      alignItems: 'center',
    },
    preview_text: {
      fontSize: 64,
      textAlign: 'center'
    },
    v_separator: {
      height: 1,
      backgroundColor: colors.border8,
    },

    // DATA ITEM
    r_style: {
      height: 110,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 35,
      justifyContent: "space-between",
      backgroundColor: colors.white,
    },
    t_text: {
      fontSize: 30,
      fontWeight: 400
    },
    btn_select: { paddingHorizontal: 20, paddingVertical: 8, borderRadius: 50 },
    btn_select_active: { backgroundColor: colors.lightGray },
    t_select: {
      fontFamily: "DMSans-Medium",
      fontSize: 16,
      lineHeight: 22,
      color: colors.blue
    },

    // Footer buttons
    signBtnV: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    btnReset: {
      width: "30%",
      borderWidth: 1,
      marginRight: 12,
      borderColor: colors.purple,
      backgroundColor: colors.white,
    },
    btnSign: {
      width: "30%",
    }
  },
  {
    [DEVICE_SIZES.LG]: {
      flatlist: { maxHeight: 300, marginBottom: 30 },
    },
    [DEVICE_SIZES.SM]: {
      v_separator: {
        height: 1,
        opacity: 0.3,
        backgroundColor: colors.purple,
      },
    },
    [DEVICE_SIZES.XS]: {
      v_separator: {
        height: 1,
        opacity: 0.3,
        backgroundColor: colors.purple,
      },
    },
  }
);

export default useStyles;
