import colors from "@assets/colors";
import images from "@assets/images";
import { Button01 } from "@src/components/button";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedCustomSignatureID } from "@src/ducks/slices/signsecure.slice";
import React, { MutableRefObject, useRef } from "react";
import { LayoutChangeEvent, Text, View } from "react-native";
import ReactSignatureCanvas from "react-signature-canvas";
import { SignatureContext } from "../../provider/signature";
import "./style.css";
import useStyles from "./styles.css";

const DrawSection = () => {
  const styles = useStyles();
  const [hasSign, setHasSign] = React.useState(false);
  const [canvasWidth, setCanvasHeight] = React.useState(0);
  const signatureId = useAppSelector(selectedCustomSignatureID);
  const { onSetSignature } = React.useContext(SignatureContext);
  const webSignRef = useRef() as MutableRefObject<ReactSignatureCanvas>;

  const onReset = () => {
    webSignRef.current.clear();
    setHasSign(false);
  };

  const onSign = () => {
    onSetSignature({
      signature: webSignRef.current.getSignaturePad().toDataURL("image/png"),
      signatureType: "Draw",
      signatureId: signatureId
    });
  };

  const onLayout = (e: LayoutChangeEvent) => {
    const width = e.nativeEvent.layout.width;

    setCanvasHeight(width);
  };

  return (
    <View style={styles.flex1}>
      <Text style={styles.signDrawText}>Draw your signature below</Text>
      <View onLayout={onLayout} style={[styles.v_signature_canvas,  { height: canvasWidth * 0.4205 }]}>
        <ReactSignatureCanvas
          ref={webSignRef}
          onEnd={() => setHasSign(true)}
          canvasProps={{ className: "signPad", width: canvasWidth, height: canvasWidth * 0.4205  }}
          backgroundColor={colors.transparent}
        />
      </View>
      <View style={styles.signBtnV}>
        <Button01
          label="Reset"
          onPress={onReset}
          style={styles.btnReset}
          source={images.icon_reload}
          labelStyle={{ color: colors.purple }}
        />
        <Button01 disabled={!hasSign} onPress={onSign} label="Sign" style={styles.btnSign} />
      </View>
    </View>
  );
};

export default DrawSection;
