import React from "react";
import { Text,View } from "react-native";
import useStyles from "./styles.css";
import { TextInput02 } from "@src/components/textinput";
import Button from "@src/components/button/Button01";
const PasswordFields = (props: any) => {
  const styles = useStyles();
  return (
    <>
        <View style={styles.v_inputs}>
          <TextInput02
            hasPassword
            label="Password"
            // value={state.email}
            style={styles.email}
            // error={errorInput.email}
            placeholder=""
            labelStyle={styles.fontWeight}
            inputStyle={styles.inputStyle}
            onChangeText={props.password}
          />
        </View>
        <View style={styles.v_inputs}>
        <Text   style={styles.errorColor}>{props.error.confirmpassword}</Text>
        </View>
        <View style={styles.v_inputs}>
          <TextInput02
            hasPassword
            label="Confirm Password"
            style={styles.email}
            // value={state.position}
            // error={errorInput.position}
            placeholder=""
            labelStyle={styles.fontWeight}
            inputStyle={styles.inputStyle}
             onChangeText={props.confirmPassword}
          />
       
        </View>
        <View style={styles.v_inputs}>
        <Text   style={styles.errorColor}>{props.error.confirmpassword}</Text>
        </View>
    </>

  );
};

export default PasswordFields;
