import React from "react";
import QRCode from "@src/components/qr-code";
import { Group, Rect } from "react-konva";
import { Html } from "react-konva-utils";
import colors from "@assets/colors";

type QRCodeSignProps = {
  field: any;
};

const QRCodeSign = (props: QRCodeSignProps) => {
  const { field } = props;

  return (
    <React.Fragment>
      <Group
        x={field.config.x}
        y={field.config.y}
        width={field.config.width}
        height={field.config.height}
      >
        <Rect width={field.config.width} height={field.config.height} />
        <Html
          divProps={{
            style: {
              zIndex: -1,
              pointerEvents: "none",
              width: field.config.width,
              height: field.config.height,
              backgroundColor: colors.transparent
            },
          }}
        >
          <QRCode
            x={field.config.x}
            y={field.config.y}
            width={field.config.width}
            height={field.config.height}
            value={field.qrvalue}
          />
        </Html>
      </Group>
    </React.Fragment>
  );
};

export default React.memo(QRCodeSign);
