import React from "react";
import { SvgProps } from "react-native-svg";
import CardSuccess from "./card-success.svg";
import CardUnSuccess from "./card-unsuccess.svg";
import DocuApproved from "./docu-approved.svg";
import DocuCancelled from "./docu-cancelled.svg";
import DocuDeclined from "./docu-declined.svg";
import DocuDenied from "./docu-denied.svg";
import DocuRejected from "./docu-rejected.svg";
import DocuSigned from "./docu-signed.svg";
import DocuValid from "./docu-valid.svg";
import MagicWand from "./ic-magic-wand.svg";
import FaceScanning from "./face-scanning.svg";

export interface AffixItemIconProps extends SvgProps {
  name: "docu-approved" | "docu-cancelled" |  "docu-declined" | 
  "docu-denied" | "docu-rejected" | "docu-signed" | 
  "docu-valid" | "card-success" | "card-unsuccess" |
  "ic-magic-wand" | "face-scanning";
  fill?: string;
}

const AffixIcon = (props: AffixItemIconProps) => {
  const { name, width, height } = props;

  switch (name) {
  case "docu-approved":
    return <DocuApproved width={width || 128} height={height || 128} />;
  case "docu-cancelled":
    return <DocuCancelled width={width || 105} height={height || 107} />;
  case "docu-declined":
    return <DocuDeclined width={width || 131} height={height || 131} />;
  case "docu-denied":
    return <DocuDenied width={width || 120} height={height || 110} />;
  case "docu-rejected":
    return <DocuRejected width={width || 86} height={height || 107} />;
  case "docu-signed":
    return <DocuSigned width={width || 98} height={height || 98} />;
  case "docu-valid":
    return <DocuValid width={width || 90} height={height || 97} />;
  case "card-success":
    return <CardSuccess width={width || 126} height={height || 95} />;
  case "card-unsuccess":
    return <CardUnSuccess width={width || 125} height={height || 78} />;
  case "ic-magic-wand":
    return <MagicWand width={width || 17} height={height || 17} />;
  case "face-scanning":
    return <FaceScanning width={width || 238} height={height || 278} />;
  default: 
    return null;
  }
};

export default AffixIcon;
