import React from "react";
import { Button01 } from "@src/components/button";
import useStyles from "./styles.css";
import colors from "@assets/colors";
import images from "@assets/images";
import { API_BASE_URL } from "@env";

function GoogleButton(props: any) {
  const styles = useStyles();

  const onSignUp = async () => {
    window.location.href = `${API_BASE_URL}/auth/google`;
  };

  return (
    <Button01
      onPress={onSignUp}
      source={images.google}
      color={colors.gray1}
      label="Sign up with Google"
      isLoading={props.isLoading}
      labelStyle={{color: colors.black9, fontSize: 12}}
      style={styles.btn_google}
    />
  );
}

export default React.memo(GoogleButton);