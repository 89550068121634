import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    v_drawer_container: {
      zIndex: 3,
      width: 400,
      height: "100%",
      minHeight: "100%",
      maxHeight: "100%",
    },
    linear_style: {  
      width: "100%",
      height: "100%",
      paddingHorizontal: 55,
      position: "absolute",
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
    },
  
    // DRAWER HEADER
    drawer_user_frame_container: {
      flex: 1,
      marginTop: 33,
      height: 300,
    },
    drawer_unawa_logo: { height: 56, width: 270 },
    user_edit_profile_container: {
      marginTop: 46,
    },
    v_details: { justifyContent: "center" },
    t_welcome: {
      color: colors.white,
      fontSize: 24,
      lineHeight: 30,
      fontFamily: "DMSans-Bold",
      textTransform: "capitalize"
    },
    t_welcome_description: {
      fontSize: 16,
      marginTop: 16,
      lineHeight: 24,
      color: colors.white,
      fontFamily: "DMSans",
    },
    v_height: { height: 80 },
    divider: { height: 1, backgroundColor: colors.blue },

    // SIGNUPNOW
    signupnow_container: {
      marginTop: 28,
    },
    btn_signupnow: {
      marginTop: 30,
      height: 47,
      borderRadius: 8,
    },
    label_signupnow: {
      fontFamily: "DMSans-Bold",
      fontSize: 14,
      lineHeight: 14,
      color: colors.white,
    },
    logo:{
      alignItems: "center"
    },
    LogOutButton: {
      flexDirection: "row"
    },
    alignmentLogout: {
      paddingTop: "25%"
    },
    logOut_Guest: {
      fontSize: 20,
      lineHeight: 20,
      marginLeft: 20,
      fontFamily: "DMSans",
      color: colors.white,
    },
    // COPYRIGHT
    drawer_content_footer: {
      left: 0,
      right: 0,
      bottom: 15,
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { marginBottom: 10, alignSelf: "center", textAlign: "center", color: colors.white },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([
      TypographyStyle.copyRightButton,
      { fontWeight: "400" },
    ]),
    
  },
  {
    [DEVICE_SIZES.LG]: {
      drawer_user_frame_container: {
        marginTop: 47
      },
      v_drawer_container: {
        width: 300,
        minHeight: "100%",
        maxHeight: "100%",
      },
      alignmentLogout: {
        paddingTop: "15%"
      },
      logOut_Guest: {
        fontSize: 18
      },
      user_edit_profile_container: {
        marginTop: 20,
      },
      v_height: { height: 20 },
      divider: { height: 0.4 },
      drawer_unawa_logo: { height: 61.5, width: 185.25 },
      t_welcome: { fontSize: 18, lineHeight: 22.5 },
      t_welcome_description: { fontSize: 12, lineHeight: 18 },
      linear_style: { 
        paddingHorizontal: 42,
        borderTopRightRadius: 22.5,
        borderBottomRightRadius: 22.5
      },
      // SIGNUPNOW
      signupnow_container: {
        marginTop: 20,
      },
      btn_signupnow: {
        height: 35,
        borderRadius: 6,
      },
      label_signupnow: {
        fontSize: 10.5,
        lineHeight: 10.5,
      },
    
      copyRightStyle: { fontSize: 9, lineHeight: 13.5, marginBottom: 10 },
      copyRightBtnStyle: { fontSize: 9, lineHeight: 13.5 },
    },
    [DEVICE_SIZES.MD]: {
      t_welcome: { fontSize: 24, lineHeight: 30, textAlign: "center", },
      t_welcome_description: { fontSize: 16, lineHeight: 25, textAlign: "center", },
      v_drawer_container: {
        zIndex: 3,
        width: "100%",
        minHeight: 600,
        maxHeight: 600,
      },
      v_height: { height: 35 },
      linear_style: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      LogOutButton: {
        flexDirection: "row",
        justifyContent:"center",
        alignItems:"center"
      },
      alignmentLogout: {
        paddingTop: "5%"
      },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      linear_style: {  
        width: "100%",
        height: "100%",
        paddingHorizontal: 15,
        position: "absolute",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      v_drawer_container: {
        flex: 1,
        width: "100%",
        minHeight: 650,
        maxHeight: 650,
      },
      LogOutButton: {
        flexDirection: "row",
        justifyContent:"center",
        alignItems:"center"
      },
      alignmentLogout: {
        paddingTop: "7%",
        right: "2%"
      },
      t_welcome: {
        fontSize: 23,
        lineHeight: 30,
        textAlign: "center",
      },
      t_welcome_description: {
        fontSize: 15,
        lineHeight: 24,
        textAlign: "center",
      },
      v_height: { 
        height: 35 
      },
      btn_signupnow: {
        marginTop: 30,
        height: 47,
        borderRadius: 8,
        width:"100%"
      },
      user_edit_profile_container: {
        marginTop: 39,
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      },
    },
  }
);

export default useStyles;
