import SVGIcon from "@assets/svg";
import { CommonActions, useNavigation } from "@react-navigation/native";
import { largeSize } from "@src/utils/screensize-helper";
import React, { useContext } from "react";
import { Pressable, View } from "react-native";
import useStyles from "./styles.css";
import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";

const Logo = () => {
  const styles = useStyles();
  const isLargeSize = largeSize();
  const { onActiveTab } = useContext(DrawerContext);
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const navigation = useNavigation();
  const size = React.useMemo(() => {
    if(isLargeSize){
      return { logo: {
        width: 37,
        height: 44,
      }, signsecure: {
        width: 185,
        height: 61.5,
      }
      };
    }

    return { logo: {
      width: 204,
      height: 42,
    }, signsecure: {
      width: 204,
      height: 42,
    }
    };
  },[isLargeSize]);

  const onPress = () => {
    if(!isLoggedIn){
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: "Signin" },
          ],
        })
      );
    }else{
      onActiveTab("Dashboard");
    }
  };
  
  return (
    <Pressable android_ripple={null} android_disableSound={true} onPress={onPress} >
      {() => (
        <View style={[styles.logoStyle]}>
          <SVGIcon name="SignsecureLogoDrawer" {...size.signsecure} /> 
        </View>
      )}
    </Pressable>
  );
};

export default Logo;
