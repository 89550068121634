import colors from "@assets/colors";
import DrawerIcon from "@assets/svg/drawer";
import { largeSize } from "@src/utils/screensize-helper";
import React from "react";
import { Pressable } from "react-native";
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { DrawerComponentContext } from "../provider";
import useStyles from "./styles.css";

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const DrawerItem = (props: any) => {
  const styles = useStyles();
  const isLargeSize = largeSize();
  const activeBG = props.isActive && styles.activeBG;
  const activeHover = props.isHover && styles.activeHover;
  const activeText = props.isActive && {color: colors.blue};
  const { animated } = React.useContext(DrawerComponentContext);

  const icon_size = React.useMemo(() => {
    if(isLargeSize){
      switch(props.item.name){
      case "Dashboard":
        return { width: 16, height: 15};
      case "Inbox":
        return { width: 15, height: 11.25};
      case "Documents":
        return { width: 14.25, height: 16.44};
      case "SafeForm Lite":
        return { width: 15, height: 16.5};
      case "Manage Users":
        return { width: 17.25, height: 15.75};
      case "Signatures":
        return { width: 15.15, height: 15.15};
      case "Settings":
        return { width: 18, height: 18};
      case "Logout":
        return { width: 15, height: 20.94};
      }
    }

  },[props.item.name, isLargeSize]);

  const animateTextStyle = useAnimatedStyle(() => {
    const opacity = interpolate(
      animated.value,
      [360, 110],
      [1, 0],
      Extrapolation.CLAMP,
    );

    return {
      opacity,
    };
  }, []);

  const animatePaddingLeft = useAnimatedStyle(() => {
    const paddingLeft = interpolate(
      animated.value,
      [360, 110],
      [isLargeSize ? 17.25 : 23, isLargeSize ? 21.39 : 29],
      Extrapolation.CLAMP,
    );
    return {
      paddingLeft
    };
  }, [isLargeSize]);

  return (
    <AnimatedPressable
      onPress={props.onPress}
      onPressIn={props.onPressIn}
      onPressOut={props.onPressOut}
      onHoverIn={props.onHoverIn}
      onHoverOut={props.onHoverOut}
      style={[styles.drawer_tab_container, animatePaddingLeft, activeBG, activeHover, props.style]}>
      <DrawerIcon name={props.item.icon} isActive={props.isActive} {...icon_size} />
      <Animated.Text style={[styles.drawer_tab_label, activeText, animateTextStyle]}>{props.item.name}</Animated.Text>
    </AnimatedPressable>
  );
};

function areEqual(prevProps: any, nextProps: any) {
  return (
    prevProps.isActive === nextProps.isActive &&
    prevProps.item === nextProps.item &&
    prevProps.isHover === nextProps.isHover
  );
}

export default React.memo(DrawerItem, areEqual);
